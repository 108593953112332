import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getVolumeList() {
    const response = await axios.get<BaseResponse<VolumeList>>(`/basic/volume_price`)
    return response.data
}

export type VolumeList = {
    defaultVaporizationRate? : number,
    isPriceVatInclude : boolean,
    title: string,
    priceList : Array<VolumePriceValue>,
}

export type VolumePriceValue = {
    title: string,
    value: number,
    factor: number,
}