import React, {useEffect, useState} from 'react';
import PurchaseRegistHeaderScreen from "./PurchaseRegistHeaderScreen";
import PurchaseRegistDetailScreen from "./PurchaseRegistDetailScreen";
import {changeTitle} from "../../../redux/CompassModule";
import {useDispatch, useSelector} from "react-redux";
import {useAsync} from "../../../hooks/useAsync";
import {getPurchaseAccountList} from "../../../api/purchase/account/getPurchaseAccountList";
import {getGasProductList} from "../../../api/basic/gasproduct/getGasProductList";
import {postPurchase} from "../../../api/purchase/postPurchase";
import {FormProvider, useForm} from "react-hook-form";
import {Purchase} from "../../../api/purchase/getPurchaseList";
import {getPurchaseDetailList, PurchaseDetail, PurchaseDetailList} from "../../../api/purchase/getPurchaseDetailList";
import {LINK_PURCHASE} from "../../../resources/LinkConstants";
import {useHistory, useParams} from "react-router-dom";
import {updatePurchase} from "../../../api/purchase/updatePurchase";
import {RootState} from "../../../redux";
import {reset} from "../../../redux/purchase/PurchaseRegistModule";

interface PurchaseRegistContainerProps {

}

interface MatchType {
    purchaseId: string
}

export type PriceType ={
    butanePrice : number
    propanePrice : number
}

function PurchaseRegistContainer(props: PurchaseRegistContainerProps) {

    const [data, setData] = useState<Array<PurchaseDetail>>([])
    const [accountUid, setAccountUid] = useState<string>()
    const [price, setPrice] = useState<PriceType>()
    const {purchaseId} = useParams<MatchType>()

    const dispatch = useDispatch()


    dispatch(changeTitle({
        title: {
            first_depth: "입고입력 홈",
            second_depth: "매입등록"
        }
    }))

    const methods = useForm<Purchase>({
        mode: "onBlur"
    })

    const {data: purchaseAccountResponse} = useAsync(getPurchaseAccountList, true)
    const {data: gasProductList} = useAsync(getGasProductList, true)
    const {data: detailListData} = useAsync(getPurchaseDetailList, purchaseId != undefined, [purchaseId], onPostDetailListData)
    const {execute: postPurchaseList, status} = useAsync(postPurchase)
    const {execute: updatePurchaseList} = useAsync(updatePurchase)

    const {type, item} = useSelector((state: RootState) => state.purchaseReducers.purchaseRegistReducer)

    const history = useHistory()

    useEffect(() => {
        if (status == 'success') {
            history.replace(LINK_PURCHASE)
        }

        if(accountUid){
            purchaseAccountResponse?.map((item) => {
                if(item.uid == accountUid){
                    setPrice({butanePrice : item.butanePrice, propanePrice : item.propanePrice})
                }
            })
        }

        switch (type) {
            case "remove" : {
                if (item) {
                    if (data) {
                        const index = data.findIndex((value) => {
                            if (value.currentTimestamp) {
                                return value.currentTimestamp == item.currentTimestamp
                            } else if (value.uid) {
                                return value.uid == item.uid
                            }
                        })

                        if (index >= 0) {
                            const temp = [...data];
                            temp.splice(index, 1)
                            setData(temp)
                        }
                    }
                }
            }
                break;
            case "add" : {
                if (item) {
                    setData(prevState => [...prevState, item])
                }
            }
                break;
            case "none":
            default:
                break
        }
        return () => {
            dispatch(reset())
        }
    }, [status, type, item, data])

    function onPostDetailListData(data: PurchaseDetailList) {
        setData(data.detailList)
    }

    function update() {
        if (detailListData) {
            detailListData.detailList = data
            detailListData.butaneRemainAmount = methods.watch("butaneRemainAmount")
            detailListData.propaneRemainAmount = methods.watch("propaneRemainAmount")
            detailListData.memo = methods.watch("memo")
            updatePurchaseList(purchaseId, detailListData)
        }
    }

    function onReset() {
        setData([])
    }

    return (
        <FormProvider {...methods}>
            <form>
                <PurchaseRegistHeaderScreen
                    accountList={purchaseAccountResponse}
                    setAccountUid={setAccountUid}
                    date={detailListData?.date}
                    accountUid={detailListData?.accountId}
                />
                <PurchaseRegistDetailScreen
                    data={data}
                    resultData={detailListData}
                    gasProductList={gasProductList}
                    postPurchase={postPurchaseList}
                    postStatus={status}
                    accountUid={accountUid}
                    reset={onReset}
                    update={update}
                    isUpdate={purchaseId != undefined}
                    price={price}
                />
            </form>
        </FormProvider>

    );
}

export default PurchaseRegistContainer