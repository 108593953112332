import React, {ChangeEvent, useEffect} from 'react';
import styled from 'styled-components';
import {Document, Page} from "react-pdf";
import Pre from "../../../../../assets/images/common/ic-24-arrow-left@3x.png";
import Next from "../../../../../assets/images/common/ic-24-arrow-right@3x.png";
import DefaultImage from "../../../../../components/style/image/DefaultImage";
import Camera from "../../../../../assets/images/common/ic-48-camera@3x.png";
import FixedWeightText from "../../../../../components/style/text/FixedWeightText";
import Input from "../../../../../components/style/input/Input";

interface PdfScreenComponentProps {
    selectedFile: FileList | undefined
    index: number
    setIndex: (index: number) => void
    updateData?: string
}

function TankCertificatePdfScreen(props: PdfScreenComponentProps) {

    let size = props.selectedFile?.length == undefined ? 0 : props.selectedFile.length

    function IncreaseIndex() {
        if (props.index < size - 1) {
            props.setIndex(props.index + 1)
        }
    }

    function DecreaseIndex() {
        if (props.index > 0) {
            props.setIndex(props.index - 1)
        }
    }

    function handleInputIndex(e: ChangeEvent<HTMLInputElement>) {
        const value = Number(e.target.value) - 1

        if (value < size && value >= 0) {
            props.setIndex(value)
        }
    }

    useEffect(() => {
        console.log(props.updateData, "hello")
    }, [props.selectedFile, props.updateData])
    return (

        <>
            {
                (props.selectedFile && props.selectedFile?.length > 0 && !props.updateData) &&
                <div>
                    <PdfDiv>
                        <Document file={props.selectedFile[props.index]}>
                            <Page pageNumber={1} width={669}/>
                        </Document>
                    </PdfDiv>
                    <SelectPdfButtonDiv>
                        <PreButton src={Pre} onClick={DecreaseIndex}/>
                        <IndexInput value={props.index + 1} onChange={handleInputIndex}/>
                        <IndexText>/ {props.selectedFile.length}</IndexText>
                        <NextButton src={Next} onClick={IncreaseIndex}/>
                    </SelectPdfButtonDiv>
                </div>
            }
            {
                props.updateData &&
                <PdfDiv>
                    <Document file={"https://dalim.s3.ap-northeast-2.amazonaws.com/6d1b5415-858d-4ddc-b297-7db26bd09a9a.pdf"}>
                        <Page pageNumber={1} width={669}/>
                    </Document>
                </PdfDiv>
            }
            {((!props.selectedFile || props.selectedFile?.length == 0) && !props.updateData) &&
            <DefaultImageDiv>
                <ImageDiv>
                    <DefaultImage src={Camera} width={"48px"} height={"48px"}/>
                </ImageDiv>
            </DefaultImageDiv>}

        </>
    );
}
const DefaultImageDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 669px;
    height: 560px;
    border-radius: 4px;
    border: solid 1px var(--gray-4);
    background-color: var(--gray-6);
    justify-content: center;
    align-items: center;
    overflow: scroll;
`

const PdfDiv = styled(DefaultImageDiv)`
    height: 330px;
    padding-top: 230px;
`

const SelectPdfButtonDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: var(--gray-6);
`

const IndexText = styled(FixedWeightText)`
    width: 25px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
`

const ImageDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 258px;
    height: 340px;
    border: solid 1px var(--gray-4);
    justify-content: center;
    align-items: center;
`
const IndexInput = styled(Input)`
    width: 24px;
    height: 24px;
`

const NextButton = styled.img`
    width: 24px;
    height : 24px;
    margin-left : 16px;
`

const PreButton = styled(NextButton)`
    margin-right: 16px;
    margin-left : 0px;
`

export default TankCertificatePdfScreen