import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getEmployee(uid : string) {
    const response = await axios.get<BaseResponse<EmployeeResponse>>(`/basic/employee/${uid}`)

    return response.data
}

export type EmployeeResponse = {
    code: string,
    name: string,
    employeeType: number,
    groupAreaIdList : Array<string>,
    mobile: string,
    employeeId : string,
    password : string,
    active: boolean,
}
