import React, {useEffect} from 'react';
import BasicNavComponent, {BasicType} from "../BasicNavComponent";
import styled from "styled-components";
import BasicTopTitle from "../BasicTopTitle";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../../../redux/CompassModule";
import TopButton from "../../../../components/style/button/TopButton";
import VolumeTable from "./VolumeTable";
import {SubText, SubtitleDiv} from "../Employee/EmployeeScreen";
import {VolumeList} from "../../../../api/basic/volume/getVolumeList";
import {ItemBoxDiv} from "../../../tank/component/ItemBox";
import Input from "../../../../components/style/input/Input";

interface VolumeScreenComponentProps {
    openModal: () => void
    data? : VolumeList
}

function VolumeScreen(props: VolumeScreenComponentProps) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeTitle({
            title: {
                first_depth: "기초데이터 홈"
            }
        }))
    })


    return (
        <>
            <BasicTopTitle openModal={() => props.openModal()}/>
            <BasicNavComponent type={BasicType.VOLUME_PRICE}/>
            <SubtitleDiv>
                <SubText>
                    체적 단가
                </SubText>
            </SubtitleDiv>
            <VolumeTable
                data={props.data?.priceList}
            />
            <SubtitleDiv>
                <SubText>
                    검침오차에 적용 할 기화율 설정
                </SubText>
            </SubtitleDiv>
            <ItemDiv>
                <ItemBoxDiv>
                    표준기화율
                </ItemBoxDiv>

                <ItemInput value={props.data?.defaultVaporizationRate} disabled={true}/>

            </ItemDiv>
            <Div>
                <TopButton/>
            </Div>
        </>
    );
}

const Div = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`


export default VolumeScreen