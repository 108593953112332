import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import BaseModal from "./BaseModal";
import ModalHeader from "./Header";
import FixedWeightText from "../style/text/FixedWeightText";
import Button from "../style/button/Button";
import {ItemBoxDiv} from "../../pages/tank/component/ItemBox";
import Input from "../style/input/Input";
import AccountSearchTable from "../table/AccountSearchTable";
import {PurchaseAccountList} from "../../api/purchase/account/getPurchaseAccountList";

interface AccountSearchModalComponentProps {
    showModal : boolean
    onCancel : () => void
    data? : PurchaseAccountList[]
    setAccount : (account : PurchaseAccountList) => void
}

function AccountSearchModal (props : AccountSearchModalComponentProps){

    const [uid, setUid] = useState("")

    useEffect(()=>{
        const account = props.data?.filter(
            (item) => {
                return item.uid == uid
            }
        )

        if(account){
            props.setAccount(account[0])
        }

    },[uid])

    return (
        <BaseModal showModal={props.showModal} onCancel={props.onCancel}>
            <Modal>
                <ModalHeader title={"매입거래처 찾기"} onCancel={props.onCancel}/>
                <ModalContents>
                    <Title>
                        매입거래처 찾기
                    </Title>
                    <SearchSection>
                        <Row>
                            <DisplayDiv>
                                <ItemDiv>
                                    <ItemBoxDiv>
                                        코드
                                    </ItemBoxDiv>
                                    <ItemInput/>
                                </ItemDiv>
                                <ItemDiv>
                                    <ItemBoxDiv>
                                        주소
                                    </ItemBoxDiv>
                                    <ItemInput/>
                                </ItemDiv>
                            </DisplayDiv>
                            <MarginDisplayDiv>
                                <ItemDiv>
                                    <ItemBoxDiv>
                                        거래처명
                                    </ItemBoxDiv>
                                    <ItemInput/>
                                </ItemDiv>
                                <ItemDiv>
                                    <ItemBoxDiv>
                                        전화번호
                                    </ItemBoxDiv>
                                    <ItemInput/>
                                </ItemDiv>
                            </MarginDisplayDiv>
                        </Row>
                        <SearchButton>
                            검색
                        </SearchButton>
                    </SearchSection>
                    <Divider/>
                    <SubTitle>매입거래처</SubTitle>
                    <ResultDiv>
                        <ResultText>검색결과 </ResultText>
                        <ResultCount>999</ResultCount>
                        <ResultText>건</ResultText>
                    </ResultDiv>
                    <AccountSearchTable data={props.data} closeModal={props.onCancel} returnUid={setUid}/>
                </ModalContents>
            </Modal>
        </BaseModal>
    );
}

const Modal = styled.div`
  width: 1080px;
  height: 1032px;
  border-radius: 4px;
  background-color: var(--white);
`

const ModalContents = styled.div`
  width: 1080px;
  height: 984px;
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  padding-top : 22px;
`

const Title = styled(FixedWeightText)`
  font-size: 24px;
  font-weight: 400;
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  width: 324px;
  height: 48px;
  margin-left: 8px;
`

const DisplayDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const MarginDisplayDiv = styled(DisplayDiv)`
 margin-left: 16px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const SearchSection = styled(Row)`
  margin-top : 22px;
  align-items: center;
  justify-content: space-between;
`

const SearchButton = styled(Button)`
  width: 80px;
  height: 112px;
  border-radius: 4px;
  background-color: var(--blue);
  color : var(--white);
`

const Divider = styled.div`
  width: 1080px;
  height: 4px;
  margin-left: -32px;
  margin-top : 32px;
  margin-bottom: 24px;
  background-color: var(--gray-6);
`

const SubTitle = styled(FixedWeightText)`
  font-size: 18px;
  font-weight: 400;
`

const ResultDiv = styled(Row)`
  margin-top : 32px;
  margin-bottom: 8px;
`

const ResultText = styled(FixedWeightText)`
  font-size: 14px;
`

const ResultCount = styled(ResultText)`
  color : var(--blue);
  margin-left: 3px;
`
export default AccountSearchModal