import axios from "axios";
import Moment from 'moment';
import {BaseResponse} from "../../BaseResponse";
import {TankCertificate} from "./postCertificate";

export async function getCertificateList(
    dateType: number,
    startDate: string,
    searchType: number,
    searchQuery: string) {

    const response = await axios.get<BaseResponse<Array<TankCertificate>>>(`/tank/certificate`, {
        params: {
            dateType : dateType,
            startDate: startDate,
            searchType: searchType,
            searchQuery: searchQuery
        }
    })

    return response.data
}
