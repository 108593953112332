import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ItemBox, {Box} from "../../../components/style/div/ItemBox";
import Compass from "../../../components/style/div/Compass";
import CancelButton from "../../../components/style/button/CancelButton";
import TopButton from "../../../components/style/button/TopButton";
import Divider from "../../../components/style/div/Divider";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {LINK_PURCHASE_ACCOUNT} from "../../../resources/LinkConstants";
import SectionTitle from "../../../components/style/div/SectionTitle";
import PostCodeButton from "../../../components/postcode/PostCodeButton";
import {PurchaseAccountRegistRequest} from "../../../api/purchase/account/purchaseAccountRegistration";
import {FormProvider, useForm} from "react-hook-form";
import {PurchaseAccountRegistrationFormResponse} from "../../../api/purchase/account/purchaseAccountRegistrationForm";
import ConfirmButton from "../../../components/style/button/ConfirmButton";
import {ItemBoxDiv} from "../../tank/component/ItemBox";
import Select from "../../../components/style/select/Select";
import {PurchaseAccountResponse} from "../../../api/purchase/account/getPurchaseAccount";
import DeleteButton from "../../../components/style/button/DeleteButton";
import DeleteModal from "../../../components/modal/DeleteModal";
import PlaceholderItemBox from "../../../components/style/div/PlaceholderItemBox";
import {
    NonRequiredRulesAndValidationBusinessNumber,
    NonRequiredRulesAndValidationEmail,
    NonRequiredRulesAndValidationTelNumber,
} from "../../../components/form/BaseFormType";


interface RegistComponentProps {
    onSubmit: (request: PurchaseAccountRegistRequest) => void
    loading: boolean
    formResponse: PurchaseAccountRegistrationFormResponse | undefined
    accountData?: PurchaseAccountResponse | null
    onDelete? : () => void
}

function PurchaseAccountRegist(props: RegistComponentProps) {

    const methods = useForm<PurchaseAccountRegistRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const [shopType, setType] = useState(1);
    const [show, setShow] = useState(false)
    const [Address, setAddress] = useState<string>()

    useEffect(() => {
        if (props.accountData) {
            setType(props.accountData?.shopTypeId || 1)
            methods.setValue("code", props.accountData?.code)
            methods.setValue("accountName", props.accountData?.accountName)
            methods.setValue("tel", props.accountData?.tel)
            methods.setValue("fax", props.accountData?.fax)
            methods.setValue("chargePerson", props.accountData?.chargePerson)
            methods.setValue("chargePersonTel", props.accountData?.chargePersonTel)
            methods.setValue("email", props.accountData?.email)
            methods.setValue("businessNumber", props.accountData?.businessNumber)
            methods.setValue("ownerName", props.accountData?.ownerName)
            methods.setValue("address", props.accountData?.address)
            methods.setValue("addressDetail", props.accountData?.addressDetail)
            methods.setValue("businessType", props.accountData?.businessType)
            methods.setValue("type", props.accountData?.type)
            methods.setValue("memo1", props.accountData?.memo1)
            methods.setValue("memo2", props.accountData?.memo2)
            methods.setValue("bankAccountNumber", props.accountData?.bankAccountNumber)
            methods.setValue("bankAccountOwnerName", props.accountData?.bankAccountOwnerName)
            methods.setValue("propanePrice", props.accountData?.propanePrice)
            methods.setValue("butanePrice", props.accountData?.butanePrice)
        }
    }, [props.accountData])

    const onSubmit = (data: PurchaseAccountRegistRequest) => {
        props.onSubmit(data)
    }

    return (
        <>
            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Compass first_depth={"매입거래처 관리 홈"} first_depth_width={198}
                             second_depth={props.accountData ? "매입거래처 수정" : "매입거래처 등록"}/>
                    <Divider/>
                    <HeaderDiv>
                        <FixedWeightText fontSize={"24px"} fontWeight={500}>
                            {props.accountData ? "매입거래처 수정" : "매입거래처 등록"}
                        </FixedWeightText>

                        <ButtonsDiv>
                            <CancelButton link={LINK_PURCHASE_ACCOUNT}/>
                            <ConfirmButton onSubmit={onSubmit}/>
                            {props.accountData && <DeleteButton setShow={setShow} show={show}/>}
                        </ButtonsDiv>
                    </HeaderDiv>

                    <SectionTitle>
                        기본정보
                    </SectionTitle>

                    <RowDiv>
                        <MarginRightColumnDiv>
                            <ItemBox title={"코드"}
                                     name={"code"}
                                     reference={methods.register({
                                         required: true,
                                         minLength: 4,
                                         maxLength: 4,
                                         pattern: /^([0-9])*$/
                                     })}
                            />
                            <ItemBox
                                title={"매입거래처명"}
                                name={"accountName"}
                                reference={methods.register({
                                    required: true,
                                    minLength: 2,
                                    maxLength: 20,
                                })}/>
                            <NormalDiv>
                                <Box>
                                    <FixedWeightText fontSize={"14px"} fontWeight={500}>구분</FixedWeightText>
                                </Box>

                                <NormalDiv style={{marginLeft: "3px"}}>
                                    {props.formResponse?.shopType.map((item) =>
                                        <InputDIv>
                                            <RadioButton name={"shopTypeId"}
                                                         type={"radio"}
                                                         value={shopType}
                                                         checked={item.id == shopType}
                                                         onClick={() => setType(item.id)}
                                                         ref={methods.register({
                                                             required: true,
                                                         })}
                                            />
                                            <InputText>{item.title}</InputText>
                                        </InputDIv>
                                    )}
                                </NormalDiv>
                            </NormalDiv>

                            <ItemBox
                                title={"전화번호"}
                                name={"tel"}
                                reference={methods.register({
                                    required: false,
                                })}
                            />

                            <ItemBox
                                title={"팩스번호"}
                                name={"fax"}
                                error={methods.errors.fax?.types?.minLength === true || methods.errors.fax?.types?.maxLength === true}
                                reference={methods.register(NonRequiredRulesAndValidationTelNumber)}
                            />

                            <ItemBox
                                title={"담당자"}
                                name={"chargePerson"}
                                reference={methods.register}
                            />
                            <ItemBox
                                title={"담당자 연락처"}
                                name={"chargePersonTel"}
                                reference={methods.register(NonRequiredRulesAndValidationTelNumber)}
                                error={methods.errors.tel?.types?.minLength === true || methods.errors.tel?.types?.maxLength === true}
                            />
                            <ItemBox
                                title={"이메일 주소"}
                                name={"email"}
                                reference={methods.register(NonRequiredRulesAndValidationEmail)}
                                error={methods.errors.email?.types?.pattern === true}
                            />

                        </MarginRightColumnDiv>

                        <ColumnDiv>
                            <ItemBox
                                title={"사업자 번호"}
                                name={"businessNumber"}
                                reference={methods.register(NonRequiredRulesAndValidationBusinessNumber)}
                                error={methods.errors.businessNumber?.types?.minLength === true || methods.errors.businessNumber?.types?.maxLength === true}
                            />
                            <ItemBox
                                title={"대표자명"}
                                name={"ownerName"}
                                reference={methods.register}
                            />

                            <CenterDiv>
                                <ItemBox
                                    title={"주소"}
                                    readOnly={true}
                                    value={Address}
                                    name={"address"}
                                    reference={methods.register({
                                        required: true,
                                    })}
                                />
                                <AddressSearchButton handleValue={setAddress}/>
                            </CenterDiv>
                            <ItemBox
                                title={"상세주소"}
                                name={"addressDetail"}
                                reference={methods.register}
                            />
                            <ItemBox
                                title={"업태"}
                                name={"businessType"}
                                reference={methods.register({
                                    required : false,
                                    maxLength : 50,
                                })}
                            />
                            <ItemBox
                                title={"종목"}
                                name={"type"}
                                reference={methods.register({
                                    required : false,
                                    maxLength : 50,
                                })}
                            />
                            <BlankDiv/>
                            <BlankDiv/>
                        </ColumnDiv>
                    </RowDiv>

                    <MarginColumnDiv>
                        <ItemBox
                            title={"비고1"}
                            name={"memo1"}
                            reference={methods.register}
                        />
                        <ItemBox
                            title={"비고2"}
                            name={"memo2"}
                            reference={methods.register}
                        />
                    </MarginColumnDiv>

                    <SectionTitle>
                        거래정보
                    </SectionTitle>

                    <RowDiv>
                        <MarginRightColumnDiv>
                            <ItemDiv>
                                <ItemBoxDiv>거래은행</ItemBoxDiv>
                                <SingleSelect
                                    menuItems={props.formResponse?.bankType}
                                    name={"bankId"}
                                    value={props.accountData?.bankId}
                                    reference={methods.register}
                                />
                            </ItemDiv>
                            <ItemBox
                                title={"계좌번호"}
                                name={"bankAccountNumber"}
                                reference={methods.register({
                                    required : false,
                                    pattern: /^((\d{1,})(-(\d{1,})){1,})*$/,
                                    maxLength : 50,
                                })}/>
                            <ItemBox
                                title={"예금주"}
                                name={"bankAccountOwnerName"}
                                reference={methods.register}/>
                        </MarginRightColumnDiv>

                        <ColumnDiv>
                            <PlaceholderItemBox
                                title={"프로판"}
                                subTitle={"(VAT포함)"}
                                placeholder={"kg당 단가"}
                                name={"propanePrice"}
                                reference={methods.register({
                                    required: false,
                                    pattern: /^([0-9])*$/
                                })}
                            />
                            <PlaceholderItemBox
                                title={"부탄"}
                                subTitle={"(VAT포함)"}
                                placeholder={"kg당 단가"}
                                name={"butanePrice"}
                                reference={methods.register({
                                    required: false,
                                    pattern: /^([0-9])*$/
                                })}
                            />
                            <BlankDiv/>
                        </ColumnDiv>
                    </RowDiv>
                </form>
            </FormProvider>
            <TopButton/>

            <DeleteModal
                showModal={show}
                onConfirm={props.onDelete}
                onCloseModal={() => setShow(false)}
            />

        </>
    );
}

const RowDiv = styled.div`
  display: flex;
  flex : 1;
  justify-content: space-between;
`

const CenterDiv = styled(RowDiv)`
  width: 100%;
  align-items: center;
`
const NormalDiv = styled.div`
  display: flex;
  flex : 1;
  justify-content: flex-start;
`

const ColumnDiv = styled(RowDiv)`
  display: flex;
  flex : 1;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`

const MarginRightColumnDiv = styled(ColumnDiv)`
  margin-right : 16px;
`

const BlankDiv = styled.div`
 flex : 1;
`
const MarginColumnDiv = styled(ColumnDiv)`
  margin-top : 42px;
`
const InputText = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  margin-left: 8px;
  margin-right : 16px;
`

const RadioButton = styled.input`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #bec0c2;
  background-color: white;
  align-self: center;
  justify-self: center;
`

const InputDIv = styled.div`
 display: flex;
 align-items : center;
 justify-content : center;
`

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom : 22px;
`

const ButtonsDiv = styled.div`
  display: flex;
`

const AddressSearchButton = styled(PostCodeButton)`
  width: 80px;
  height: 48px;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color : var(--white);
  background-color: var(--blue);
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const SingleSelect = styled(Select).attrs({
    width: "404px",
    height: "48px",
    marginLeft: "8px"
})``


export default PurchaseAccountRegist;