import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {RowDiv} from "../component/RowDiv";
import {ItemBoxDiv} from "../component/ItemBox";
import {CommonButton} from "../component/CommonButton";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {SpaceBetweenDiv} from "../TankScreen";
import Input from "../../../components/style/input/Input";
import Button from "../../../components/style/button/Button";
import {FormProvider, useForm} from "react-hook-form";
import Select from "../../../components/style/select/Select";
import {useLocation} from "react-router-dom";
import HorizontalSpace from "../../../components/HorizontalSpace";
import {CertificateForm} from "../../../api/tank/certification/getCertificatelForm";
import StringItemSelect from "../../../components/style/select/StringItemSelect";

interface TankCertificationComponentProps {
    form? : CertificateForm
    result? : number
    selectCount? : number
    getList : (dateType : number, startDate : string, searchType : number, searchQuery : string)=>void
    download : () => void
}

type TankSearch ={
    dateType : number
    year : string
    searchType : number
    searchQuery : string
}

function TankCertificateScreen(props: TankCertificationComponentProps) {

    const methods = useForm<TankSearch>({
        mode: "onBlur",
        criteriaMode : "all"
    })

    const [type, setType] = useState(1)
    const location = useLocation()
    const params = new URLSearchParams(location.search);

    useEffect(()=>{
        // if(props.form && props.form.dateType){
        if(props.form){
            // methods.setValue("year", "전체")
            // console.log(methods.watch("year"), "year watch")
        }
        // }
    },[props.form])

    function onSubmit(){
        props.getList(
            methods.watch("dateType"),
            methods.watch("year"),
            methods.watch("searchType"),
            methods.watch("searchQuery")
        )
    }

    return (
        <FormProvider {...methods}>
            <form>
                <RowDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <TankSelect
                        menuItems={props.form?.searchType}
                        name={"searchType"}
                        reference={methods.register}
                        defaultValue={0}
                    />
                    <Searchbar placeholder={"검색어를 입력하세요"} name={"searchQuery"} ref={methods.register}/>
                    <SearchButton onClick={methods.handleSubmit(onSubmit)}>검색</SearchButton>
                </RowDiv>
                <RowDiv>
                    <ItemBoxDiv>
                        제조번호
                    </ItemBoxDiv>
                    <TankYearTypeSelect
                        menuItems={props.form?.dateType}
                        name={"dateType"}
                        reference={methods.register}
                        defaultValue={2}
                    />
                    <YearSelect
                        menuItems={props.form?.dateType[methods.watch("dateType")-1]?.value}
                        name={"year"}
                        reference={methods.register}
                        value={props.form?.dateType[methods.watch("dateType") - 1]?.value[0].value}
                    />
                </RowDiv>

                <HorizontalSpace/>

                <SpaceBetweenDiv>
                    <TableTitle>탱크관리</TableTitle>
                    <CertificateButton onClick={props.download}>합격증 다운로드</CertificateButton>
                </SpaceBetweenDiv>

                <SpaceBetween>
                    <div>
                        <ResultText>검색결과</ResultText>
                        <ResultCount>{props.result}</ResultCount>
                        <ResultText>건</ResultText>
                    </div>
                    <div>
                        <ResultText fontSize={"10px"}>선택</ResultText>
                        <ResultCount>{props.selectCount}</ResultCount>
                        <ResultText>건</ResultText>
                    </div>

                </SpaceBetween>
            </form>
        </FormProvider>
    );
}

const SpaceBetween = styled(RowDiv)`
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 11px;
`

const Searchbar = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 402px;
  height: 32px;
  padding-left: 12px;
  margin-left: 8px;
  font-size: 10px;
`

const CertificateButton = styled(CommonButton)`
  width : 91px;
`
const SearchButton = styled(Button)`
  width: 43px;
  height: 32px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 10px;
`

const TableTitle = styled(FixedWeightText)`
  font-size : 14px;
  font-weight: 500;
`

const TankSelect = styled(Select).attrs({
    width: "120px",
    height: "32px",
    marginLeft: "8px"
})``

const TankYearTypeSelect = styled(Select).attrs({
    width: "149.5px",
    height: "32px",
    marginLeft: "8px"
})``

const YearSelect = styled(StringItemSelect).attrs({
    width: "149.5px",
    height: "32px",
    marginLeft: "8px"
})``

const ResultText = styled(FixedWeightText)`
  font-size: 10px;
`
const ResultCount = styled(ResultText)`
  font-size: 10px;
  color: var(--blue);
  margin-left: 2px;
`
export default TankCertificateScreen