import React from "react";
import styled from 'styled-components';
import logo from '../../../assets/images/header/img_logo@3x.png';
import DefaultWrapperDiv from "../../style/div/DefaultWrapperDiv";
import FixedWeightText from "../../style/text/FixedWeightText";

interface ComponentProps {
    width?: number;
    margin_left?: number;
}

function LoginFooter(props: ComponentProps) {
    return (
        <Div>
            <DefaultWrapperDiv justifyContent={"flex-start"} color={"var(--gray-6)"}>
                <Row>
                    <LogoGroup>
                        <div>
                            <img src={logo} width={24} height={24}/>
                        </div>
                        <FooterText>주식회사 디앨</FooterText>
                    </LogoGroup>
                    <TextGroup>
                        <FooterText>회사소개</FooterText>
                        <FooterText>공지사항</FooterText>
                        <FooterText>서비스 이용약관</FooterText>
                        <FooterText>개인정보처리방침</FooterText>
                    </TextGroup>
                </Row>
            </DefaultWrapperDiv>
        </Div>
    );
}

const Div = styled.div`
  display: flex;
  flex : 1;
  justify-content: center;
  margin-top : 80px;
  background-color: var(--gray-6);
  height: 120px;
`

const Row = styled.div`
  display: flex;
  flex : 1;
  flex-direction: row;
  width: 1080px;
  justify-content: space-between;
  margin-top: 28px;
`
const LogoGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
  width: 105px;
`
const TextGroup = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 355px;
  justify-content: space-between;
`

const FooterText = styled(FixedWeightText)`
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  color: var(--gray-3);
`

export default LoginFooter;