import React, {useEffect, useState} from 'react';
import TankCertificateScreen from "./TankCertificateScreen";
import {getCertificateList} from "../../../api/tank/certification/getCertificateList";
import TankListTable from "./TankListTable";
import TopButton from "../../../components/style/button/TopButton";
import {getCertificateForm} from "../../../api/tank/certification/getCertificatelForm";
import styled from "styled-components";
import {fileDownload, SingleFileDownload, TankInfo} from "../../../lib/fileDownload";
import {useAsync} from "../../../hooks/useAsync";
import {useLocation} from 'react-router-dom';

interface TankCertificationContainerComponentProps {
}

function TankCertificationContainer(props: TankCertificationContainerComponentProps) {

    const [tankInfo, setTankInfo] = useState<Array<TankInfo>>([])

    const {data, execute: getList} = useAsync(getCertificateList, true, [2, "-999999999-01-01", 3, ""])
    const {data: form} = useAsync(getCertificateForm, true)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        if(location.search !== "") {
            getList(params.get("startDate"), params.get("endDate"), params.get("searchType"), params.get("searchQuery"))
        }
    },[location.search])

    function download() {
        if (tankInfo.length == 0) {
            return;
        }
        if (tankInfo.length == 1) {
            SingleFileDownload(tankInfo[0])
        } else {
            fileDownload(tankInfo)
        }
    }

    return (
        <>
            <TankCertificateScreen
                form={form}
                getList={getList}
                result={data?.length}
                selectCount={tankInfo?.length}
                download={download}
            />
            <TankListTable
                data={data}
                onRowChanged={(urls: Array<TankInfo>) => setTankInfo(urls)}
            />
            <Div>
                <TopButton/>
            </Div>
        </>
    );
}

export const Div = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export default TankCertificationContainer