import React, {useEffect, useState} from 'react';
import BaseModal from "../../../../components/modal/BaseModal";
import {useAsync} from "../../../../hooks/useAsync";
import {deleteEtcProduct} from "../../../../api/basic/etcproduct/deleteEtcProduct";
import {MethodState, ProductMethodType} from "../GasProduct/GasProductContainer";
import {etcProductRegistration, EtcProductRegistRequest} from "../../../../api/basic/etcproduct/etcProductRegistration";
import {putEtcProductRegistration} from "../../../../api/basic/etcproduct/putEtcProductRegistration";
import {getEtcProduct} from "../../../../api/basic/etcproduct/getEtcProduct";
import EtcProductModalScreen from "./EtcProductModalScreen";

interface BasicModalProps {
    type?: ProductMethodType
    onCloseModal: () => void
    onOpen?: () => void
}

function EtcProductModalContainer(props: BasicModalProps) {

    const {data: saveResponse, error: saveError, isLoading: saveLoading, execute: save} = useAsync(etcProductRegistration)
    const {data: updateResponse, error: updateError, isLoading: updateLoading, execute: update} = useAsync(putEtcProductRegistration)
    const {data: deleteResponse, isLoading: deleteLoading, execute: deleteEtcData} = useAsync(deleteEtcProduct)
    const {data: getResponse, isLoading: getLoading, execute: getEtcData, reset : resetEtcData} = useAsync(getEtcProduct)

    const [showModal, setShowModal] = useState(false)

    function closeModal() {
        setShowModal(false)
        resetEtcData()
        props.onCloseModal()
    }

    function saveEtcProduct(request: EtcProductRegistRequest) {
        save(request)
    }

    function updateEtcProduct(request: EtcProductRegistRequest) {
        if (props.type?.payload) {
            update(props.type?.payload, request)
        }
    }

    function removeEtcProduct() {
        if (props.type?.payload) {
            if(deleteEtcData(props.type?.payload)){
                alert("삭제됐습니다.")
                closeModal()
            }
        }
    }

    useEffect(() => {
        if (saveResponse) {
            alert("저장되었습니다.")
        }
        switch (props.type?.type) {
            case MethodState.UPDATE:
                getEtcData(props.type?.payload)
            case MethodState.SAVE:
                setShowModal(true)
                break;
        }
    }, [props.type])

    return (
        <BaseModal showModal={showModal} onCancel={closeModal}>
            <EtcProductModalScreen
                data={getResponse}
                save={saveEtcProduct}
                update={updateEtcProduct}
                delete={removeEtcProduct}
                closeModal={closeModal}/>
        </BaseModal>

    );
}


export default EtcProductModalContainer