import React, {useEffect, useState} from 'react';
import {useAsync} from "../../../../hooks/useAsync";
import GasProductModalScreen from "./GasProductModalScreen";
import {MethodState, ProductMethodType} from "./GasProductContainer";
import {gasProductRegistration, GasProductRegistRequest} from "../../../../api/basic/gasproduct/gasProductRegistration";
import {gasProductRegistrationForm} from "../../../../api/basic/gasproduct/gasProductRegistrationForm";
import {putGasProductRegistration} from "../../../../api/basic/gasproduct/putGasProductRegistration";
import {getGasProduct} from "../../../../api/basic/gasproduct/getGasProduct";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import {deleteGasProduct} from "../../../../api/basic/gasproduct/deleteGasProduct";

interface BasicModalProps {
    type?: ProductMethodType
    onCloseModal: () => void
    onOpen?: () => void
}

function GasProductModalContainer(props: BasicModalProps) {

    const {data: saveResponse, error: saveError, isLoading: saveLoading, execute: save} = useAsync(gasProductRegistration)
    const {data: formResponse, error: formError, isLoading: formLoading} = useAsync(gasProductRegistrationForm, true)
    const {data: updateResponse, error: updateError, isLoading: updateLoading, execute: update} = useAsync(putGasProductRegistration)
    const {data: deleteResponse, isLoading: deleteLoading, execute: deleteGasData} = useAsync(deleteGasProduct)
    const {data: getResponse, isLoading: getLoading, execute: getGasData, reset: resetGasData} = useAsync(getGasProduct)

    const [showModal, setShowModal] = useState(false)

    function closeModal() {
        setShowModal(false)
        resetGasData()
        props.onCloseModal()
    }

    function saveGasProduct(request: GasProductRegistRequest) {
        save(request)
    }

    function updateGasProduct(request: GasProductRegistRequest) {
        if (props.type?.payload) {
            update(props.type?.payload, request)
        }
    }

    function removeGasProduct() {
        if (props.type?.payload) {
            if(deleteGasData(props.type?.payload)){
                alert("삭제됐습니다.")
                closeModal()
            }
        }
    }

    useEffect(() => {
        switch (props.type?.type) {
            case MethodState.UPDATE:
                getGasData(props.type?.payload)
            case MethodState.SAVE:
                setShowModal(true)
                break;
        }
    }, [props.type])

    return (
        <ConfirmModal showModal={showModal} onCancel={closeModal}>
            <GasProductModalScreen
                data={getResponse}
                form={formResponse}
                save={saveGasProduct}
                update={updateGasProduct}
                delete={removeGasProduct}
                closeModal={closeModal}/>
        </ConfirmModal>

    );
}


export default GasProductModalContainer