import React, {useEffect} from 'react';
import ErpTableComponent from "../../components/table/ErpTable";
import styled from "styled-components";
import Input from "../../components/style/input/Input";
import {ItemBoxDiv} from "../tank/component/ItemBox";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import Button from "../../components/style/button/Button";
import {Link, useHistory} from "react-router-dom";
import {LINK_PURCHASE_REGISTRATION} from "../../resources/LinkConstants";
import DetailListTable from "./components/DetailListTable";
import SumListTable from "./components/SumListTable";
import {PurchaseList} from "../../api/purchase/getPurchaseList";
import {PurchaseDetailList} from "../../api/purchase/getPurchaseDetailList";
import DeleteButton from "../../components/button/DeleteButton";
import {useFormContext} from "react-hook-form";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";

interface PurchaseListScreenProps {
    listData?: PurchaseList
    getDetail: (args: any) => void
    detailListData?: PurchaseDetailList
    deletePurchase: (uid: string | undefined) => void
}

function PurchaseListScreen(props: PurchaseListScreenProps) {

    const {setValue, register, watch} = useFormContext();

    const history = useHistory()

    function onCellClick(data: any) {
        props.getDetail(data.uid)
    }

    useEffect(() => {
        setValue("propaneRemainAmount", props.detailListData?.propaneRemainAmount)
        setValue("butaneRemainAmount", props.detailListData?.butaneRemainAmount)
    },[props.detailListData])

    function updateButtonClick(){
        if(props.detailListData?.uid){
            history.replace(LINK_PURCHASE_REGISTRATION + `/${props.detailListData?.uid}`)
        }
    }

    return (
        <div>
            <MatchHorizontalSpace height={"1px"}/>
            <RowDiv>
                <div>
                    <SubTitle>
                        <SubtitleText>입고</SubtitleText>
                        <ResultText>검색결과</ResultText>
                        <ResultCount>{props.listData?.purchaseList?.length}</ResultCount>
                        <ResultText>건</ResultText>
                    </SubTitle>

                    <ErpTableComponent
                        rowHeight={36}
                        tableWidth={352}
                        tableHeight={475}
                        onCellClicked={() => {
                        }}
                        onRowClicked={onCellClick}
                        rowData={props.listData?.purchaseList}
                        columns={[
                            {
                                id: "date",
                                title: "일자",
                                accessor: "date",
                                sortable: true,
                                width: 117.3,
                            },
                            {
                                id: "accountName",
                                title: "매입처",
                                accessor: "accountName",
                                sortable: true,
                                width: 117.3,

                            }, {
                                id: "chargePerson",
                                title: "이름",
                                accessor: "chargePerson",
                                sortable: true,
                                width: 117.3,
                            }]
                        }
                    />
                </div>

                <div>
                    <RightSubTitle>
                        <SubtitleText>상세</SubtitleText>
                        <ButtonSection>
                            <DeleteButton
                                height={"36px"}
                                width={"100px"}
                                description={"입고입력"}
                                onConfirm={() => props.deletePurchase(props.detailListData?.uid)}
                            />

                            <ModifyButton onClick={updateButtonClick}>수정</ModifyButton>

                            <Link to={LINK_PURCHASE_REGISTRATION} style={{textDecoration: 'none'}}>
                                <RegistrationButton>등록</RegistrationButton>
                            </Link>
                        </ButtonSection>
                    </RightSubTitle>

                    <DetailListTable data={props.detailListData?.detailList}/>

                    <RemainBoard>
                        <RemainSection>
                            <ItemDiv>
                                <ItemBoxDiv>
                                    잔량(프로판)
                                </ItemBoxDiv>
                                <ItemInput name={"propaneRemainAmount"} ref={register()} readOnly={true}/>
                            </ItemDiv>
                            <ItemDiv>
                                <ItemBoxDiv>
                                    잔량(부탄)
                                </ItemBoxDiv>
                                <ItemInput name={"butaneRemainAmount"} ref={register()} readOnly={true}/>
                            </ItemDiv>
                        </RemainSection>
                        <RemainSection>
                            <MemoDiv>
                                <MemoTextDiv>
                                    비고
                                </MemoTextDiv>
                                <ItemInput value={props.detailListData?.memo} readOnly={true}/>
                            </MemoDiv>
                        </RemainSection>
                    </RemainBoard>

                    <SumListTable
                        data={props.detailListData?.detailList}
                        info={props.detailListData}
                    />
                </div>
            </RowDiv>
        </div>
    );
}

const RowDiv = styled.div`
  display: flex;
  flex : 1;
  justify-content: space-between;
  margin-bottom: 32px;
`


const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center;
`

const MemoDiv = styled(ItemDiv)`
  margin-left: 8px;
`

const MemoTextDiv = styled(ItemBoxDiv)`
  height: 80px;
`

const ItemInput = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 240px;
  height: 32px;
  margin-left: 8px;
`

const RemainBoard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex : 1;
  margin-bottom: 16px;
  margin-top : 16px;
`

const RemainSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 352px;
  height: 60px;
`

const RightSubTitle = styled(SubTitle)`
  justify-content: space-between;
  width: 712px;
`

const SubtitleText = styled(FixedWeightText)`
  font-size: 16px;
  font-weight: 400;
  margin-right: 24px;
`

const ResultText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: normal;
`

const ResultCount = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: normal;
  color : var(--blue);
`

const ButtonSection = styled.div`
  display: flex;
  width: 320px;
  justify-content: space-between;
`

const ModifyButton = styled(Button)`
  height: 36px;
  width: 100px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: var(--gray-3);
   color : var(--white);
`

const RegistrationButton = styled(Button)`
  height: 36px;
  width: 100px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: var(--blue);
  color : var(--white);
`
export default PurchaseListScreen