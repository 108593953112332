import React from 'react';
import ErpTableComponent from "./ErpTable";
import styled from "styled-components";
import {LINK_PURCHASE_ACCOUNT_REGISTRATION} from "../../resources/LinkConstants";
interface AccountSearchTableComponentProps {
    data? : any
    closeModal : () => void
    returnUid : (uid : string) => void
}

function AccountSearchTable (props : AccountSearchTableComponentProps){

    function select(uid : string){
        props.returnUid(uid)
        props.closeModal()
    }

    function activeCellRenderer(data : any) {
        return <SelectButton onClick={()=> select(data.value)}>선택</SelectButton>
    }

    return (
        <ErpTableComponent
            tableWidth={1016}
            tableHeight={528}
            rowData={props.data}
            onCellClicked={()=>{}}
            columns={[
                {
                    id: "index",
                    title: "순번",
                    accessor: "",
                    sortable: true,
                    width: 96,
                    autoIncrement: true,
                }, {
                    id: "select",
                    title: "선택",
                    accessor: "uid",
                    sortable: true,
                    width: 96,
                    cellRendererFramework: activeCellRenderer,
                    clickable : true,
                }, {
                    id: "code",
                    title: "코드",
                    accessor: "code",
                    sortable: true,
                    width: 96,
                    clickable : false
                }, {
                    id: "accountName",
                    title: "판매처명",
                    accessor: "accountName",
                    sortable: true,
                    width: 176,
                    clickable : false
                }, {
                    id: "tel",
                    title: "휴대폰번호",
                    accessor: "tel",
                    sortable: true,
                    width: 176,
                    clickable : false
                }, {
                    id: "address",
                    title: "주소",
                    accessor: "address",
                    sortable: true,
                    width: 256,
                    clickable : false
                }, {
                    id: "",
                    title: "",
                    accessor: "",
                    width: 120,
                    clickable : false
                },]
            }

        />
    );
}

const SelectButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
`
export default AccountSearchTable