import React, {ReactNode} from "react";
import styled from "styled-components";
import CircularIndeterminate from "./CircularIndeterminate";
import Button from "./Button";

type ProgressButtonProps = {
    isLoading?: boolean
    children?: ReactNode | string
}

const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`
const Progress = styled(CircularIndeterminate)`
`

const ProgressButton = styled(Button).attrs((props: ProgressButtonProps) => ({
    children:
        <Div>
            <Progress/>
            {props.children}
        </Div>
}))<ProgressButtonProps>`
    > * {
      > :first-child {
        visibility: ${(props: ProgressButtonProps) => props.isLoading ? "visible" : "hidden"};
      }
    }
`

export default ProgressButton;