import React, {useState} from 'react';
import FixedWeightText from "../../components/style/text/FixedWeightText";
import styled from "styled-components";
import Input from "../../components/style/input/Input";
import {ItemBoxDiv} from "../tank/component/ItemBox";
import Datepicker from "../../components/style/datepicker/Datepicker";
import Moment from "moment";
import Button from "../../components/style/button/Button";
import Select, {MenuItem} from "../../components/style/select/Select";
import {useFormContext} from "react-hook-form";
import {useHistory, useLocation} from 'react-router-dom';
import {LINK_PURCHASE} from "../../resources/LinkConstants";

interface PurchaseHeaderProps {
    onClickSearchButton?: (...args: any) => void
}

enum Period {
    THREEMONTH,
    SIXMONTH,
    ONEYEAR,
    THISEYEAR
}



function PurchaseHeaderScreen(props: PurchaseHeaderProps) {

    const {watch, register, handleSubmit, setValue} = useFormContext();

    const [period, setPeriod] = useState(0);

    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const DateFormat = 'YYYY-MM-DD'

    const history = useHistory()

    const selectMockItem: Array<MenuItem> = [{id: 1, title: "매입거래처명"}, {id: 2, title: "담당자명"}]

    function handleChangeStartDate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setValue("startDate", event.target.value)
    }

    function handleChangeEndDate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setValue("endDate", event.target.value)
    }

    function onClickMonth(month: number) {
        setValue("startDate", Moment().subtract(month, "months").format(DateFormat))
        setValue("endDate", Moment().format(DateFormat))

        switch (month) {
            case 3:
                setPeriod(Period.THREEMONTH)
                break;
            case 6:
                setPeriod(Period.SIXMONTH)
                break;
            case 12:
                setPeriod(Period.ONEYEAR)
                break;
        }
    }

    function onClickThisYear() {
        const year = Moment().format("YYYY")
        setValue("startDate", Moment().format(year.concat("-01-01")))
        setValue("endDate", Moment().format(DateFormat))

        setPeriod(Period.THISEYEAR)
    }

    function onSubmit() {
        if (props.onClickSearchButton) {
            // props.onClickSearchButton(methods.watch().startDate, methods.watch().endDate, methods.watch().searchType, methods.watch().searchQuery)
            const query = watch().searchQuery == undefined ? "" : watch().searchQuery

            history.push(LINK_PURCHASE + "?startDate=" + watch().startDate + "&endDate=" + watch().endDate + "&searchType=" + watch().searchType + "&searchQuery=" + query)
        }
    }

    return (
        <>
            <HeaderScreen>
                <HeaderDiv>
                    <FixedWeightText fontSize={"16px"} fontWeight={500}>
                        입고입력
                    </FixedWeightText>
                </HeaderDiv>
                <ItemDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <PurchaseSelect menuItems={selectMockItem}
                                    name={"searchType"}
                                    defaultValue={Number(params.get("searchType")) || 1}
                                    reference={register}/>
                    <ItemInput
                        defaultValue={params.get("searchQuery") || ""}
                        name={"searchQuery"}
                        ref={register}/>
                    <SearchButton onClick={handleSubmit(onSubmit)}>검색</SearchButton>
                </ItemDiv>
                <ItemDiv>
                    <ItemBoxDiv>
                        일자
                    </ItemBoxDiv>
                    <HalfDatePicker
                        defaultValue={params.get("startDate") || Moment().format('YYYY-MM-01')}
                        onChange={handleChangeStartDate}
                        reference={register}
                        name={"startDate"}
                    />
                    <div>
                        ~
                    </div>
                    <HalfDatePicker
                        defaultValue={params.get("endDate") || Moment().format('YYYY-MM-DD')}
                        onChange={handleChangeEndDate}
                        reference={register}
                        name={"endDate"}
                    />
                    <SampleButton>샘플</SampleButton>
                </ItemDiv>
            </HeaderScreen>
        </>
    );
}

const SampleButton = styled(Button)`
  width: 80px;
  height: 32px;
  box-sizing: border-box;
  flex-grow: 0;
  padding: 8px 12px;
  background-color: var(--white);
  border-width: 1px;
  border-color: var(--gray-3);
`
const HeaderScreen = styled.div`
  margin-bottom: 32px;
`

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  align-items: center;
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 396px;
  height: 36px;
  margin-left: 8px;
`

const SearchButton = styled(Button)`
  width: 80px;
  height: 36px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
`

const HalfDatePicker = styled(Datepicker)`
  width: 129px;
  height: 36px;
  margin-right: 8px;
`
export const PurchaseSelect = styled(Select).attrs({
    width: "120px",
    height: "36px",
    marginLeft: "8px"
})``

export default PurchaseHeaderScreen