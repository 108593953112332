import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {MenuItem} from "../../components/style/select/Select";

export async function getSignUpForm() {
    const response = await axios.get<BaseResponse<SignUpForm>>("/signUp/form")

    return response.data
}

export type SignUpForm = {
    memberEmailDomainType: Array<MenuItem>,
    memberTelLocalType: Array<MenuItem>,
    memberPhoneCarrierType: Array<MenuItem>
}