import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalHeader from "../../../components/modal/Header";
import BaseModal from "../../../components/modal/BaseModal";
import TextWithCheckBox from "../../../components/style/button/TextWithCheckBox";
import Button from "../../../components/style/button/Button";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {PersonalInformationProtection, ServicePolicy} from "./PolicyContent";


interface SignupModalComponentProps {
    showModal: boolean
    onCloseModal: () => void
    onConfirm: () => void
}

function SignupModal(props: SignupModalComponentProps) {

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [allChecked, setAllChecked] = useState(false);

    const [buttonColor, setButtonColor] = useState("var(--gray-4)")

    useEffect(() => {
        setAllChecked(checked2 && checked1)
        setButtonColor(allChecked ? "var(--blue)" : "var(--gray-4)")
    })

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked1(event.target.checked)
        setChecked2(event.target.checked)
    };

    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked1(event.target.checked)
    };

    const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked2(event.target.checked)
    };

    function onConfirm() {
        closeModal()
        props.onConfirm()
    }

    function closeModal() {
        props.onCloseModal()
    }

    return (
        <BaseModal showModal={props.showModal} onCancel={closeModal}>
            <ModalDiv>
                <ModalHeader title={"이용약관"} onCancel={closeModal}/>
                <ContentDiv>
                    <HeaderContentText fontSize={"24px"} fontWeight={500}>이용정보 및 개인정보 수집 및 동의</HeaderContentText>
                    <ServicePolicyDiv>
                        <FixedWeightText fontSize={"14px"} fontWeight={500}>서비스 이용약관 및 동의</FixedWeightText>
                        
                        <TextWithCheckBox text={"전체동의"} handleChange={handleChangeAll} checked={allChecked}
                                          falseColor={"var(--gray-4)"} trueColor={"var(--black)"}
                                          falseCheckColor={"var(--gray-4)"} trueCheckColor={"var(--blue)"}/>
                    </ServicePolicyDiv>

                    <ScrollDiv readOnly={true} value={ServicePolicy}/>

                    <ServiceDiv>
                        <FixedWeightText fontSize={"14px"} fontWeight={500}>[필수] 서비스 이용약관</FixedWeightText>

                        <TextWithCheckBox text={"동의합니다"} handleChange={handleChange1} checked={checked1}
                                          falseColor={"var(--gray-4)"} trueColor={"var(--black)"}
                                          falseCheckColor={"var(--gray-4)"} trueCheckColor={"var(--blue)"}/>
                    </ServiceDiv>

                    <ScrollDiv readOnly={true} value={PersonalInformationProtection}/>

                    <ServiceDiv>
                        <FixedWeightText fontSize={"14px"} fontWeight={500}>[필수] 개인정보 처리방침</FixedWeightText>
                        <TextWithCheckBox text={"동의합니다"} handleChange={handleChange2} checked={checked2}
                                          falseColor={"var(--gray-4)"} trueColor={"var(--black)"}
                                          falseCheckColor={"var(--gray-4)"} trueCheckColor={"var(--blue)"}/>
                    </ServiceDiv>
                    <ButtonDiv>
                        <CancelButton onClick={closeModal}>
                            <FixedWeightText color={"var(--red)"} fontSize={"14px"} fontWeight={500}>취소</FixedWeightText>
                        </CancelButton>
                        <SignUpButton color={buttonColor} onClick={onConfirm}>
                            <FixedWeightText color={"var(--white)"} fontSize={"14px"} fontWeight={500}>확인</FixedWeightText>
                        </SignUpButton>
                    </ButtonDiv>
                </ContentDiv>
            </ModalDiv>
        </BaseModal>
    );
}

const ModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 720px;
    height: 832px;
    border-radius: 4px;
    background-color: var(--white);
`
const ContentDiv = styled.div`
    display: flex;
    height: 710px;
    flex-direction: column;
    padding : 22px 32px 32px 32px;
`

const ServicePolicyDiv = styled.div`
    display: flex;
    justify-content: space-between;
`

const ServiceDiv = styled(ServicePolicyDiv)`
    padding-top: 16px;
`
const HeaderContentText = styled(FixedWeightText)`
  height: 36px;
  align-items: center;
  margin-bottom: 50px;
`

const ScrollDiv = styled.textarea`
  height: 200px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--pale-grey);
  resize : none;
  padding-top : 24px;
  padding-right: 32px;
  padding-left: 32px;
  margin-top : 16px;
`

const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`
const CancelButton = styled(Button)`
  width: 120px;
  height: 48px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`
const SignUpButton = styled(CancelButton)`
  margin-left: 8px;
  background-color: ${(props) => props.color};
`
export default SignupModal