import React, {useEffect} from 'react';
import {
    purchaseAccountRegistration,
    PurchaseAccountRegistRequest
} from "../../../api/purchase/account/purchaseAccountRegistration";
import {purchaseAccountRegistrationForm} from "../../../api/purchase/account/purchaseAccountRegistrationForm";
import PurchaseAccountRegist from "./PurchaseAccountRegist";
import {useAsync} from "../../../hooks/useAsync";
import {useParams} from "react-router-dom";
import {getPurchaseAccount} from "../../../api/purchase/account/getPurchaseAccount";
import {putPurchaseAccountRegistration} from "../../../api/purchase/account/putPurchaseAccountRegistration";
import {deletePurchaseAccount} from "../../../api/purchase/account/deletePurchaseAccount";


interface PurchaseAccountRegistContainerProps {

}

interface MatchType {
    accountId: string
}

function PurchaseAccountRegistContainer(props: PurchaseAccountRegistContainerProps) {

    const {accountId} = useParams<MatchType>()

    const {data: formResponse, isLoading: formLoading} = useAsync(purchaseAccountRegistrationForm, true)
    const {data: saveResponse, isLoading: saveLoading, execute : regist} = useAsync(purchaseAccountRegistration)
    const {data: updateResponse, isLoading: updateLoading, execute : update} = useAsync(putPurchaseAccountRegistration)
    const {data: getResponse, isLoading: getLoading, execute : getUserData} = useAsync(getPurchaseAccount)
    const {data: deleteResponse, isLoading: deleteLoading, execute : deleteAccount} = useAsync(deletePurchaseAccount)

    function onSubmit(request: PurchaseAccountRegistRequest) {
        if(accountId){
            update(accountId, request)
        }
        else{
            regist(request)
        }
    }

    function onDelete(){
        deleteAccount(accountId)
    }

    useEffect(() =>{
        if(accountId){
            getUserData(accountId)
        }
    },[accountId])

    return (
        <>
            <PurchaseAccountRegist onSubmit={onSubmit} loading={formLoading} formResponse={formResponse} accountData={getResponse} onDelete={onDelete}/>
        </>
    );
}

export default PurchaseAccountRegistContainer