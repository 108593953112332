import React, {useState} from 'react';
import EmployeeScreen from "./EmployeeScreen";
import EmployeeModalContainer from "./EmployeeModalContainer";
import {ProductMethodType} from "../GasProduct/GasProductContainer";

interface EmployeeContainerComponentProps {
}

function EmployeeContainer(props: EmployeeContainerComponentProps) {
    const [type, setType] = useState<ProductMethodType>()

    function onOpenModal(type: ProductMethodType) {
        setType(type)
    }

    function onCloseModal() {
        setType(undefined)
    }

    return (
        <div>
            <EmployeeScreen openModal={onOpenModal}/>
            <EmployeeModalContainer type={type} onCloseModal={onCloseModal}/>
        </div>
    );
}

export default EmployeeContainer