import React, {useState} from 'react';
import styled from 'styled-components';
import BaseModal from "../modal/BaseModal";
import Button from "../style/button/Button";
import Common from "./component/Common";

interface DeleteButtonComponentProps {
    height: string
    width: string
    description?: string
    onConfirm?: () => void
    disabled?: boolean
}

function DeleteButton(props: DeleteButtonComponentProps) {

    const [open, setOpen] = useState(false)

    function onDelete() {
        props.onConfirm?.()
        setOpen(false)
    }

    return (
        <div>
            <StyledDeleteButton
                disabled={props.disabled}
                width={props.width}
                height={props.height}
                onClick={() => setOpen(true)}
            >
                삭제
            </StyledDeleteButton>

            <BaseModal showModal={open} onCancel={() => setOpen(false)}>
                <Common
                    description={props.description}
                    subtitle={"해당 데이터를 삭제하시겠습니까?"}
                    setOpen={() => setOpen(false)}
                    onClick={onDelete}
                />
            </BaseModal>
        </div>
    );
}

const StyledDeleteButton = styled(Button)`
  width: ${(props: DeleteButtonComponentProps) => props.width};
  height: ${(props: DeleteButtonComponentProps) => props.height};
  margin-left: 8px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  color: var(--red);
`

export default DeleteButton