import React from 'react';
import styled from "styled-components";
import logo from "../../assets/images/header/img_logo@3x.png";
import login_picture from "../../assets/images/header/ic_24_profile@3x.png";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LINK_SIGN_IN} from "../../resources/LinkConstants";
import {logOut} from "../../redux/account/logoutModule";
import {RootState} from "../../redux";

interface TitleComponentProps {
}


function Title(props: TitleComponentProps) {
    const userName = useSelector((state: RootState) => state.headerTitleReducer.userName)
    const dispatch = useDispatch()

    function onClickLogOut() {
        dispatch(logOut())
    }

    return (
        <DefaultWrapperDiv direction={"row"} justifyContent={"space-between"}>
            <CenterDiv>
                <LogoImage src={logo}/>
                <TitleText>통소리 ERP</TitleText>
            </CenterDiv>
            {!userName ?
                <CenterDiv>
                    <Link to="/signIn" style={{textDecoration: 'none'}}>
                        <NameText>로그인</NameText>
                    </Link>
                    <Link to="/signUp" style={{textDecoration: 'none'}}>
                        <NameText>회원가입</NameText>
                    </Link>
                </CenterDiv> :
                <CenterDiv>
                    <PictureImage src={login_picture}/>
                    <Link to="/logout" style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <NameText>{userName && `${userName}님`}</NameText>
                    </Link>
                    <Link to={LINK_SIGN_IN}
                          onClick={onClickLogOut}
                          style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <NameText>로그아웃</NameText>
                    </Link>
                </CenterDiv>
            }
        </DefaultWrapperDiv>
    )
}

const CenterDiv = styled.div`
    display: flex;
    align-items: center;
`
const LogoImage = styled.img`
    width: 24px;
    height : 24px;
`

const PictureImage = styled.img`
    width: 24px;
    height : 24px;
    margin-right: 12px;
`

const TitleText = styled.h1`
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin-left : 8px
`

const NameText = styled.button`
    width: fit-content;
    min-width: 64px;
    height: fit-content;
    border-width : 0;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    align-items : center;
    background: transparent;
    color: white;
`
export default Title;