import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function employeeRegistrationForm() {
    const response = await axios.get<BaseResponse<EmployeeRegistrationFormResponse>>("/basic/employee/form")

    return response.data
}

export type EmployeeRegistrationFormResponse = {
    employeeType: Array<EmployeeType>,
    // group : Array<EmployeeGroup>,
}

type EmployeeType = {
    id: number,
    title: string
}
//
// type EmployeeGroup = {
//     id : number,
//     title : string
// }