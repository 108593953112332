import axios from "axios";
import {BaseResponse} from "./BaseResponse";
import {ErpUser} from "./signIn";

export async function signInFromAdmin(signInFromAdminRequest : SignInFromAdminRequest) {
    const response = await axios.post<BaseResponse<ErpUser>>(`/signIn/from/admin`, signInFromAdminRequest)
    return response.data
}

export interface SignInFromAdminRequest {
    adminToken: string
    memberUid: string
}