import React from 'react'
import styled from 'styled-components'

type InputProps = {
    placeholder?: string
    focusBorderColor?: string
    backgroundColor? : string
    color? : string
}

const Input = styled.input<InputProps>`
  display: flex;
  box-sizing: border-box;
  padding-left: 12px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  color: ${(props) => props.color || ("var(--black)")};
  caret-color: var(--blue);
  background-color: ${(props) => props.backgroundColor || ("var(--white)")};
  &:focus{
    outline: none !important;
    border-width: 2px;
    border-color: ${(props) => props.focusBorderColor || ("var(--blue)")};
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: var(--gray-4);
  }
  :-ms-input-placeholder {
    color: var(--gray-4);
  }
`

export default Input
