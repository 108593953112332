import React, {useState} from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import {useHistory} from "react-router-dom";
import {GridApi} from "ag-grid-community";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";

interface TableExampleGridComponentProps {
    data? : Array<any>
    onClick : (uid : string)=> void
}

type TankRetest = {
    uid : string
    applicationDate : string
    completionRequestDate: string
    CompletionDate : string
    Quantity : number
    Progress : string
}

function TankRetestListTable(props: TableExampleGridComponentProps) {

    const tankRests : Array<TankRetest> = [{
        uid : "0001",
        applicationDate : "2021-03-04",
        completionRequestDate : "1234",
        CompletionDate : "0001",
        Quantity : 1,
        Progress : "접수중(1/1)",

    }, {
        uid : "0002",
        applicationDate : "2021-03-05",
        completionRequestDate : "1234",
        CompletionDate : "0001",
        Quantity : 2,
        Progress : "접수완료(1/2)",
    }, {
        uid : "0003",
        applicationDate : "2021-03-05",
        completionRequestDate : "1234",
        CompletionDate : "0001",
        Quantity : 3,
        Progress : "요청완료(3/3)",
    }]

    function onCellClicked(uid: string) {
        props.onClick(uid)
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                onCellClicked={onCellClicked}
                rowData={tankRests}
                columns={[
                    {
                        id: "index",
                        title: "순번",
                        accessor: "",
                        sortable: true,
                        width: 80,
                        autoIncrement: true
                    }, {
                        id: "applicationDate",
                        title: "신청일",
                        accessor: "applicationDate",
                        sortable: true,
                        clickable : true,
                        width: 120,

                    }, {
                        id: "completionRequestDate",
                        title: "완료요청일",
                        accessor: "completionRequestDate",
                        width: 120,
                    }, {
                        id: "CompletionDate",
                        title: "완료일",
                        accessor: "CompletionDate",
                        width: 120,
                    }, {
                        id: "Quantity",
                        title: "수량",
                        accessor: "Quantity",
                        width: 100,
                    }, {
                        id: "Progress",
                        title: "진행도",
                        accessor: "Progress",
                        width: 160,
                    }, {
                        id: "",
                        title: "",
                        accessor: "",
                        width: 400,
                    }
                ]}/>
        </>

    )
}

export default TankRetestListTable
