import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../text/FixedWeightText";
import Button from "./Button";
import SignupModal from "../../../pages/signup/component/SignupModal";

interface CancelButtonComponentProps {
    show : boolean
    setShow : (show : boolean) => void
}

function DeleteButton(props: CancelButtonComponentProps) {


    return (
        <>
        <StyledButton onClick={() => props.setShow(!props.show)}>
            <ButtonText>삭제</ButtonText>
        </StyledButton>
        </>
    );
}

const StyledButton = styled(Button)`
  width: 80px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-left: 8px;
`

const ButtonText = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  width: 26px;
  height: 20px;
  font-size: 13px;
  color: var(--red);
`

export default DeleteButton