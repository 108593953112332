import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import BaseModal from "./BaseModal";
import ModalHeader from "./Header";
import FixedWeightText from "../style/text/FixedWeightText";
import TextWithCheckBox from "../style/button/TextWithCheckBox";
import Button from "../style/button/Button";



interface DeleteModalComponentProps {
    showModal: boolean
    onCloseModal: () => void
    onConfirm?: () => void
}

function DeleteModal(props: DeleteModalComponentProps) {


    const [buttonColor, setButtonColor] = useState("var(--gray-4)")

    function onConfirm() {
        props.onConfirm?.()

        closeModal()
    }

    function closeModal() {
        props.onCloseModal()
    }

    return (
        <BaseModal showModal={props.showModal} onCancel={closeModal}>
            <ModalDiv>
                <ContentDiv>
                    <HeaderContentText>매입거래처 삭제</HeaderContentText>
                    <SubText>해당 매입거래처를 정말 삭제하시겠습니까?</SubText>
                    <ButtonDiv>
                        <CancelButton onClick={closeModal}>
                            <FixedWeightText color={"var(--red)"} fontSize={"14px"} fontWeight={500}>취소</FixedWeightText>
                        </CancelButton>
                        <SignUpButton color={buttonColor} onClick={onConfirm}>
                            <FixedWeightText color={"var(--white)"} fontSize={"14px"} fontWeight={500}>삭제</FixedWeightText>
                        </SignUpButton>
                    </ButtonDiv>
                </ContentDiv>
            </ModalDiv>
        </BaseModal>
    );
}

const ModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 200px;
    border-radius: 4px;
    background-color: var(--white);
`
const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding : 22px 32px 32px 32px;
`

const HeaderContentText = styled(FixedWeightText)`
  width: 120px;
  height: 28px;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
`

const SubText = styled(HeaderContentText)`
  width: 249px;
  font-size: 14px;
  margin-bottom: 32px;
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CancelButton = styled(Button)`
  width: 120px;
  height: 48px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`
const SignUpButton = styled(CancelButton)`
  margin-left: 8px;
  background-color: ${(props) => props.color};
`

export default DeleteModal