import {call, delay, put} from 'redux-saga/effects';
import {AsyncActionCreatorBuilder, PayloadAction} from 'typesafe-actions';
import handleHttpError from "../api/HttpErrorHandler";
import lodash from "lodash";
/*
  유틸함수의 재사용성을 높이기 위하여 함수의 파라미터는 언제나 하나의 값을 사용하도록 하고,
  action.payload 를 그대로 파라미터로 넣어주도록 설정합니다.
  만약에 여러가지 종류의 값을 파라미터로 넣어야 한다면 객체 형태로 만들어줘야 합니다.
*/
type PromiseCreatorFunction<P, T> = ((payload: P) => Promise<T>) | (() => Promise<T>);

// action 이 payload 를 갖고 있는지 확인합니다.
// __ is __ 문법은 Type guard 라고 부릅니다 https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-type-assertions
function isPayloadAction<P>(action: any): action is PayloadAction<string, P> {
    return action.payload !== undefined;
}

export default function createAsyncSaga<T1, P1, T2, P2, T3, P3>(
    asyncActionCreator: AsyncActionCreatorBuilder<any, any, any>,
    promiseCreator: PromiseCreatorFunction<P1, P2>
) {
    return function* saga(action: ReturnType<typeof asyncActionCreator.request>) {
        try {
            convertToPerfectObject(action.payload)
            const response = isPayloadAction<P1>(action)
                ? yield call(promiseCreator, action.payload)
                : yield call(promiseCreator);
            yield delay(500)

            if (response.code == 200) {
                yield put(asyncActionCreator.success(response.result));
            } else {
                yield call(() => handleHttpError(response.code, response.message))
                yield put(asyncActionCreator.failure(response.message));
            }
        } catch (e) {
            yield delay(1000)
            yield call(() => alert("일시적인 오류입니다."))
            yield put(asyncActionCreator.failure(e));
        }
    };
}

function convertToPerfectObject(data: any) {
    if (data == null) {
        return
    }
    removeEmptyElement(data)
    removeEmptyObject(data)
}

function removeEmptyElement(data: any) {
    const array = getStringValuesFromEnum(data)
    if (array.length == 1) {
        return
    }

    array.forEach((value => {
        removeEmptyElement(data[value])
        if (data[value] == '') {
            delete data[value]
        }
    }))
}

function removeEmptyObject(data: any) {
    getStringValuesFromEnum(data).forEach(value => {
        if (lodash.isObject(data[value]) && lodash.isEmpty(data[value])) {
            delete data[value]
        }
    })
}

function getStringValuesFromEnum<T>(myEnum: T): (keyof T)[] {
    return Object.keys(myEnum) as any
}