import React from 'react';

interface CollectMoneyScreenComponentProps {

}

function CollectMoneyScreen(props: CollectMoneyScreenComponentProps) {
    return (
        <div>
            수금화면
        </div>
    );
}

export default CollectMoneyScreen