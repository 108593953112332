import React from 'react';

interface CustomerScreenComponentProps {

}

function CustomerScreen(props: CustomerScreenComponentProps) {
    return (
        <div>
            고객관리 화면
        </div>
    );
}

export default CustomerScreen