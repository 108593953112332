import {useEffect} from "react";

const useNumber = (methods : any, name : string) => {

    useEffect(()=>{
        const str = methods.watch(name)
        if(!Number(str)){
            methods.setValue(name, str.slice(0, str.length-1))
        }

    },[methods.watch(name)])

    return
};

export default useNumber