import React, {useEffect, useState} from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import {PurchaseDetail, PurchaseDetailList} from "../../../api/purchase/getPurchaseDetailList";
import {useFormContext} from "react-hook-form";
import {PriceType} from "../regist/PurchaseRegistContainer";

interface SumListTableComponentProps {
    info?: PurchaseDetailList
    data?: Array<PurchaseDetail>
    sum? : number
    price? : PriceType
}

export type ResultData = {
    productName: string,
    totalInOut: number,
    chargeAmount : number,
    remain?: number,
    unitPrice?: number,
    supplyPrice: number,
    surtax: number,
    totalPrice: number,
}

function SumListTable(props: SumListTableComponentProps) {

    const {watch} = useFormContext()

    const [data, setData] = useState<Array<ResultData>>([])

     useEffect(() => {
         if(props.data){
             const resultPropane : ResultData = {
                 productName : "프로판",
                 totalInOut : 0,
                 remain : watch("propaneRemainAmount"),
                 chargeAmount : 0,
                 unitPrice : props.info?.propanePrice == undefined ? props.price?.propanePrice : props.info?.propanePrice,
                 supplyPrice : 0,
                 surtax : 0,
                 totalPrice : 0,
             }

             const resultButane : ResultData = {
                 productName : "부탄",
                 totalInOut : 0,
                 remain : watch("butaneRemainAmount"),
                 unitPrice : props.info?.butanePrice == undefined ? props.price?.butanePrice : props.info?.butanePrice,
                 chargeAmount : 0,
                 supplyPrice : 0,
                 surtax : 0,
                 totalPrice : 0,
             }

             const resultData : ResultData = {
                 productName : "합계",
                 totalInOut : 0,
                 remain :0,
                 unitPrice :0,
                 chargeAmount : 0,
                 supplyPrice : 0,
                 surtax : 0,
                 totalPrice : 0,
             }

             console.log(props.data)

             props.data?.map(
                 (item) => {
                     if(item.type == "부탄"){
                         resultButane.totalInOut = Number(resultButane.totalInOut) + Number(item.purchaseCount) * (item.weight == undefined ? 0 : Number(item.weight))
                     }
                     else if(item.type == "프로판"){
                         resultPropane.totalInOut = Number(resultPropane.totalInOut) +  Number(item.purchaseCount) * (item.weight == undefined ? 1 : Number(item.weight))
                     }
                 }
             )

             resultPropane.chargeAmount = Number(resultPropane.totalInOut) - Number(resultPropane.remain)
             resultPropane.supplyPrice = resultPropane.chargeAmount * Number(resultPropane.unitPrice)
             resultPropane.surtax = 0.1 * resultPropane.supplyPrice
             resultPropane.totalPrice = resultPropane.supplyPrice + resultPropane.surtax

             resultButane.chargeAmount = Number(resultButane.totalInOut) - Number(resultButane.remain)
             resultButane.supplyPrice = resultButane.chargeAmount * Number(resultButane.unitPrice)
             resultButane.surtax = 0.1 * resultButane.supplyPrice
             resultButane.totalPrice = resultButane.supplyPrice + resultButane.surtax

             resultData.surtax = resultButane.surtax + resultPropane.surtax
             resultData.totalPrice = resultButane.totalPrice + resultPropane.totalPrice
             resultData.supplyPrice = resultButane.supplyPrice + resultPropane.supplyPrice

             setData([resultPropane, resultButane, resultData])
         }

    },[props.sum, watch])


    return (
        <ErpTableComponent
            tableWidth={712}
            tableHeight={147}
            rowData={data}
            rowHeight={36}
            columns={[
                {
                    id: "productName",
                    title: "품 명",
                    accessor: "productName",
                    sortable: true,
                    width: 88,

                }, {
                    id: "totalInOut",
                    title: "총입고량",
                    accessor: "totalInOut",
                    sortable: true,
                    width: 88,
                }, {
                    id: "remain",
                    title: "잔량",
                    accessor: "remain",
                    sortable: true,
                    width: 88,
                },{
                    id: "chargeAmount",
                    title: "충전량",
                    accessor: "chargeAmount",
                    sortable: true,
                    width: 88,
                },
                {
                    id: "unitPrice",
                    title: "단가",
                    accessor: "unitPrice",
                    sortable: true,
                    width: 88,
                }, {
                    id: "supplyPrice",
                    title: "공급가액",
                    accessor: "supplyPrice",
                    sortable: true,
                    width: 90,
                },
                {
                    id: "surtax",
                    title: "부가세",
                    accessor: "surtax",
                    sortable: true,
                    width: 90,
                },
                {
                    id: "totalPrice",
                    title: "합계금액",
                    accessor: "totalPrice",
                    sortable: true,
                    width: 90,
                },
            ]
            }
            onCellClicked={() => {
            }}/>
    );
}

export default SumListTable