import axios from 'axios';
import {BaseResponse} from "../../BaseResponse";


export async function gasProductRegistration(request: GasProductRegistRequest) {
    const response = await axios.post<BaseResponse<boolean>>("/basic/gas_product", request)
    return response.data
}

export type GasProductRegistRequest = {
    code: string,
    title: string,
    gasProductType: number,
    standard: string,
    weight: number,
    price: number,
    isPriceTaxInclude: boolean,
    pricePerKg: number,
    isPricePerKgTaxInclude: boolean,
    purchasePrice: number
}