import axios from 'axios';
import {BaseResponse} from "../../BaseResponse";


export async function etcProductRegistration(request: EtcProductRegistRequest) {
    const response = await axios.post<BaseResponse<boolean>>("/basic/etc_product", request)
    return response.data
}

export type EtcProductRegistRequest = {
    code: string,
    title: string,
    standard: string,
    unit: string,
    sellPrice: number,
    isSellPriceTaxInclude: boolean,
    purchasePrice: number,
    isPurchasePriceTaxInclude: boolean
}