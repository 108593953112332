import React, {useEffect, useState} from 'react';
import ModalHeader from "../../../../components/modal/Header";
import {Controller, useForm} from "react-hook-form";
import ModifyButton from "../../../../components/button/ModifyButton";
import {VolumeList} from "../../../../api/basic/volume/getVolumeList";
import styled from "styled-components";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Button from "../../../../components/style/button/Button";
import VolumeModalTable from "./VolumeModalTable";
import BasicCheckBox from "../../../../components/style/button/BasicCheckBox";
import {ItemBoxDiv} from "../../../tank/component/ItemBox";
import Input from "../../../../components/style/input/Input";

interface VolumeModalScreenComponentProps {
    update: () => void
    closeModal: () => void
    data?: VolumeList
}

function VolumeModalScreen(props: VolumeModalScreenComponentProps) {

    const [priceVAT, setPriceVAT] = useState(false)
    const [vaporizationRate, setVaporizationRate] = useState<number|undefined>()

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPriceVAT(event.target.checked)
    }

    function handleInputText(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        console.log(event.target.value)
        setVaporizationRate(Number(event.target.value))
    }

    function closeModal() {
        props.closeModal()
    }

    function onConfirm() {
        if (props.data) {
            props.data.isPriceVatInclude = priceVAT
            props.data.defaultVaporizationRate = vaporizationRate
        }
        props.update()

        closeModal()
        window.location.reload()
    }

    useEffect(() => {
        if (props.data) {
            setPriceVAT(props.data.isPriceVatInclude)
            setVaporizationRate(props.data.defaultVaporizationRate)
        }
    },[props.data])

    return (
        <form>
            <ModalDiv>
                <ModalHeader title={"체적 단가 수정"} onCancel={closeModal}/>
                <ContentDiv>
                    <HeaderDiv>
                        <HeaderText>
                            체적 단가
                        </HeaderText>
                        <CheckBoxDiv>
                            <BasicCheckBox
                                handleChange={handleChange}
                                checked={priceVAT}
                            />
                            <CheckBoxText
                                color={priceVAT ? "var(--black)" : "var(--gray-4)"}
                            >
                                VAT 포함
                            </CheckBoxText>
                        </CheckBoxDiv>


                    </HeaderDiv>
                    <VolumeModalTable data={props.data?.priceList}/>
                    <ItemDiv>
                        <ItemBoxDiv>
                            표준기화율
                        </ItemBoxDiv>
                        <ItemInput type={"number"} value={vaporizationRate} onChange={handleInputText}/>
                    </ItemDiv>

                    <FooterDiv>
                        <OtherButton onClick={closeModal}>
                            <OtherText fontWeight={500}>취소</OtherText>
                        </OtherButton>
                        <ModifyButton
                            height={"48px"}
                            width={"120px"}
                            onConfirm={onConfirm}
                            description={"체적 수정"}
                        />
                    </FooterDiv>
                </ContentDiv>
            </ModalDiv>
        </form>
    );
}

const ModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 720px;
    height: 700px;
    border-radius: 4px;
    background-color: var(--white);
`

const ContentDiv = styled.div`
  box-sizing: border-box;
  width: 720px;
  height: 724px;
  padding : 22px 32px 32px 32px;
`

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
`
const HeaderText = styled(FixedWeightText)`
  font-size : 24px;
  font-weight: 500;
`

const GasConfirmButton = styled(Button)`
  width: 120px;
  height: 48px;
  margin-left: 8px;
  background-color: var(--blue);
`

const OtherButton = styled(GasConfirmButton)`
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`

const ConfirmText = styled(FixedWeightText)`
  width: 46px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  color: var(--white);
`

const OtherText = styled(ConfirmText)`
  color : var(--red);
`

const CheckBoxText = styled(FixedWeightText)`
  width: 54px;
  height: 20px;
  font-size : 14px;
  font-weight : 400;
`

const CheckBoxDiv = styled.div`

`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center;
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

export default VolumeModalScreen