import React from 'react';
import ErpTableComponent from "../../../../components/table/ErpTable";
import {useAsync} from "../../../../hooks/useAsync";
import {getEtcProductList} from "../../../../api/basic/etcproduct/getEtcProductList";

interface TableExampleGridComponentProps {
    onCellClicked: (uid: string) => void
}

function EtcProductTable(props: TableExampleGridComponentProps) {
    const {execute, data} = useAsync(getEtcProductList, true)

    function onCellClicked(uid: string) {
        props.onCellClicked(uid)
    }

    function onSortChanged(sortColName?: string, sort?: string) {
        execute(0, 10, sortColName, sort)
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                rowData={data?.itemList}
                onCellClicked={onCellClicked}
                onSortChanged={onSortChanged}
                columns={[
                    {
                        id: "index",
                        title: "순번",
                        accessor: "",
                        sortable: true,
                        width: 110,
                        autoIncrement: true
                    }, {
                        id: "code",
                        title: "코드",
                        accessor: "code",
                        sortable: true,
                        clickable: true,
                        width: 110,
                    }, {
                        id: "title",
                        title: "품목명",
                        accessor: "title",
                        sortable: true,
                        width: 220,
                    }, {
                        id: "standard",
                        title: "규격",
                        accessor: "standard",
                        sortable: true,
                        width: 160,
                    }, {
                        id: "unit",
                        title: "단위",
                        accessor: "unit",
                        sortable: true,
                        width: 160,
                    }, {
                        id: "sellPrice",
                        title: "판매단가",
                        accessor: "sellPrice",
                        sortable: true,
                        width: 160,
                    }, {
                        id: "purchasePrice",
                        title: "구매단가",
                        accessor: "purchasePrice",
                        sortable: true,
                        width: 160,
                    }
                ]}/>
        </>

    )
}

export default EtcProductTable