import * as React from 'react';
import styled from 'styled-components';
import Compass from "../../components/style/div/Compass";
import Divider from "../../components/style/div/Divider";
import PurchaseAccountTable from "./regist/component/PurchaseAccountTable";
import {CommonButton} from "../tank/component/CommonButton";
import {Link} from "react-router-dom";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import {LINK_PURCHASE_ACCOUNT_REGISTRATION} from "../../resources/LinkConstants";

interface PurchaseProps {
}

function Purchase(props: PurchaseProps) {
    return (
        <>
            <Compass first_depth={"매입거래처 관리 홈"} first_depth_width={198}/>

            <Divider/>

            <HeaderDiv>
                <FixedWeightText fontSize={"24px"} fontWeight={500}>
                    매입처 관리
                </FixedWeightText>
                <Link to={LINK_PURCHASE_ACCOUNT_REGISTRATION} style={{textDecoration: 'none'}}>
                    <CommonButton>
                        매입거래처 등록
                    </CommonButton>
                </Link>
            </HeaderDiv>
            <ResultText>
                <FixedWeightText fontSize={"14px"} fontWeight={500}>
                    검색결과 999건
                </FixedWeightText>
            </ResultText>

            <PurchaseAccountTable/>

        </>
    );
}

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom : 22px;
  align-items: center;
`

const ResultText = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom : 8px;
`

export default Purchase;