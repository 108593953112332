import React, {useState} from 'react';
import Compass from "../../components/style/div/Compass";
import Divider from "../../components/style/div/Divider";
import {LINK_SALES_ACCOUNT_REGISTRATION} from "../../resources/LinkConstants";
import {CommonButton} from "../tank/component/CommonButton";
import {Link} from "react-router-dom";
import styled from "styled-components";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import {ItemBoxDiv} from "../tank/component/ItemBox";
import Button from "../../components/style/button/Button";
import Select from "../../components/style/select/Select";
import {FormProvider, useForm} from "react-hook-form";
import {selectItem} from "../../components/form/YesOrNoSelectItem";

interface SalesScreenComponentProps {

}

function SalesAccountScreen(props: SalesScreenComponentProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    const [type1, setType1] = useState(1)
    const [type2, setType2] = useState(1)

    return (
        <FormProvider {...methods}>
            <form>
                <Compass first_depth={"판매거래처 관리 홈"} first_depth_width={198}/>

                <Divider/>

                <HeaderDiv>
                    <FixedWeightText fontSize={"24px"} fontWeight={500}>
                        판매거래처 관리
                    </FixedWeightText>

                    <ButtonDiv>
                        <CommonButton>
                            판매처 복수등록
                        </CommonButton>

                        <Link to={LINK_SALES_ACCOUNT_REGISTRATION} style={{textDecoration: 'none'}}>
                            <CommonButton>
                                판매처 등록
                            </CommonButton>
                        </Link>

                        <CommonButton>
                            건물(다세대) 등록
                        </CommonButton>
                    </ButtonDiv>

                </HeaderDiv>
                <SearchDiv>
                    <FilterDiv>
                        <ItemBoxDiv>필터</ItemBoxDiv>
                        <SelectGroupDiv>
                            <FilterSelect menuItems={selectItem} name={"select1"}/>
                            <FilterSelect menuItems={selectItem} name={"select2"}/>
                            <FilterSelect menuItems={selectItem} name={"select3"}/>
                        </SelectGroupDiv>

                    </FilterDiv>
                    <FilterDivLeftMargin14>
                        <ItemBoxDiv>판매거래처</ItemBoxDiv>
                        <Select 
                            width={"404px"} 
                            height={"48px"} 
                            marginLeft={"8px"} 
                            menuItems={selectItem}
                        />
                    </FilterDivLeftMargin14>
                </SearchDiv>

                <SearchDiv>
                    <FilterDiv>
                        <ItemBoxDiv>구분1</ItemBoxDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"전체"} checked={type1 == 1} onClick={() => setType1(1)}/>
                            <InputText>전체</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"벌크"} checked={type1 == 2} onClick={() => setType1(2)}/>
                            <InputText>벌크</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"용기"} checked={type1 == 3} onClick={() => setType1(3)}/>
                            <InputText>용기</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"도매"} checked={type1 == 4} onClick={() => setType1(4)}/>
                            <InputText>도매</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"소매"} checked={type1 == 5} onClick={() => setType1(5)}/>
                            <InputText>소매</InputText>
                        </InputDiv>
                    </FilterDiv>
                    <FilterDivLeftMargin16>
                        <ItemBoxDiv>구분2</ItemBoxDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"전체"} checked={type2 == 1} onClick={() => setType2(1)}/>
                            <InputText>전체</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"벌크"} checked={type2 == 2} onClick={() => setType2(2)}/>
                            <InputText>중량</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"용기"} checked={type2 == 3} onClick={() => setType2(3)}/>
                            <InputText>체적</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton type={"radio"} value={"도매"} checked={type2 == 4} onClick={() => setType2(4)}/>
                            <InputText>기타</InputText>
                        </InputDiv>
                    </FilterDivLeftMargin16>
                </SearchDiv>
                <SearchDiv>
                    <ItemBoxDiv>검색</ItemBoxDiv>
                    <Select width={"230px"} height={"48px"} menuItems={selectItem} reference={methods.register}/>
                    <Select width={"610px"} height={"48px"} menuItems={selectItem}/>
                    <SearchButton>
                        <FixedWeightText color={"var(--white)"} fontWeight={500}>
                            검색
                        </FixedWeightText>
                    </SearchButton>
                </SearchDiv>

                <TableTitleDiv>
                    <FixedWeightText fontSize={"18px"} fontWeight={500}>
                        판매거래처 리스트
                    </FixedWeightText>
                </TableTitleDiv>

                <ResultText>
                    <FixedWeightText fontSize={"14px"} fontWeight={500}>
                        검색결과 999건
                    </FixedWeightText>
                </ResultText>
                {/*<PurchaseAccountTable/>*/}
            </form>
        </FormProvider>
    );
}

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom : 22px;
  align-items: center;
`

const RadioButton = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 8px;
  border: solid 1px #bec0c2;
  background-color: white;
  align-self: center;
  justify-self: center;
`

const InputDiv = styled.div`
 display : flex;
 align-items : center;
`

const InputText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 500;
  width: 26px;
  margin-left: 8px;
`

const ButtonDiv = styled.div`
  display: flex;
`

const SearchDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const FilterDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const FilterDivLeftMargin14 = styled(FilterDiv)`
  margin-left: 14px;
`

const FilterDivLeftMargin16 = styled(FilterDiv)`
  margin-left: 16px;
`

const SearchButton = styled(Button)`
  width: 80px;
  height: 48px;
  margin-left: 8px;
  background-color: var(--blue);
  align-items: center;
`

const TableTitleDiv = styled.div`
  display: flex;
  margin-top: 60px;
`

const ResultText = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom : 8px;
`

const SelectGroupDiv = styled.div`
  display: flex;
  justify-content: center;
`

const FilterSelect = styled(Select).attrs({
    width : "130px",
    height : "48px",
    marginLeft : "8px"
})``

export default SalesAccountScreen