import React from 'react';
import ErpTableComponent from "../../../../components/table/ErpTable";
import {LINK_PURCHASE_ACCOUNT_REGISTRATION} from "../../../../resources/LinkConstants";
import {useHistory} from "react-router-dom";
import {getPurchaseAccountList} from "../../../../api/purchase/account/getPurchaseAccountList";
import {useAsync} from "../../../../hooks/useAsync";

interface TableExampleGridComponentProps {

}

function PurchaseAccountTable(props: TableExampleGridComponentProps) {
    const history = useHistory();
    const {data: purchaseAccountResponse} = useAsync(getPurchaseAccountList, true)

    function onCellClicked(uid: String) {
        history.replace(LINK_PURCHASE_ACCOUNT_REGISTRATION + `/${uid}`)
        console.log(uid)
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                onCellClicked={onCellClicked}
                rowData={purchaseAccountResponse}
                columns={[
                    {
                        id: "index",
                        title: "순번",
                        accessor: "",
                        sortable: true,
                        width: 70,
                        autoIncrement: true
                    }, {
                        id: "code",
                        title: "일자",
                        accessor: "code",
                        sortable: true,
                        width: 120,
                    }, {
                        id: "name",
                        title: "매입 거래처명",
                        accessor: "accountName",
                        sortable: true,
                        width: 180,
                    }, {
                        id: "cost",
                        title: "이월금액",
                        accessor: "name.title",
                        sortable: true,
                        width: 180,
                    }, {
                        id: "code",
                        title: "매입액",
                        accessor: "name.title",
                        sortable: true,
                        width: 180,
                    }, {
                        id: "email",
                        title: "지급액",
                        accessor: "name.title",
                        sortable: true,
                        width: 180,
                    }, {
                        id: "email",
                        title: "미지급액",
                        accessor: "name.title",
                        sortable: true,
                        width: 180,
                    }]}/>
        </>

    )
}

export default PurchaseAccountTable
