import {createGlobalStyle} from "styled-components";
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
   ${reset};
   * > body,
   * > body * {
      font-family: 'Noto Sans KR';
   }
   input[type="number"]::-webkit-outer-spin-button,
   input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const OCR_URL =
    "/custom/v1/4728/e49bb7ed747070271c59c6969e38d4875b56be727032e049281bfd8e1cd738f2/infer"

