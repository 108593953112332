import React, {ReactNode, useState} from 'react';
import {ColumnApi, GridApi, GridReadyEvent, ICellRendererParams, ValueGetterParams} from "ag-grid-community";

import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {CellEditingStoppedEvent, RowSelectedEvent, SortChangedEvent} from "ag-grid-community/dist/lib/events";
import {ValueSetterParams} from "ag-grid-community/dist/lib/entities/colDef";

interface ErpTableComponentProps {
    tableWidth: number
    tableHeight: number
    rowHeight?: number
    rowData: any[] | undefined
    columns: ColumnType[]
    onGridReady?: (api: GridApi) => void
    onCellClicked: (uid: string) => void
    onCellDelete?: (uid: string) => void
    onRowClicked?: (data: any) => void
    onRowSelected?: (event : RowSelectedEvent) => void
    fullRowEditColId?: string,
    onSortChanged?: (sortColName?: string, sort?: string) => void
    onEditFinish?: () => void
    stayScrollEnd?: boolean
    multipleSelect? : string
}

type ColumnType = {
    id: string
    title?: string
    accessor: string
    valueGetter?: (params: ValueGetterParams) => any
    editable?: boolean
    sortable?: boolean
    clickable?: boolean
    checked? : boolean
    width?: number
    height?: number
    autoIncrement?: boolean
    hide?: boolean
    cellRendererFramework?: (params: ICellRendererParams) => ReactNode
    columnValidator?: ColumnValidatorType
    onCellValueChanged? : (value : any)=> any
    headerCheckboxSelection? : boolean
    checkboxSelection? : boolean
}

export enum ColumnValidatorType {
    INT,
    FLOAT
}

function ErpTableComponent(props: ErpTableComponentProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    const gridOptions = {
        suppressNoRowsOverlay: true
    }

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)

        event.api.showNoRowsOverlay()
        event.api.setSortModel(props.columns.map(value => {
            return {colId: value.id, sort: undefined}
        }))

        event.api.sizeColumnsToFit()

        props.onGridReady?.(event.api)
    }

    function onRowClicked(event: RowSelectedEvent) {
        if (props.fullRowEditColId) {
            gridApi?.stopEditing(true)
            gridApi?.setFocusedCell(event.rowIndex, props.fullRowEditColId)

            gridApi?.startEditingCell({
                rowIndex: event.rowIndex,
                colKey: props.fullRowEditColId
            })
        }

        props.onRowClicked?.(event.data)
    }

    function onSortChanged(event: SortChangedEvent) {
        if (typeof event.api.getSortModel()[0] == "object") {
            props.onSortChanged?.(event.api.getSortModel()[0].colId, event.api.getSortModel()[0].sort)
        } else {
            props.onSortChanged?.(undefined, undefined)
        }
    }

    function onCellEditFinished(event: CellEditingStoppedEvent) {
        props.onEditFinish?.()
    }

    function autoIncrement(params: ValueGetterParams) {
        return params.data && params.node.rowIndex + 1;
    }

    function cell(node: ICellRendererParams, clickable?: boolean) {
        if (typeof node.data == "object") {
            if (clickable) {
                return <text style={{color: "var(--blue)", textDecoration: "underline"}}
                             onClick={() => props.onCellClicked(node.data.uid)}>
                    {node.value}
                </text>
            }
        }

        return <text>{node.value}</text>
    }

    function valueSetter(params: ValueSetterParams, validator: ColumnValidatorType | undefined) {
        if (validator == undefined) {
            if (params.colDef.colId) {
                params.data[params.colDef.colId] = params.newValue;
            }
            return true
        }

        switch (validator) {
            case ColumnValidatorType.INT:
                let newValInt = parseInt(params.newValue);
                if (params.colDef.colId) {
                    params.data[params.colDef.colId] = newValInt;
                }
                break;
            case ColumnValidatorType.FLOAT:
                let newValFloat = parseFloat(params.newValue);
                if (params.colDef.colId) {
                    params.data[params.colDef.colId] = newValFloat;
                }
                break;
        }

        return true;
    }

    return (
        <div className="ag-theme-alpine" style={{height: props.tableHeight, width: props.tableWidth}}>
            <AgGridReact
                rowHeight={props.rowHeight}
                headerHeight={props.rowHeight}
                gridOptions={gridOptions}
                rowSelection={props.multipleSelect}
                maxConcurrentDatasourceRequests={1}
                cacheBlockSize={10}
                editType={props.fullRowEditColId ? "fullRow" : undefined}
                onGridReady={onGridReady}
                onSortChanged={onSortChanged}
                onRowClicked={onRowClicked}
                animateRows={true}
                onCellEditingStopped={onCellEditFinished}
                onRowSelected={(event : RowSelectedEvent) => {
                    props.onRowSelected?.(event)
                }}
                onRowDataUpdated={(api) => {
                    if (props.stayScrollEnd) {
                        gridApi?.ensureIndexVisible(Number(api.api.getLastDisplayedRow()), 'bottom')
                    }
                }}
                rowData={props.rowData}
            >

                {props.columns.map((value, index) =>
                    <AgGridColumn
                        key={value.id}
                        colId={value.id}
                        headerName={value.title}
                        valueGetter={value.autoIncrement ? autoIncrement : value.valueGetter}
                        field={value.accessor}
                        editable={value.editable}
                        sortable={value.sortable}
                        headerCheckboxSelection={value.checked}
                        checkboxSelection={value.checked}
                        width={value.width}
                        onCellValueChanged={value.onCellValueChanged}
                        valueSetter={(params: ValueSetterParams) => valueSetter(params, value.columnValidator)}
                        hide={value.hide || false}
                        cellRendererFramework={(params: ICellRendererParams) => value.cellRendererFramework?.(params) || cell(params, value.clickable)}
                    />
                )}
            </AgGridReact>
        </div>
    );
}

export default ErpTableComponent