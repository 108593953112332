import React, {ReactNode} from 'react';
import styled from 'styled-components';
import FixedWeightText from "../text/FixedWeightText";

interface SectionTitleComponentProps {
    children: ReactNode
}

function SectionTitle(props: SectionTitleComponentProps) {
    return (
        <WrapDiv>
            <ContentsTitle>
                {props.children}
            </ContentsTitle>
        </WrapDiv>
    );
}

const WrapDiv = styled.div`
  margin-top : 46px;
  margin-bottom: 12px;
`

const ContentsTitle = styled(FixedWeightText)` 
  font-size : 18px;
  font-weight: 500;
`

export default SectionTitle