import React, {useEffect} from 'react';
import styled from 'styled-components';
import Select from "../../../../../components/style/select/Select";
import FixedWeightText from "../../../../../components/style/text/FixedWeightText";
import Input from "../../../../../components/style/input/Input";
import BasicCheckBox from "../../../../../components/style/button/BasicCheckBox";
import {useFormContext} from "react-hook-form";
import {CertificateDetailForm} from "../../../../../api/tank/certification/getCertificateDetailForm";
import {StyeldButton} from "../TankCertificateRegistrationScreen";
import {TankCertificate} from "../../../../../api/tank/certification/postCertificate";
import Moment from 'moment'
import Datepicker from "../../../../../components/style/datepicker/Datepicker";
import RedDot from "./RedDot";

interface CertificateContentScreenComponentProps {
    form?: CertificateDetailForm
    index: number
    myIndex: number
    data?: TankCertificate
}

function TankCertificateContentScreen(props: CertificateContentScreenComponentProps) {
    const {setValue, register} = useFormContext()


    useEffect(() => {
        setValue("items[" + props.myIndex + "].uid", props.data?.uid)
        setValue("items[" + props.myIndex + "].certificateNumber", props.data?.certificateNumber)
        setValue("items[" + props.myIndex + "].type", 1)
        setValue("items[" + props.myIndex + "].typeValue", props.data?.typeValue)
        setValue("items[" + props.myIndex + "].volume", props.data?.volume)
        setValue("items[" + props.myIndex + "].manufacturerNumber", props.data?.manufacturerNumber)
        setValue("items[" + props.myIndex + "].manufacturer", props.data?.manufacturer)
        setValue("items[" + props.myIndex + "].passDate", props.data?.passDate)
        // setValue("items[" + props.myIndex + "].gasName", props.data?.gasName)
        setValue("items[" + props.myIndex + "].gasName", "LPG")
        setValue("items[" + props.myIndex + "].ownerType", props.data?.ownerType?  props.data?.ownerType : 1)
        setValue("items[" + props.myIndex + "].nextExaminationDate", props.data?.nextExaminationDate ? props.data.nextExaminationDate : Moment().format('YYYY-MM-DD'))
        setValue("items[" + props.myIndex + "].fileUri", props.data?.fileUri)
        setValue("items[" + props.myIndex + "].finalTestDate", props.data?.finalTestDate ? props.data.finalTestDate : props.data?.passDate)
        setValue("items[" + props.myIndex + "].memo", props.data?.memo)
        setValue("items[" + props.myIndex + "].salesAccountName", props.data?.salesAccountName)
        setValue("items[" + props.myIndex + "].address", props.data?.address)
        setValue("items[" + props.myIndex + "].userName", props.data?.userName)
    }, [props.data])

    return (
        <CategoryDiv hidden={(props.myIndex !== props.index) && props.myIndex != -1}>
            <input name={`items[${props.myIndex}].type`} ref={register} hidden={true}/>
            <input name={`items[${props.myIndex}].fileUri`} ref={register} hidden={true}/>
            <input name={`items[${props.myIndex}].nextExaminationDate`} ref={register} hidden={true}/>
            <input name={`items[${props.myIndex}].uid`} ref={register} hidden={true}/>

            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>증명서번호
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].certificateNumber"}
                    ref={register}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>분류
                    </ItemBoxText>
                </ItemBoxDiv>

                <CheckBox checked={true}/>
                <TypeText>{props.form?.certType[0].title}</TypeText>
                <ListInput
                    name={"items[" + props.myIndex + "].typeValue"}
                    ref={register}
                    readOnly={true}
                />
            </ListDiv>

            <ListDiv>
                <ItemBoxDiv/>
                <CheckBox checked={false}/>
                <TypeText>{props.form?.certType[1].title}</TypeText>
                <ListInput/>
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>제조사
                    </ItemBoxText>
                </ItemBoxDiv>
                <ContentSelect
                    name={"items[" + props.myIndex + "].manufacturer"}
                    reference={register}
                    menuItems={props.form?.manufactureType}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>제조번호
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].manufacturerNumber"}
                    ref={register}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>용량
                    </ItemBoxText>
                </ItemBoxDiv>
                <ContentSelect
                    name={"items[" + props.myIndex + "].volume"}
                    reference={register}
                    menuItems={props.form?.volumeType}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        충전가스명
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].gasName"}
                    ref={register}
                    value={"LPG"}
                    backgroundColor={"#eeeeee"}
                    color={"var(--gray-3)"}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>검사합격일
                    </ItemBoxText>
                </ItemBoxDiv>
                <PassDatePicker
                    defaultValue={Moment().format('YYYY-MM-DD')}
                    reference={register}
                    name={"items[" + props.myIndex + "].passDate"}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        자가여부
                    </ItemBoxText>
                </ItemBoxDiv>
                <ContentSelect
                    name={"items[" + props.myIndex + "].ownerType"}
                    reference={register}
                    menuItems={props.form?.ownerType}
                    defaultValue={1}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        비고
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].memo"}
                    ref={register}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        최종검사일
                    </ItemBoxText>
                </ItemBoxDiv>
                <FinalTestDatePicker
                    name={"items[" + props.myIndex + "].finalTestDate"}
                    reference={register}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        판매처명(코드)
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].salesAccountName"}
                    ref={register}
                />
                <StyeldButton>
                    <FixedWeightText color={"var(--white)"} fontSize={"12px"}
                                     fontWeight={500}>검색</FixedWeightText>
                </StyeldButton>
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        사용자명
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].userName"}
                    ref={register}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        주소
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.myIndex + "].address"}
                    ref={register}
                />
            </ListDiv>
        </CategoryDiv>
    )
}

const ItemBoxDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 80px;
  height: 40px;
  padding-left: 12px;
  align-items: center;
  font-size: 10px;
  background-color: var(--gray-6);
`

const CategoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  border: none;
  align-items: flex-start;
`

const ListDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 395px;
`

const ListInput = styled(Input)`
  display: flex;
  flex: 1;
  height: 32px;
  font-size: 10px;
  margin-left: 8px;
`

const ItemBoxText = styled(FixedWeightText)`
  width: 65px;
  height: 20px;
  font-size: 10px;
  font-weight: 500;
  color: var(--black);
`

const CheckBox = styled(BasicCheckBox)`
  width: 20px;
  height: 20px;
  padding-left: 8px;
`

const TypeText = styled(FixedWeightText)`
  font-size: 10px;
  font-weight: 400;
  margin-left: 8px;
  margin-right: 18px;
`

const ContentSelect = styled(Select).attrs({
    width: "307px",
    height: "32px",
})``

const PassDatePicker = styled(Datepicker)`
  width: 307px;
  height: 32px;
`

const FinalTestDatePicker = styled(Datepicker)`
  width: 307px;
  height: 32px;
`

export default TankCertificateContentScreen