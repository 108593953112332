import React, {useEffect} from 'react';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch, {SwitchClassKey, SwitchProps} from '@material-ui/core/Switch';
import styled from "styled-components";
import FixedWeightText from "../style/text/FixedWeightText";
import {FormControl} from "@material-ui/core";
import {Controller, useFormContext} from "react-hook-form";
import MaterialSelect from "@material-ui/core/Select";
import {MenuItem} from "../style/select/Select";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

export const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 64,
            height: 32,
            padding: 1,
            margin: theme.spacing(2),
        },
        switchBase: {
            padding: 4,
            marginLeft: 1,
            '& + $track': {
                backgroundColor: 'var(--red)',
                opacity: 1,
                border: 'none',
            },
            '&$checked': {
                transform: 'translateX(30px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: 'var(--blue)',
                    opacity: 1,
                    border: 'none',
                },
                backButtonTitle: 'No'
            },
            '&$focusVisible $thumb': {
                color: theme.palette.common.white,
                backgroundColor: 'var(--blue)',
                border: 'none',
            },
        },
        thumb: {
            alignItems: "center",
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 64 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({classes, ...props}: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

interface MaterialSwitchProps {
    name: string
    handleSwitch: () => void
}
//
// export default function MaterialSwitch(props: MaterialSwitchProps) {
//     const {control} = useFormContext()
//     const [state, setState] = React.useState(false);
//     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         setState(event.target.checked)
//         props.handleSwitch()
//     }
//
//     return (
//         <FormControl>
//             <Controller
//                 as={<IOSSwitch checked={state} onChange={handleChange}/>}
//                 name={props.name}
//                 control={control}
//             />
//             {/*{!state &&*/}
//             {/*<TrueDiv onClick={() => setState(false)}>*/}
//             {/*    <TrueText>ON</TrueText>*/}
            {/*</TrueDiv>*/}
            {/*}*/}
            {/*{state &&*/}
            {/*<FalseDiv onClick={() => setState(true)}>*/}
            {/*    <FalseText>OFF</FalseText>*/}
            {/*</FalseDiv>*/}
            {/*}*/}
//         </FormControl>
//     );
// }

const Row = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`

const TrueDiv = styled.div`
margin-left: -65px;
z-index: 1;
pointer-events:none
`

const FalseDiv = styled.div`
margin-left : -90px;
z-index : 1;
pointer-events:none
`

const TrueText = styled(FixedWeightText)`
width: fit-content;
height: fit-content;
font-size: 14px;
font-weight: 500;
color: var(--white);
`

const FalseText = styled(TrueText)`
`