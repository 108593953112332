import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getGasProductList() {
    const response = await axios.get<BaseResponse<Array<GasProductList>>>(`/basic/gas_product`)
    return response.data
}

export type GasProductList = {
    uid: string,
    code: string,
    title: string,
    isActive: boolean,
    gasProductType: string,
    standard: string,
    weight: number,
    price: number,
    pricePerKg: number,
    purchasePrice: number,
}
