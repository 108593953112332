export const ServicePolicy =
    `
서비스 이용약관

제 1 조 (목적)

본 약관은 디앨 주식회사 (이하 "회사")가 운영하는 "서비스"를 이용함에 있어 "회사"와 회원간의 이용 조건 및 제반 절차, 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 한다.

제 2 조 (용어의 정의)

이 약관에서 사용하는 용어의 정의는 아래와 같다.
① "사이트"라 함은 회사가 서비스를 "회원"에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트, 모바일웹, 앱 등의 서비스를 통칭하며, 회원 계정(아이디 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는 아래의 사이트를 말한다.

www.tongsori-erp.kr  

② "서비스"라 함은 탱크합격증 관리, 입출고 내역 등을 포함한 사이트에서 제공하는 모든 부대 서비스를 말한다.
③ "회원"이라 함은 기업이 본 서비스를 이용하기 위하여 동 약관에 동의하고 "회사"가 승인한 아이디를 부여받은 "기업회원"을 말한다.
④ "아이디"라 함은 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 "회사"가 승인하는 문자와 숫자의 조합을 말한다.
⑤ "비밀번호"라 함은 "회사"의 서비스를 이용하려는 사람이 아이디를 부여받은 자와 동일인임을 확인하고 "회원"의 권익을 보호하기 위하여 "회원"이 선정한 문자와 숫자의 조합을 말한다.


제 3 조 (약관의 명시와 개정)
① "회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 이용자에게 공지해야 한다.
② "회사"는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.
③ "회사"가 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지한다. 단 "회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 한다.
④ "회원"은 변경된 약관에 대해 거부할 권리가 있다. "회원"은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있다. "회원"이 거부하는 경우 본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게 사전 통지 후 당해 "회원"과의 계약을 해지할 수 있다. 만약, "회원"이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 "서비스"를 이용하는 경우에는 동의한 것으로 간주한다.


제 4 조 (약관의 해석)
① 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등의 관계법령에 따른다.
② "회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용할 경우에는 개별 계약이 우선한다.


제 5 조 (이용계약의 성립)
① "회사"의 서비스 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는 자가 본 약관과 개인정보취급방침을 읽고 "동의" 또는 "확인" 버튼을 누른 경우 본 약관에 동의한 것으로 간주한다.
② 제1항 신청에 있어 "회원"은 기업인증을 위해 사업자정보를 확인할 수 있는 증명서(사업자등록증), 연락처 등를 제공하여야한다. 회사명과 사업자등록번호를 허위로 등록한 경우, 휴·폐업 사업자등록번호로 등록한 경우에 "회원"은 일체의 권리를 주장할 수 없다.
③ 이용신청자가 이용신청(회원가입 신청) 작성 후 관리자페이지에서 “회사”가 해당 회원가입 신청을 승인 한 후 이용계약이 성립된다.
④ 사업자등록번호가 없는 기관이나 단체는 "회사"가 정한 별도의 절차에 따라 이용계약을 할 수 있다.


제 6 조 (이용신청의 승낙과 제한)
① "회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행상 또는 기술상 지장이 없는 경우에는 원칙적으로 접수순서에 따라 서비스 이용을 승낙한다.
② "회사"는 아래 사항에 해당하는 경우에 대해서는 이용신청을 승낙하지 아니한다.
1. 실제 회사명과 사업자등록번호가 아니거나 타사의 정보를 이용하여 신청한 경우
2. 이용계약 신청서의 내용을 허위로 기재하거나 "회사"가 제시하는 내용을 기재하지 않은 경우
3. 이용자의 귀책사유로 인하여 승인이 불가능하거나 “회사”가 기타 규정한 제반 사항을 위반하며 신청하는 경우


③ "회사"는 아래 사항에 해당하는 경우에는 그 신청에 대하여 승낙제한 사유가 해소될 때까지 승낙을 유보할 수 있다.
1. "회사"가 설비의 여유가 없는 경우
2. "회사"의 기술상 지장이 있는 경우
3. 기타 "회사"의 귀책사유로 이용승낙이 곤란한 경우


제 7 조 (서비스의 내용)
① "회사"는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같다.
1. 입출고 내역관리, 세금계산서발행 모바일ERP 등 핵심기능 
2. 탱크합격증 등 포함한 각종 관리에 필요한 서류 보관 및 다운로드 기능 
3. 기타 "회사"가 추가 개발하거나 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스
② "회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 단, 이 경우 "회사"는 추가 또는 변경내용을 "회원"에게 공지해야 한다.


제 8 조 (서비스의 요금)
① "회원" 가입비용은 무료이다. 다만 “회원”이 제 7 조에서 지정한 서비스를 제공 받기위해선 별도의 계약을 채결 해야 한다.
② "회사"는 추후에 유료서비스를 제공할 경우 “회원”에게 공지 및 별도의 계약을 채결 해야 한다.
③ 유료 서비스 신청 후 "회원" 사정에 의해 서비스가 취소될 경우, 별도의 계약서에서 정한 정책에 의해 환불 수수료를 부과할 수 있다.


제 9 조 (서비스 이용시간)
① "회사"는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다. 다만, "회사"는 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "회사"는 그 이용시간을 사전에 "회원"에게 공지 또는 통지하여야 한다.
② "회사"는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애해결을 위한 보수작업 시간, 정기 PM작업, 시스템 교체작업, 회선 장애 등이 발생한 경우 일시적으로 서비스를 중단할 수 있으며 계획된 작업의 경우 공지 란에 서비스 중단 시간과 작업 내용을 알려야 한다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있다.


제 10 조 (서비스 제공의 중지)
① "회사"는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있다.
1. 설비의 보수 등 "회사"의 필요에 의해 사전에 "회원"들에게 통지한 경우
2. 기간통신사업자가 전기통신서비스 제공을 중지하는 경우
3. 기타 불가항력적인 사유에 의해 서비스 제공이 객관적으로 불가능한 경우


제 11 조 (정보의 제공 및 광고의 게재)
① "회사"는 "회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 서신우편 및 문자메시지서비스을 이용한 방법으로 제공할 수 있다.
② "회사"는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스화면, 홈페이지, 전자우편, 문자메시지서비스 등에 게재할 수 있으며, 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있다.
③ "회사"는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않는다.
④ 본 서비스의 "회원"은 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주한다.


제 12 조 (자료내용의 책임과 "회사"의 정보 수정 권한)
① 자료내용은 "회원"이 “사이트”에 등록한 정보를 말한다.
② "회원"은 자료 내용 및 게시물을 “사용자 가이드”에 근거하여 정확하게 작성해야 하며, 만일 자료의 내용이 부정확하게 작성되어 발생하는 모든 책임은 "회원"에게 있다.
③ 모든 자료내용의 관리와 작성은 "회원" 본인이 하는 것이 원칙이나 사정상 위탁 또는 대행관리를 하더라도 자료내용의 책임은 "회원"에게 있으며 "회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록 노력해야 한다.
④ "회사"는 “회원”이 “사이트”에 등록한 자료 내용의 수정 요청 시 적절히 대응해 주어야한다.


제 13 조 ("회사"의 의무)
① "회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 한다.
② "회사"는 서비스와 관련한 "회원"의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 "회원"에게 통지하여야 한다.
③ "회사"는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존한다. 다만 회원 자격이 없는 회원은 예외로 한다.
④ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 진다.
⑤ "회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나 열람시킬 경우 반드시 "회원"의 동의를 얻어야 한다.


제 14 조 ("회원"의 의무)
① "회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 된다.
② "회원"이 신청한 유료서비스는 등록 또는 신청과 동시에 "회사"와 채권, 채무 관계가 발생하며, "회원"은 이에 대한 요금을 지정한 기일 내에 납부하여야 한다.
③ "회원"은 서비스를 이용하여 얻은 정보를 "회사"의 사전동의 없이 타인에게 제공할 수 없다.
④ "회원"은 본 서비스를 이용 중 다음 각 호의행위를 해서는 안 된다.
1. 다른 회원의 아이디를 부정 사용하는 행위
2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
3. 해킹행위 또는 바이러스의 유포 행위
4. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위
5. 그 밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위


제 15 조 ("회원"의 가입해지/서비스중지/자료삭제)
① "회원"은 언제든지 회원탈퇴 고객센터를 통하여 이용계약 해지 신청을 할 수 있다.
② 유료서비스를 위한 별도의 계약을 채결한 경우 별도의 해지 절차를 통해 계약을 해지한다.
③ 다음의 사항에 해당하는 경우 "회사"는 사전 동의없이 가입해지나 서비스 중지, 채용공고 삭제 조치를 취할 수 있다.
1. 회원의 의무를 성실하게 이행하지 않았을 때
2. 규정한 유료서비스 이용 요금을 납부하지 않았을 때
3. 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때
4. 회원이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때
5. 사업자등록증에 나타난 정보와 기업회원 가입시의 정보가 일치하지 않을 때
6. 타 기업의 사업자등록번호나 상호를 도용하여 허위로 기업정보를 등록하거나 휴·폐업 사업자등록번호로 기업정보를 등록했을 때
7. 회사명, 담당자 성명, 사무실 연락처 등 구인업체의 중요 정보를 정확하게 기입하지 않았을 때
8. 본 서비스 명예를 훼손하였을 때


③ "회원"이 유료서비스를 이용하는 중 "회사"의 책임에 의해 정상적인 서비스가 제공되지 않을 경우 "회원"은 본 서비스의 해지를 요청할 수 있다.
④ "회사"는 회원 가입이 해지된 경우 해당 회원 정보를 모두 삭제하여야 하며, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 정한 일정한 기간 동안 회원정보를 보관한다.
⑤ 개인정보보호를 위하여 "회원"이 1년 동안 "사이트"를 이용하지 않은 경우, "아이디"를 "휴면계정"로 분리하여 해당 계정의 이용을 중지할 수 있다. 이 경우 "회사"는 "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을 전자메일, 서면, SMS 중 하나의 방법으로 사전통지하며 "회원"이 직접 본인확인을 거쳐, 다시 "사이트" 이용 의사표시를 한 경우에는 "사이트" 이용이 가능하다.


제 16 조 (손해배상)
① "회사"가 이 약관의 규정에 위반한 행위로 "회원"에게 손해를 입히거나 기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로 인해 "회원"에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야 한다.
② "회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "회원"이 손해를 입었을 경우에 "회사"는 그 손해를 배상하여야 한다.
③ "회원"이 이 약관의 규정에 위반한 행위로 "회사" 및 제3자에게 손해를 입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌 경우에는 "회원"은 그 손해를 배상하여야 한다.


제 17 조 (이용요금 오류의 조정)
"회사"는 이용요금과 관련하여 오류가 있는 경우에 "회원"의 요청, 또는 "회사"의 사전 통지에 의하여 다음에 해당하는 조치를 취한다.
① 과다납입한 요금에 대하여는 그 금액을 반환한다.
② 요금을 반환 받아야 할 "회원"이 요금체납이 있는 경우에는 반환해야 할 요금에서 이를 우선 공제하고 반환한다.
③ "회사"는 과소청구액에 대해서는 익월에 합산 청구한다.


제 18 조 ("회원"의 개인정보보호)
“회사"는 "회원"의 개인정보보호를 위하여 노력해야 한다. "회원"의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따르고, "사이트"에 "개인정보취급방침"을 고지한다.


제 19 조 (신용정보의 제공 활용 동의)
① "회사"가 회원가입과 관련하여 취득한 "회원"의 신용정보를 타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 "회원"의 동의를 얻어야 한다.
② 본 서비스와 관련하여 "회사"가 "회원"으로부터 신용정보의 이용 및 보호에 관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은 "회사"가 신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 "회원"의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주한다.


제 20 조 (분쟁의 해결)
① "회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 한다.
② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지 관할법원으로 한다.


부칙- 이 약관은 2021년 4월 12일부터 시행한다.`

export const PersonalInformationProtection = `
개인정보보호
1. 개인정보의 수집 및 이용목적


 디앨 주식회사(이하 "사이트")은 회원의 효율적인 고객관리에 적합한 서비스를 제공하기 위하여 개인정보를 수집하고 있으며 수집된 정보를 아래와 같이 이용하고 있습니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.
1) 회원관리
회원제 서비스 이용에 따른 본인확인, 본인의 의사확인, 고객문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달


2) 서비스 개발 및 마케팅·광고에의 활용
맞춤 서비스 제공, 서비스 안내 및 이용권유, 서비스 개선 및 신규 서비스 개발



2. 수집하는 개인정보 항목 및 수집방법


가. 수집하는 개인정보의 항목
1) 회원가입 시 수집하는 항목 필수항목: 중복가입확인정보(DI), 아이디, 비밀번호, 회사명, 사업자등록번호, 전화번호, 휴대폰번호, Fax번호, e-메일, 우편번호, 주소


2) 유료 정보 이용 시 수집하는 항목
유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
CMS 이체: 은행명, 계좌번호 등




2. 개인정보의 보유 및 이용기간


"사이트"는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 이용기간이 종료한 경우 개인정보를 지체 없이 파기합니다.`