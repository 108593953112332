import React from 'react';

interface CidScreenComponentProps {

}

function CidScreen(props: CidScreenComponentProps) {
    return (
        <div>

        </div>
    );
}

export default CidScreen