import React, {useEffect, useState} from 'react';
import VolumeModalScreen from "./VolumeModalScreen";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import {VolumeList} from "../../../../api/basic/volume/getVolumeList";
import {useAsync} from "../../../../hooks/useAsync";
import {putVolumeList} from "../../../../api/basic/volume/putVolumeList";

interface BasicModalProps {
    showModal: boolean
    onCloseModal: () => void
    onOpen?: () => void
    data?: VolumeList
}

function VolumeModalContainer(props: BasicModalProps) {

    const {data: updateResponse, error: updateError, isLoading: updateLoading, execute: update} = useAsync(putVolumeList)

    function closeModal() {
        props.onCloseModal()
    }

    return (
        <ConfirmModal showModal={props.showModal} onCancel={closeModal}>
            <VolumeModalScreen
                update={() => update(props.data)}
                closeModal={closeModal}
                data={props.data}/>
        </ConfirmModal>

    );
}


export default VolumeModalContainer