import React from 'react';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components";
import Moment from 'moment';
import {BaseFormType} from "../../form/BaseFormType";

type DatepickerProps = BaseFormType & {
    width?: string
    height?: string
    reference?: any
}

const DatePickers = styled(TextField).attrs((props: DatepickerProps) => ({
    id: "date",
    type: "date",
    // defaultValue: Moment().format('YYYY-MM-DD'),
    InputProps: {disableUnderline: true},
    variant: "outlined",
    name: props.name,
    inputRef: props.reference,
}))<DatepickerProps>`
    box-sizing: border-box;
    width: ${(props: DatepickerProps) => props.width};
    height: ${(props: DatepickerProps) => props.height};
    padding : 0px;
    margin-left : 8px;
    font-size: 10px;
    
   & .MuiOutlinedInput-root{
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      justify-content: center;
      font-size: 10px;
      font-weight: 500;
   }
   
   
   & .MuiOutlinedInput-notchedOutline {
      border: solid 1px var(--gray-4);
   }
   & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: solid 1px var(--blue);
   }
`

export default DatePickers
