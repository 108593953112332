import axios from 'axios';
import {BaseResponse} from "../../BaseResponse";

export async function salesAccountRegistration(request: SalesAccountRegistRequest) {
    const response = await axios.post<BaseResponse<string>>("/sales/account", request)
    return response.data
}

export type SalesAccountRegistRequest = {
    code: string,
    accountName: string,
    ownerName: string,
    tel: string,
    phone: string,
    address: string,
    addressDetail: string,
    longitude: number,
    latitude: number,
    employeeId: string,
    sellTypeId: string,
    salesTypeId: number,
    collectMoneyTypeId: number,
    salesAccountTypeId: number,
    groupId : number,
    volumePriceTypeId: number,
    adjustmentValue: number,
    unitPrice: number,
    gaugeInitDate: string,
    gaugeInitValue: number,
    tankInitDate: string,
    tankInitValue: number,
    tankWeight: number,
    isBillNeeded: boolean,
    tongsoriSyncId: number,
    contractNo: string,
    contractStartDate: string,
    contractEndDate: string,
    contractorName: string,
    contractorSocialNo: string,
    tankOwnerTypeId: number,
    facilityOwnerTypeUid: number,
    regulatorTypeId: number,
    regulatorValue: number,
    vaporizerValue: number,
    meterValue: number,
    fuseCockCount: number,
    valveValue: number,
    valveCount: number,
    supplyPipe1Value: number,
    supplyPipe1Count: number,
    supplyPipe2Value: number,
    supplyPipe2Count: number,
    isFacilityGood: boolean,
    isPipeInstalled: boolean,
    salesAccountElectronicBillUid: number
}