import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getEtcProduct(uid : string) {
    const response = await axios.get<BaseResponse<EtcProductResponse>>(`/basic/etc_product/${uid}`)
    return response.data
}

export type EtcProductResponse = {
    code: string
    title: string,
    standard: string,
    unit: string,
    sellPrice: number,
    isSellPriceTaxInclude: boolean,
    purchasePrice: number,
    isPurchasePriceTaxInclude: boolean,
}
