import axios from 'axios';
import {BaseResponse} from "../BaseResponse";

export async function signUp(request: SignUpRequest) {
    const response = await axios.post<BaseResponse<string>>("/signUp", request)
    return response.data
}

interface MemberPhoneNumber {
    name: string
    carrier: number
    number1: number
    number2: number
}

interface MemberTelNumber {
    name: string
    localNumber : number
    number1: number
    number2: number
}

interface MemberEmail {
    name: string
    email: string
    domain : string
}

export type SignUpRequest = {
    userId: string,
    password: string,
    companyName: string,
    businessNumber: string,
    ownerName: string,
    tel: MemberTelNumber,
    mobile1: MemberPhoneNumber,
    mobile2?: MemberPhoneNumber,
    mobile3?: MemberPhoneNumber,
    fax?: string,
    email?: MemberEmail,
    address: string,
    addressDetail: string,
    deliveryAddress: string,
    deliveryAddressDetail: string,
    serviceAgreement: boolean,
    privacyAgreement: boolean
}