import React, {useEffect} from 'react';
import BasicNavComponent, {BasicType} from "../BasicNavComponent";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../../../redux/CompassModule";
import {MethodState, ProductMethodType} from "../GasProduct/GasProductContainer";
import BasicTopTitle from "../BasicTopTitle";
import EtcProductTable from "./EtcProductTable";

interface EtcProductScreenComponentProps {
    openModal: (type: ProductMethodType) => void
}

function EtcProductScreen (props : EtcProductScreenComponentProps){
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeTitle({
            title : {
                first_depth : "기초데이터 홈",
            }
        }))
    })

    return (
        <div>
            <BasicTopTitle openModal={props.openModal}/>
            <BasicNavComponent type={BasicType.ETC_PRODUCT}/>
            <EtcProductTable onCellClicked={(uid) => props.openModal({
                type: MethodState.UPDATE,
                payload: uid
            })}/>
        </div>
    );
}

export default EtcProductScreen