import React, {useEffect} from 'react';
import SalesAccountRegist from "./SalesAccountRegist";
import {salesAccountRegistration, SalesAccountRegistRequest} from "../../../api/sales/account/salesAccountRegistration";
import {
    salesAccountRegistrationForm,
} from "../../../api/sales/account/salesAccountRegistrationForm";
import {useAsync} from "../../../hooks/useAsync";


interface SalesAccountRegistContainerProps {

}

function SalesAccountRegistContainer(props: SalesAccountRegistContainerProps) {

    const {data: formResponse, error: formError, isLoading: formLoading} = useAsync(salesAccountRegistrationForm,true)
    const {data: saveResponse, error: saveError, isLoading: saveLoading, execute} = useAsync(salesAccountRegistration)

    function onRegist(request: SalesAccountRegistRequest) {
        console.log(request, "fork")
        execute(request)
    }

    return (
        <SalesAccountRegist onRegist={onRegist} loading={formLoading || saveLoading} formResponse={formResponse}/>
    );
}

export default SalesAccountRegistContainer