import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function postUploadTankCertificate(uid: string) {
    const response = await axios.post<BaseResponse<boolean>>(`/tank/certificate/upload/${uid}`)
    return response.data
}

export type UploadTankCertificate = {
    uid: string
    fileUri: string
    certificateNumber : string
    type : number
    typeValue : string
    manufacturer : number
    manufacturerNumber : string
    volume : number
    gasName : string
    // 검사합격일
    passDate : string
    nextExaminationDate : string
    //자가여부
    ownerType : number
    lastTestDate : string
    memo : string
    finalTestDate : string
    salesAccountName : string
    userName : string
    address : string
}
