import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import ModalComponent from "./Modal";

interface ConfirmModalComponentProps {
    showModal: boolean
    onCancel?: () => void
    children: ReactNode
}

function ConfirmModal(props: ConfirmModalComponentProps) {
    const [showModal, setShowModal] = useState(props.showModal)

    function open() {
        setShowModal(true)
    }

    function close() {
        setShowModal(false)
        if (props.onCancel) {
            props.onCancel()
        }
    }

    const ref = useRef<HTMLDivElement>(null)
    const escapeListener = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            close()
        }
    }, [])
    const clickListener = useCallback(
        (e: MouseEvent) => {
            if (ref != null && ref.current != null && !(ref.current! as any).contains(e.target)) {
                close()
            }
        },
        [ref.current],
    )

    // Below is the 10 lines of code you need.
    useEffect(() => {
        if (props.showModal) {
            open()
        } else {
            close()
        }
    }, [props.showModal])

    return (
        showModal ? (
            <ModalComponent>
                <ModalDiv>
                    <ModalInnerDiv ref={ref}>
                        {props.children}
                    </ModalInnerDiv>
                </ModalDiv>
            </ModalComponent>) : null
    );
}


const ModalDiv = styled.div`
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalInnerDiv = styled.div`
  display: flex;
`


export default ConfirmModal