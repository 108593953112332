import React, {ReactNode} from 'react';
import Header from "./component/Header";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import DefaultOuterDiv from "../style/div/DefaultOuterDiv";
import LoginFooter from "./component/LoginFooter";

interface ComponentProps {
    children: ReactNode;
    width?: number;
    height?: number;
}

function Index(props: ComponentProps) {
    // const compassTitle = useSelector((state: RootState) => state.compassReducer.title)

    return (
        <div style={{display: "flex", flexDirection: "column", flex: 1, width: props.width, height: props.height}}>
            <Header/>
            {/*<NavBar/>*/}
            <DefaultOuterDiv>
                <DefaultWrapperDiv>
                    {/*{compassTitle.first_depth != "" ? <Compass first_depth={compassTitle.first_depth} first_depth_width={198}*/}
                    {/*         second_depth={compassTitle.second_depth} visibility={compassTitle.visibility}/> : <></>}*/}
                    {props.children}
                </DefaultWrapperDiv>
            </DefaultOuterDiv>
            <LoginFooter/>
            {/*{compassTitle.first_depth == "" ? <Footer/> : <LoginFooter/>}*/}
        </div>
    );
}

export default Index;