export const manufacturerYear =[
    {title: "2016년", value : "2016"},
    {title: "2017년", value : "2017"},
    {title: "2018년", value : "2018"},
    {title: "2019년", value : "2019"},
    {title: "2020년", value : "2020"},
    {title: "2021년", value : "2021"},
]

export const manufacturerMonth =[
    {title: "1월", value : "01"},
    {title: "2월", value : "02"},
    {title: "3월", value : "03"},
    {title: "4월", value : "04"},
    {title: "5월", value : "05"},
    {title: "6월", value : "06"},
    {title: "7월", value : "07"},
    {title: "8월", value : "08"},
    {title: "9월", value : "09"},
    {title: "10월", value : "10"},
    {title: "11월", value : "11"},
    {title: "12월", value : "12"},
]
