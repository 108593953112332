import React, {useState} from 'react';
import GasProductScreen from "./GasProductScreen";
import GasProductModalContainer from "./GasProductModalContainer";

interface GasProductContainerComponentProps {
}


export enum MethodState {
    SAVE,
    UPDATE
}

export type ProductMethodType = {
    type : MethodState
    payload? : string
}

function GasProductContainer(props: GasProductContainerComponentProps) {
    const [type, setType] = useState<ProductMethodType>()


    function onOpenModal(type: ProductMethodType) {
        console.log(type)
        setType(type)
    }

    function onCloseModal() {
        setType(undefined)
    }

    return (
        <div>
            <GasProductScreen openModal={onOpenModal}/>
            <GasProductModalContainer type={type} onCloseModal={onCloseModal}/>
        </div>
    );
}

export default GasProductContainer