import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {MenuItem} from "../../../components/style/select/Select";

export async function salesAccountRegistrationForm() {
    const response = await axios.get<BaseResponse<SalesAccountRegistrationFormResponse>>("/sales/account/form")
    return response.data
}

export type SalesAccountRegistrationFormResponse = {
    collectMoneyType : Array<CollectMoneyType>
    facilityOwnerType : Array<FacilityOwnerType>
    volumePriceType : Array<volumePriceType>
    saleType : Array<SaleType>
    sellType : Array<SellType>
    tankOwnerType : Array<TankOwnerType>
    accountType : Array<AccountType>
}

type CollectMoneyType = MenuItem
type FacilityOwnerType = MenuItem
type volumePriceType = MenuItem
type SaleType = MenuItem
type SellType = MenuItem
type TankOwnerType = MenuItem
type AccountType = MenuItem