import React, {useEffect} from 'react';
import LoginScreen from "./LoginScreen";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from "../../redux";
import {signInAsync} from "../../redux/account/signInModule";
import {SignInRequest} from "../../api/signIn";
import {LINK_TANK} from "../../resources/LinkConstants";
import {useParams} from "react-router";
import {SignInFromAdminRequest} from "../../api/SignInFromAdmin";
import {signInFromAdminAsync} from "../../redux/account/signInFromAdminModule";


interface LoginComponentProps {

}

function LoginContainer(props: LoginComponentProps) {

    const signInFromAdminRequest = useParams<SignInFromAdminRequest>()

    const {data, loading} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse);
    const {data: adminData, loading : adminLoading} = useSelector((state: RootState) => state.accountReducers.signInFromAdminReducer.signInFromAdmResponse);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    function onConfirm(request: SignInRequest) {
        dispatch(signInAsync.request(request));
    }

    useEffect(() => {
        if (signInFromAdminRequest.adminToken && signInFromAdminRequest.memberUid) {
            localStorage.clear()
            dispatch(signInFromAdminAsync.request(signInFromAdminRequest));
        }
    }, [])

    useEffect(() => {
            if (data != null || adminData != null) {
                if (location.state?.referrer?.pathname != null) {
                    history.replace(location.state.referrer.pathname)
                } else {
                    history.replace(LINK_TANK)
                }
            }
        }
    )

    return (
        <>
            <LoginScreen onConfirmClick={onConfirm} loading={loading && adminLoading}/>
        </>
    )
}

export default LoginContainer