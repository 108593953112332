import React, {useEffect} from 'react';
import Purchase from "./PurchaseAccountScreen";

interface PurchaseAccountRegistContainerProps {
}

interface MatchType {
    userId : string
}

function PurchaseAccountContainer(props: PurchaseAccountRegistContainerProps) {

    useEffect(() => {
        console.log("pa con")
    })
    return (
        <Purchase/>
    );
}

export default PurchaseAccountContainer