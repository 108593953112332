import React from 'react';
import ErpTableComponent from "../../../../components/table/ErpTable";
import {useAsync} from "../../../../hooks/useAsync";
import {getGasProductList} from "../../../../api/basic/gasproduct/getGasProductList";

interface TableExampleGridComponentProps {
    onCellClicked: (uid: string) => void
}

function GasProductTable(props: TableExampleGridComponentProps) {
    const {data: gasProductResponse} = useAsync(getGasProductList, true)

    function onCellClicked(uid: string) {
        props.onCellClicked(uid)
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                onCellClicked={onCellClicked}
                rowData={gasProductResponse}
                columns={[
                    {
                        id: "index",
                        title: "순번",
                        accessor: "",
                        sortable: true,
                        width: 70,
                        autoIncrement: true
                    }, {
                        id: "code",
                        title: "코드",
                        accessor: "code",
                        sortable: true,
                        width: 80,
                        height: 200,

                    }, {
                        id: "type",
                        title: "구분",
                        accessor: "gasProductType",
                        sortable: true,
                        width: 80,
                    }, {
                        id: "title",
                        title: "품목명",
                        accessor: "title",
                        sortable: true,
                        width: 80,
                    }, {
                        id: "standard",
                        title: "규격",
                        accessor: "standard",
                        sortable: true,
                        width: 80,
                    }, {
                        id: "weight",
                        title: "Kg",
                        accessor: "weight",
                        sortable: true,
                        width: 80,
                    }, {
                        id: "price",
                        title: "판매가",
                        accessor: "price",
                        sortable: true,
                        width: 220,
                    }, {
                        id: "pricePerKg",
                        title: "Kg당 판매가",
                        accessor: "pricePerKg",
                        sortable: true,
                        width: 220,
                    }, {
                        id: "purchasePrice",
                        title: "구매가",
                        accessor: "purchasePrice",
                        sortable: true,
                        width: 220,
                    }
                ]}/>
        </>

    )
}
export default GasProductTable