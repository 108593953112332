import axios from 'axios';
import {BaseResponse} from "../../BaseResponse";

export async function purchaseAccountRegistration(data : PurchaseAccountRegistRequest) {
    const response = await axios.post<BaseResponse<boolean>>("/purchase/account", data)

    return response.data
}

export type PurchaseAccountRegistRequest = {
    code: string
    accountName: string,
    //Set 하는 방법 찾아야함
    shopTypeId: number,
    tel: string,
    fax: string
    chargePerson: string
    chargePersonTel: string
    email: string
    businessNumber: string
    ownerName: string
    address: string
    addressDetail: string
    //@todo 위경도 구하는법 알아내야함
    // latitude: number,
    // longitude: number,
    businessType: string
    type: string
    memo1: string
    memo2: string
    bankId: number,
    bankAccountNumber: string,
    bankAccountOwnerName: string,
    propanePrice: number,
    butanePrice: number,
    isActive: boolean
}
