import React, {useEffect} from 'react'
import {pdfjs} from 'react-pdf'
import FixedWeightText from "../../../../components/style/text/FixedWeightText"
import CancelButton from "../../../../components/style/button/CancelButton"
import ConfirmButton from "../../../../components/style/button/ConfirmButton"
import styled from "styled-components"
import {LINK_TANK} from "../../../../resources/LinkConstants"
import TopButton from "../../../../components/style/button/TopButton"
import {Div} from "../../../basic/component/GasProduct/GasProductScreen";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../../../redux/CompassModule";
import {ItemBoxDiv} from "../../component/ItemBox";
import {RowDiv} from "../../component/RowDiv";
import Datepicker from "../../../../components/style/datepicker/Datepicker";
import Moment from "moment";
import {useForm} from "react-hook-form";
import TankRetestDetailListTable from "./TankReExaminationDetailList";
import TankRetestUpdateDetailListTable from "./TankReExaminationDetailUpdateList";

interface Props {
    isUpdate? : boolean
}

function TankRetestRegistScreen(props: Props) {

    const title = props.isUpdate == true ? "재검사 수정" : "재검사 신청"
    const {register, setValue} = useForm()

    const dispatch = useDispatch()

    dispatch(changeTitle({
        title: {
            first_depth: "탱크관리홈",
            second_depth : title,
        }
    }))

    const onSubmit = (data: any) => {
        console.log("fork", data)
        // setRequest(data)
    }

    function handleChangeStartDate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setValue("startDate", event.target.value)
    }

    useEffect(()=>{
        console.log(props.isUpdate, "test2")
    })

    return (
        <form>
            <HeaderDiv>
                <FixedWeightText fontSize={"24px"} fontWeight={500}>
                    {title}
                </FixedWeightText>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <CancelButton marginRight={8} link={LINK_TANK}/>
                    <ConfirmButton onSubmit={onSubmit}>신청</ConfirmButton>
                </div>
            </HeaderDiv>

            <TitleText fontSize={"18px"} fontWeight={500}>검사 이력</TitleText>

            <RowDiv>
                <ItemBoxDiv>신청일</ItemBoxDiv>
                <MarginDatePicker
                    defaultValue={Moment().format('YYYY-MM-DD')}
                    onChange={handleChangeStartDate}
                    reference={register}
                    name={"startDate"}
                />
                <ItemBoxDiv>완료요청일</ItemBoxDiv>
                <HalfDatePicker
                    defaultValue={Moment().format('YYYY-MM-DD')}
                    onChange={handleChangeStartDate}
                    reference={register}
                    name={"startDate"}
                />
            </RowDiv>

            {props.isUpdate == true ? <TankRetestUpdateDetailListTable onClick={()=>{}}/> : <TankRetestDetailListTable onClick={()=>{}}/>}


            <Div>
                <TopButton/>
            </Div>
        </form>
    );
}

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top : 22px;
  margin-bottom: 22px;
`

const HalfDatePicker = styled(Datepicker)`
  width: 404px;
  height: 48px;
`

const MarginDatePicker = styled(HalfDatePicker)`
  margin-right : 16px;
`

const TitleText = styled(FixedWeightText)`
  margin-top: 60px;
  margin-bottom: 12px;
`

export default TankRetestRegistScreen