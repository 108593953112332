import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {MenuItem} from "../../../components/style/select/Select";
import {StringMenuItem} from "../../../components/style/select/StringItemSelect";

export async function getCertificateForm() {
    const response = await axios.get<BaseResponse<CertificateForm>>("/tank/certificate/form")
    return response.data
}

export type CertificateForm = {
    dateType: Array<CertificateFormItem>,
    searchType: Array<MenuItem>,
}

type CertificateFormItem = {
    id : number,
    title : string
    value : Array<StringMenuItem>
}