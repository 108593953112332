import React, {useState} from 'react';
import {ProductMethodType} from "../GasProduct/GasProductContainer";
import EtcProductScreen from "./EtcProductScreen";
import EtcProductModalContainer from "./EtcProductModalContainer";

interface EtcProductContainerComponentProps {
}


function EtcProductContainer(props: EtcProductContainerComponentProps) {

    const [type, setType] = useState<ProductMethodType>()

    function onOpenModal(type: ProductMethodType) {
        setType(type)
    }

    function onCloseModal() {
        setType(undefined)
    }

    return (
        <div>
            <EtcProductScreen openModal={onOpenModal}/>
            <EtcProductModalContainer type={type} onCloseModal={onCloseModal}/>
        </div>
    );
}

export default EtcProductContainer