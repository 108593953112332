import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {PurchaseDetail} from "./getPurchaseDetailList";

export async function postPurchase(data : PurchaseRequest) {
    const response = await axios.post<BaseResponse<boolean>>("/purchase", data)

    return response.data
}

export type PurchaseRequest = {
    accountId : string,
    date: string,
    butaneRemainAmount: number,
    propaneRemainAmount: number,
    memo: string,
    detailList : Array<PurchaseDetail>
}
