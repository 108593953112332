import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import Button from "../../../components/style/button/Button";
import {MethodState, ProductMethodType} from "./GasProduct/GasProductContainer";

interface BasicTopTitleComponentProps {
    openModal: (type: ProductMethodType) => void
    title? : string
}

function BasicTopTitle(props: BasicTopTitleComponentProps) {
    return (
        <div>
            <TitleSection>
                <Title>
                    기초데이터
                </Title>
                <SaveButton onClick={() => props.openModal({type: MethodState.SAVE})}>
                    {props.title ? "등록" : "수정"}
                </SaveButton>
            </TitleSection>
        </div>
    );
}

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 46px;
  
`
const Title = styled(FixedWeightText)`
  width: 111px;
  height: 36px;
  font-size: 24px;
  font-weight: 500;
`

const SaveButton = styled(Button)`
  width: 120px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

export default BasicTopTitle