export const LINK_HOME = "/home"

export const LINK_SIGN_UP = "/signUp"
export const LINK_SIGN_IN = "/signIn"

export const LINK_PURCHASE_ACCOUNT = "/purchase_account"
export const LINK_PURCHASE_ACCOUNT_REGISTRATION = "/purchase_account/registration"
export const LINK_PURCHASE_ACCOUNT_REGISTRATION_MODIFICATION = "/purchase_account/registration:accountId"

export const LINK_SALES_ACCOUNT = "/sales_account"
export const LINK_SALES_ACCOUNT_REGISTRATION = "/sales_account_registration"

export const LINK_SALES = "/sales"

export const LINK_PURCHASE = "/purchase"
export const LINK_PURCHASE_REGISTRATION = "/purchase/manage"
export const LINK_PURCHASE_MODIFICATION = "/purchase/manage/:purchaseId"

export const LINK_METER_CHECK = "/meter_check"

export const LINK_COLLECT_MONEY = "/collect_money"

export const LINK_CID = "/cid"

export const LINK_BASIC = "/basic"
export const LINK_BASIC_GAS_PRODUCT = "/basic/gas_product"
export const LINK_BASIC_VOLUME_PRICE = "/basic/volume_price"
export const LINK_BASIC_ETC_PRODUCT = "/basic/etc_product"
export const LINK_BASIC_EMPLOYEE = "/basic/employee"
export const LINK_BASIC_GROUP = "/basic/group"

export const LINK_TANK = "/tank"
export const LINK_TANK_REGISTRATION = "/tank_registration"
export const LINK_TANK_MODIFICATION = "/tank_registration/:uid"

export const LINK_RETEST_REGISTRATION = "/tank_retest_registration"
export const LINK_RETEST_MODIFICATION = "/tank_retest_registration/:retestId"

export const LINK_CUSTOMER = "/customer"

export const LINK_ETC = "/etc"