import React, {useState} from 'react';
import styled from 'styled-components';
import {CommonButton} from "./component/CommonButton";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import {RowDiv} from "./component/RowDiv";
import {LINK_TANK_REGISTRATION} from "../../resources/LinkConstants";
import {Link} from "react-router-dom";
import BasicNavButton from "../basic/component/BasicNavButton";
import TankRetest from "./reexamination/TankRetest";
import {useForm} from "react-hook-form";
import TankCertificationContainer from "./certificate/TankCertificationContainer";
import TankCertificateLoadModalContainer from "./component/load/TankCertificateLoadModalContainer";

interface TankCertificationScreenComponentProps {
}

type TankTest = {}

function TankScreen (props : TankCertificationScreenComponentProps){

    //추후 API작업 후 수정
    const {register, handleSubmit} = useForm<TankTest>({
        mode: "onChange"
    })

    const [showLoad, setShowLoad] = useState(false)

    const [select, setSelect] = useState(1);

    function onSubmit(tankTest: TankTest) {

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TitleDiv>
                <TitleText>
                    탱크관리
                </TitleText>
                <ButtonDiv>
                    <CertificateButton
                        onClick={() => setShowLoad(!showLoad)}
                    >
                        합격증 불러오기
                    </CertificateButton>
                    <Link to={LINK_TANK_REGISTRATION} style={{textDecoration: 'none'}}>
                        <CommonButton>
                            합격증 등록
                        </CommonButton>
                    </Link>

                </ButtonDiv>
            </TitleDiv>
            <ButtonGroup>
                <BasicNavButton width={"78px"} isSelected={select == 1} onClick={() => setSelect(1)}>
                    합격증리스트
                </BasicNavButton>
                {/*<BasicNavButton width={"65px"} isSelected={select == 2} onClick={() => setSelect(2)}>*/}
                {/*    재검사신청*/}
                {/*</BasicNavButton>*/}
            </ButtonGroup>

            <Space/>

            {select == 1 && <TankCertificationContainer/>}
            {select == 2 && <TankRetest/>}
            <TankCertificateLoadModalContainer showModal={showLoad}
                                            onCloseModal={() => setShowLoad(false)}
                                            onConfirm={() => setShowLoad(false)}
            />
        </form>
    );
}

const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 16px;
`

const Space = styled.div`
  height: 32px;
`

const ButtonGroup = styled(RowDiv)`
  width: 167px;
  justify-content: space-between;
  
`

const TitleText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 500;
`

const ButtonDiv = styled.div`
  display: flex;
  flex-display:row;
  justify-content: space-between;
`

const CertificateButton = styled(CommonButton)`
  width: 91px;
  margin-right: 8px;
`
export const SpaceBetweenDiv = styled(RowDiv)`
  height: 48px;
  justify-content: space-between;
`



export default TankScreen