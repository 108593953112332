import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getGasProduct(uuId : string) {
    const response = await axios.get<BaseResponse<GasProductResponse>>(`/basic/gas_product/${uuId}`)
    return response.data
}

export type GasProductResponse = {
    code: string
    title: string,
    gasProductType: number,
    standard: string,
    weight: number,
    price: number,
    isPriceTaxInclude: boolean,
    pricePerKg: number,
    isPricePerKgTaxInclude: boolean,
    purchasePrice: number
    isActive: boolean
}
