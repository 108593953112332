import React, {ReactNode} from 'react';
import styled from 'styled-components';
import Button from "./Button";
import FixedWeightText from "../text/FixedWeightText";

interface ConfirmButtonComponentProps {
    onSubmit? : (data : any) => void
    children? : ReactNode
}

function ConfirmButton(props: ConfirmButtonComponentProps) {

    return (
        <StyledButton
            style={{backgroundColor: "var(--blue)"}}
            type={"submit"}
            onSubmit={props.onSubmit}
        >
            <ButtonText>{props.children ? props.children : "등록"}</ButtonText>
        </StyledButton>
    );
}

export const StyledButton = styled(Button)`
  width: 80px;
  height: 48px;
  background-color: var(--gray-5);
  align-items: center;
`

const ButtonText = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  width: 26px;
  height: 20px;
  font-size: 13px;
  color: var(--white);
`

export default ConfirmButton