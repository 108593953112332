import {combineReducers} from "redux";
import {all} from "redux-saga/effects";
import {purchaseRegistReducer} from "./PurchaseRegistModule";

export const purchaseReducers = combineReducers({
    purchaseRegistReducer
})

export function* purchaseSaga() {
    yield all([
    ]);
}