import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {TankCertificate} from "./postCertificate";

export async function postOCR(data : FormData) {
    const response = await axios.post<BaseResponse<Array<TankCertificate>>>("/ocr", data,{
        headers : {"Content-Type": "multipart/form-data"}
    })

    return response.data
}
