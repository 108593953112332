import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getEtcProductList(page: number, pagingSize: number, sortCallName: string, sort: string) {
    const response = await axios.get<BaseResponse<EtcProductListResponse>>(
        `/basic/etc_product`, {
            params: {
                page: page,
                size: pagingSize,
                sortCallName: sortCallName,
                sort: sort
            }
        })
    return response.data
}

export type EtcProductListResponse = {
    totalCount: number,
    itemList: Array<EtcProductList>,
    currentPage: number,
    totalPage: number,
}

export type EtcProductList = {
    uid: string,
    code: string,
    title: string,
    standard: string,
    unit: number,
    sellPrice: number,
    pricePerKg: number,
    purchasePrice: number,
}
