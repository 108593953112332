import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalHeader from "../../../../components/modal/Header";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Button from "../../../../components/style/button/Button";
import Input from "../../../../components/style/input/Input";
import {ItemBoxDiv} from "../../../tank/component/ItemBox";
import {EtcProductResponse} from "../../../../api/basic/etcproduct/getEtcProduct";
import {EtcProductRegistRequest} from "../../../../api/basic/etcproduct/etcProductRegistration";
import {Controller, useForm} from "react-hook-form";
import BasicCheckBox from "../../../../components/style/button/BasicCheckBox";
import {NonRequiredRulesAndValidationTelNumber} from "../../../../components/form/BaseFormType";
import ItemBox from "../../../../components/style/div/ItemBox";

interface BasicModalProps {
    data?: EtcProductResponse
    update: (data: EtcProductRegistRequest) => void
    save: (data: EtcProductRegistRequest) => void
    delete: () => void
    closeModal: () => void
}

function EtcProductModalScreen(props: BasicModalProps) {
    const {handleSubmit, setValue, register, control} = useForm<EtcProductRegistRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const [sellPriceVAT, setSellPriceVAT] = useState(false)
    const [kgRatioPriceVAT, setKgRatioPriceVAT] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSellPriceVAT(event.target.checked)
        setValue("isSellPriceTaxInclude", event.target.checked)
    }

    const handleChangeKgRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKgRatioPriceVAT(event.target.checked)
        setValue("isPurchasePriceTaxInclude", event.target.checked)
    }

    function closeModal() {
        props.closeModal()
        setSellPriceVAT(false)
        setKgRatioPriceVAT(false)
    }


    const onSubmit = (data: EtcProductRegistRequest) => {
        if (props.data) {
            props.update(data)
        } else {
            props.save(data)
        }
    }


    function onDelete() {
        props.delete()
    }

    useEffect(() => {
        if (props.data) {
            setValue("code", props.data.code)
            setValue("title", props.data.title)
            setValue("standard", props.data.standard)
            setValue("unit", props.data.unit)
            setValue("sellPrice", props.data.sellPrice)
            setSellPriceVAT(props.data.isSellPriceTaxInclude)
            setValue("isSellPriceTaxInclude", props.data.isSellPriceTaxInclude)
            setValue("purchasePrice", props.data.purchasePrice)
            setKgRatioPriceVAT(props.data.isPurchasePriceTaxInclude)
            setValue("isPurchasePriceTaxInclude", props.data.isPurchasePriceTaxInclude)
        }
    }, [props.data])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalDiv>
                <ModalHeader title={"기타 품목 등록"} onCancel={closeModal}/>
                <ContentDiv>
                    <HeaderDiv>
                        <HeaderText>
                            기타 품목 등록
                        </HeaderText>
                    </HeaderDiv>

                    <ItemDiv>
                        <ItemBoxDiv>
                            코드
                        </ItemBoxDiv>
                        <ItemInput
                            name="code"
                            ref={register({
                                required: true,
                                minLength: 4,
                                maxLength: 4,
                                pattern: /^([0-9])*$/
                            })}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            품목명
                        </ItemBoxDiv>
                        <ItemInput
                            name="title"
                            ref={register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            규격
                        </ItemBoxDiv>
                        <ItemInput
                            name="standard"
                            ref={register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            단위
                        </ItemBoxDiv>
                        <ItemInput
                            name="unit"
                            ref={register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            판매가
                        </ItemBoxDiv>
                        <ItemInput
                            name="sellPrice"
                            ref={register}
                        />

                        <Controller
                            as={
                                <BasicCheckBox
                                    handleChange={handleChange}
                                    checked={sellPriceVAT}
                                />
                            }
                            control={control}
                            defaultValue={sellPriceVAT}
                            name={"isSellPriceTaxInclude"}
                        />

                        <CheckBoxText
                            color={sellPriceVAT ? "var(--black)" : "var(--gray-4)"}
                        >
                            VAT 포함
                        </CheckBoxText>

                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            구매가
                        </ItemBoxDiv>
                        <ItemInput
                            name="purchasePrice"
                            ref={register}
                        />

                        <Controller
                            as={
                                <BasicCheckBox
                                    handleChange={handleChangeKgRatio}
                                    checked={kgRatioPriceVAT}
                                />
                            }
                            control={control}
                            defaultValue={kgRatioPriceVAT}
                            name={"isPurchasePriceTaxInclude"}
                        />

                        <CheckBoxText
                            color={kgRatioPriceVAT ? "var(--black)" : "var(--gray-4)"}
                        >
                            VAT 포함
                        </CheckBoxText>
                    </ItemDiv>

                    <FooterDiv>
                        <OtherButton onClick={closeModal}>
                            <OtherText fontWeight={500}>취소</OtherText>
                        </OtherButton>

                        {props.data && (<OtherButton onClick={onDelete}>
                                <OtherText fontWeight={500}>삭제</OtherText>
                            </OtherButton>
                        )}

                        <ConfirmButton type="submit">
                            {props.data && <ConfirmText fontWeight={500}>수정</ConfirmText>}
                            {!props.data && <ConfirmText fontWeight={500}>등록</ConfirmText>}
                        </ConfirmButton>
                    </FooterDiv>
                </ContentDiv>
            </ModalDiv>
        </form>
    );
}

const ModalDiv = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 720px;
    height: 624px;
    border-radius: 4px;
    background-color: var(--white);
`

const ContentDiv = styled.div`
  height: 522px;
  padding : 22px 32px 32px 32px;
`

const HeaderDiv = styled.div`
  height: 48px;
`
const HeaderText = styled(FixedWeightText)`
  font-size : 24px;
  font-weight: 500;
`

const ConfirmButton = styled(Button)`
  width: 120px;
  height: 48px;
  margin-left: 8px;
  background-color: var(--blue);
`

const OtherButton = styled(ConfirmButton)`
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`

const ConfirmText = styled(FixedWeightText)`
  width: 46px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  color: var(--white);
`

const OtherText = styled(ConfirmText)`
  color : var(--red);
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const CheckBoxText = styled(FixedWeightText)`
  width: 54px;
  height: 20px;
  font-size : 14px;
  font-weight : 500;
  line-height: 1.43;
`

export default EtcProductModalScreen