import React, {ReactNode} from 'react';
import styled from "styled-components";
import FixedWeightText from "./FixedWeightText";

interface TextProps {
    title : string
    title2? : string
}

function BoxText(props: TextProps) {
    return (
        <>
            <Text>{props.title}</Text>
            {props.title2 &&<Text>{props.title2}</Text>}
        </>
    );
}

export default BoxText;

const Text = styled(FixedWeightText)`
  font-size : 14px;
  font-weight: 400;
`
