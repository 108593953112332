import React, {useEffect} from 'react';
import styled from 'styled-components';
import BaseText from "../../components/style/text/BaseText";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../redux/CompassModule";

interface HomeScreenComponentProps {

}

function HomePage(props: HomeScreenComponentProps) {

    return (
        <div>
            <Cover>
                <BaseText size={"48px"} textStyle={"bold"} color={"var(--white)"} width={"519px"}>
                    새로운 가스 경영 프로그램
                </BaseText>
            </Cover>
        </div>
    );
}

export const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 640px;
  background-color: var(--black);
`

export default HomePage