import React from 'react';
import styled from "styled-components";

interface HorizontalSpaceProps {
    height?: string
}

function MatchHorizontalSpace(props: HorizontalSpaceProps) {
    return (
        <StyledDiv {...props}/>
    );
}

export default MatchHorizontalSpace

const StyledDiv = styled.div`
  height: ${(props: HorizontalSpaceProps) => props.height || "1px"};
  width: 1080px;
  background-color: var(--gray-5);
`