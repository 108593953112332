import {ActionType, createAction, createReducer} from "typesafe-actions";

const CHANGE_TITLE = 'compass/CHANGE_TITLE'

export const changeTitle = createAction(CHANGE_TITLE)<{
    title : CompassTitle
}>()
type ChangeTitleAction = ActionType<typeof changeTitle>
type ChangeTitleState = {
    title: CompassTitle
};
type CompassTitle = {
    first_depth: string,
    second_depth?: string
    visibility?: boolean
}
const initialState: ChangeTitleState = {
    title: {
        first_depth: "매입거래처 관리 홈",
    }
};
export const compassReducer = createReducer<ChangeTitleState, ChangeTitleAction>(initialState)
    .handleAction(changeTitle, (state, { payload : {title : value}}) => ({
        ...state,
        title : value
    }))