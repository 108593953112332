import {ActionType, createAction, createReducer} from "typesafe-actions";

export const LOG_OUT = 'logOut/LOG_OUT'

export const logOut = createAction(LOG_OUT)()

type LogOutAction = ActionType<typeof logOut>

type LogOustState = {
};

const initialState: LogOustState = {
};

export const logOutReducer = createReducer<LogOustState, LogOutAction>(initialState)