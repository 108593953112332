import styled from "styled-components";

export const ItemBoxDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 80px;
  height: 40px;
  padding-left: 12px;
  align-items: center;
  font-size: 10px;
  background-color: var(--gray-6);
`
