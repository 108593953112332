import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function postCertificate(data: Array<TankCertificate>) {
    const response = await axios.post<BaseResponse<boolean>>("/tank/certificate", data)
    return response.data
}

export type TankCertificate = {
    uid? : string
    fileUri : string
    certificateNumber : string
    type : number
    typeValue : string
    manufacturer : number
    manufacturerNumber : string
    volume : number
    gasName : string
    // 검사합격일
    passDate : string
    nextExaminationDate : string
    //자가여부
    ownerType : number
    lastTestDate : string
    memo : string
    finalTestDate : string
    salesAccountName : string
    userName : string
    address : string
    history : Array<ExaminationHistory>
}

export type ExaminationHistory = {
    uid? : string
    certificateId? : string,
    testDate: string,
    testType: number,
    testingOrganization: string,
    fileUri: string,
    fileName? : string
}