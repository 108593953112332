import React, {useEffect, useReducer, useState} from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import {GridApi, ICellRendererParams} from "ag-grid-community";
import {PurchaseDetail} from "../../../api/purchase/getPurchaseDetailList";
import {useDispatch} from "react-redux";
import {add, remove} from "../../../redux/purchase/PurchaseRegistModule";

interface RegistListTableComponentProps {
    isEditable?: boolean,
    data: Array<PurchaseDetail> | undefined,
    calSum : () => void
}

function RegistDetailListTable(props: RegistListTableComponentProps) {
    const dispatch = useDispatch()

    function activeCellRenderer(data: ICellRendererParams) {
        let isDeleted = false
        return <div onClick={() => {
            if (!isDeleted) {
                isDeleted = true
                dispatch(remove(data.api.getSelectedRows()[0]))
            }
        }}>삭제</div>
    }

    return (
        <ErpTableComponent
            tableWidth={712}
            tableHeight={312}
            rowData={props.data}
            stayScrollEnd={true}
            fullRowEditColId={"purchaseCount"}

            columns={[
                {
                    id: "index",
                    title: "순번",
                    accessor: "",
                    sortable: true,
                    width: 30,
                    autoIncrement: true
                }, {
                    id: "code",
                    title: "품목코드",
                    accessor: "code",
                    sortable: true,
                    width: 55,
                }, {
                    id: "title",
                    title: "품목명",
                    accessor: "title",
                    sortable: true,
                    width: 80,
                }, {
                    id: "type",
                    title: "구분",
                    accessor: "type",
                    sortable: true,
                    width: 43,
                }, {
                    id: "standard",
                    title: "규격",
                    accessor: "standard",
                    sortable: true,
                    width: 48,
                }, {
                    id: "purchaseCount",
                    title: "입고",
                    accessor: "purchaseCount",
                    sortable: true,
                    width: 40,
                    editable: props.isEditable,
                    onCellValueChanged : (value : any) => props.calSum()
                }, {
                    id: "sellCount",
                    title: "출고",
                    accessor: "sellCount",
                    sortable: true,
                    width: 40,
                    editable: props.isEditable
                }, {
                    id: "containerPurchaseCount",
                    title: "입고\n(미검)",
                    accessor: "containerPurchaseCount",
                    sortable: true,
                    width: 70,
                    editable: props.isEditable
                }, {
                    id: "containerSellCount",
                    title: "출고\n(미검)",
                    accessor: "containerSellCount",
                    sortable: true,
                    width: 70,
                    editable: props.isEditable
                }, {
                    id: "stationPurchaseCount",
                    title: "보관\n(충전소)",
                    accessor: "stationPurchaseCount",
                    sortable: true,
                    width: 70,
                    editable: props.isEditable
                }, {
                    id: "stationSellCount",
                    title: "회수\n(충전소)",
                    accessor: "stationSellCount",
                    sortable: true,
                    width: 70,
                    editable: props.isEditable
                }, {
                    id: "disposalCount",
                    title: "폐기",
                    accessor: "disposalCount",
                    sortable: true,
                    width: 40,
                    editable: props.isEditable
                }
                , {
                    id: "delete",
                    title: "삭제",
                    accessor: "",
                    width: 40,
                    cellRendererFramework: activeCellRenderer
                }
            ]
            }
            onCellClicked={() => {
            }}
        />
    );
}

export default RegistDetailListTable