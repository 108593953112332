import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {GasProductType} from "../basic/gasproduct/gasProductRegistrationForm";

export async function getPurchaseDetailList(uid: string) {
    const response = await axios.get<BaseResponse<PurchaseDetailList>>(`/purchase/${uid}`)

    return response.data
}

export type PurchaseDetailList ={
    uid: string,
    accountId : string,
    date: string,
    butaneRemainAmount: number,
    propaneRemainAmount: number,
    propanePrice: number,
    butanePrice : number,
    memo: string,
    detailList : Array<PurchaseDetail>
}

export type PurchaseDetail = {
    uid?: string,
    productId : string,
    code: string,
    title: string,
    type: string,
    standard: string,
    weight? : number
    purchaseCount: number,
    sellCount: number,
    containerPurchaseCount: number,
    containerSellCount: number,
    stationPurchaseCount: number,
    stationSellCount: number,
    disposalCount: number,
    currentTimestamp? : number
}
