import React from 'react';
import ErpTableComponent from "../../../../components/table/ErpTable";
import {useAsync} from "../../../../hooks/useAsync";
import { getEmployeeList} from "../../../../api/basic/employee/getEmployeeList";
import {ICellRendererParams} from "ag-grid-community";

interface TableExampleGridComponentProps {
    onCellClicked : (uid : string) => void
}

function EmployeeTable(props: TableExampleGridComponentProps) {
    const {execute, data} = useAsync(getEmployeeList, true)


    function onCellClicked(uid: string) {
        props.onCellClicked(uid)
    }

    function onSortChanged(sortColName?: string, sort?: string) {
        execute(0, 10, sortColName, sort)
    }

    function activeCellRenderer(params: ICellRendererParams) {
        return <text>{params.value ? "정상" : "휴면"}</text>
    }

   return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                rowData={data?.itemList}
                onCellClicked={onCellClicked}
                onSortChanged={onSortChanged}
                columns={[
                    {
                        id: "index",
                        title: "순번",
                        accessor: "",
                        sortable: true,
                        width: 70,
                        autoIncrement: true
                    }, {
                        id: "code",
                        title: "코드",
                        accessor: "code",
                        clickable : true,
                        sortable: true,
                        width: 80,
                    }, {
                        id: "name",
                        title: "이름",
                        accessor: "name",
                        sortable: true,
                        width: 130,
                    }, {
                        id: "employeeType",
                        title: "구분",
                        accessor: "employeeType",
                        sortable: true,
                        width: 80,
                    }, {
                        id: "mobile",
                        title: "휴대폰번호",
                        accessor: "mobile",
                        sortable: true,
                        width: 180,
                    }, {
                        id: "groupAreaList",
                        title: "담당거래처",
                        accessor: "groupAreaList",
                        sortable: true,
                        width: 180,
                    }, {
                        id: "employeeId",
                        title: "아이디",
                        accessor: "employeeId",
                        sortable: true,
                        width: 120,
                    }, {
                        id: "password",
                        title: "비밀번호",
                        accessor: "password",
                        sortable: true,
                        width: 120,
                    }, {
                        id: "active",
                        title: "상태",
                        accessor: "active",
                        sortable: true,
                        width: 120,
                        cellRendererFramework: activeCellRenderer
                    }
                ]}/>
        </>

    )
}

export default EmployeeTable