import React from 'react';
import ErpTableComponent, {ColumnValidatorType} from "../../../../components/table/ErpTable";
import {VolumePriceValue} from "../../../../api/basic/volume/getVolumeList";

interface TableExampleGridComponentProps {
    data? : Array<VolumePriceValue>
}

function VolumeModalTable(props: TableExampleGridComponentProps) {


    return (
        <>
            <ErpTableComponent
                tableWidth={656}
                tableHeight={380}
                onCellClicked={()=>{}}
                rowData={props.data}
                columns={[
                    {
                        id: "title",
                        title: "조정기 압력",
                        accessor: "title",
                        sortable: true,
                        width: 228,
                    }, {
                        id: "value",
                        title: "mmH2O당 단가",
                        accessor: "value",
                        sortable: true,
                        editable : true,
                        columnValidator : ColumnValidatorType.INT,
                        width: 228,
                    }, {
                        id: "factor",
                        title: "압력 보정계수",
                        accessor: "factor",
                        sortable: true,
                        width: 200,
                    }
                ]}/>
        </>

    )
}

export default VolumeModalTable