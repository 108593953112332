import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {UploadTankCertificate} from "./postUploadTankCertificate";

export async function getSharedTankCertificate(passDate : string, manufacturerNumber : string, manufacturerType : number, volumeType : number) {
    const response = await axios.get<BaseResponse<UploadTankCertificate>>(`/tank/certificate/share`, {
        params: {
            passDate: passDate,
            manufacturerNumber : manufacturerNumber,
            manufacturerType : manufacturerType,
            volumeType : volumeType
        }
    })

    return response.data
}
