import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function purchaseAccountRegistrationForm() {
    const response = await axios.get<BaseResponse<PurchaseAccountRegistrationFormResponse>>("/purchase/account/form")
    return response.data
}

export type PurchaseAccountRegistrationFormResponse = {
    shopType: Array<ShopType>,
    bankType: Array<BankType>,
}

type ShopType = {
    id: number,
    title: string
}

type BankType = {
    id: number,
    title: string
}