import React from 'react';
import styled from 'styled-components';
import {RowDiv} from "../component/RowDiv";
import {ItemBoxDiv} from "../component/ItemBox";
import {SpaceBetweenDiv} from "../TankScreen";
import {CommonButton} from "../component/CommonButton";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import Input from "../../../components/style/input/Input";
import Button from "../../../components/style/button/Button";
import {DividerDiv} from "../../../components/style/div/Divider";
import Select, {MenuItem} from "../../../components/style/select/Select";
import {selectItem} from "../../../components/form/YesOrNoSelectItem";
import {FormProvider, useForm} from "react-hook-form";
import {Link, useHistory} from "react-router-dom";
import {LINK_RETEST_REGISTRATION} from "../../../resources/LinkConstants";
import TankRetestListTable from "./TankRetestListTable";

interface TankRetestComponentProps {

}

function TankRetest(props: TankRetestComponentProps) {
    const methods = useForm({
        mode: "onBlur"
    })

    const history = useHistory()

    const selectMockItem: Array<MenuItem> = [{id: 1, title: "검사예정년도"}, {id: 2, title: "제조년도"}]

    function onCellClicked(uid : string){
        history.push(LINK_RETEST_REGISTRATION + `/${uid}`)
    }

    return (
        <FormProvider {...methods}>
            <form>
                <RowDiv>
                    <ItemBoxDiv>
                        조회기간
                    </ItemBoxDiv>
                    <Select
                        width={"240px"}
                        height={"48px"}
                        marginLeft={"8px"}
                        menuItems={selectMockItem}
                    />
                    <Select
                        width={"240px"}
                        height={"48px"}
                        marginLeft={"8px"}
                        menuItems={selectItem}
                    />
                    <Select
                        width={"240px"}
                        height={"48px"}
                        marginLeft={"8px"}
                        menuItems={selectItem}
                    />

                    <SearchButton>검색</SearchButton>
                </RowDiv>

                <Divider/>
                <SpaceBetweenDiv>

                    {/*<TableTitle>재검사 리스트</TableTitle>*/}

                    {/*<Link to={LINK_RETEST_REGISTRATION} style={{textDecoration: 'none'}}>*/}
                    {/*    <CommonButton>재검사 신청</CommonButton>*/}
                    {/*</Link>*/}
                </SpaceBetweenDiv>
                <SpaceBetweenDiv>
                    <FixedWeightText>검색결과 999건</FixedWeightText>
                    <FixedWeightText>선택 1건</FixedWeightText>
                </SpaceBetweenDiv>

                <TankRetestListTable onClick={onCellClicked}/>
            </form>
        </FormProvider>
    );
}


const SelectBox = styled.select`
  width: 240px;
  height: 48px;
  border: solid 1px var(--gray-4);
  border-radius: 4px;
  margin-left: 8px;
`


const Searchbar = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 595px;
  height: 48px;
  padding-left: 12px;
  margin-left: 8px;
`

const SearchButton = styled(Button)`
  width: 80px;
  height: 48px;
  margin-left: 136px;
  background-color: var(--blue);
  color: var(--white);
`

const Divider = styled(DividerDiv)`
  margin-top: 40px;
`

const TableTitle = styled(FixedWeightText)`
  font-size : 18px;
  font-weight: 400;
`

export default TankRetest