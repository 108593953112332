import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalHeader from "../../../../components/modal/Header";
import {ItemBoxDiv} from "../../../tank/component/ItemBox";
import {InputDiv, InputText, RadioButton} from "../../../salesaccount/component/RadioButton";
import {Controller, FormProvider, useForm} from "react-hook-form";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Button from "../../../../components/style/button/Button";
import {EmployeeRegistrationFormResponse} from "../../../../api/basic/employee/employeeRegistrationForm";
import {EmployeeResponse} from "../../../../api/basic/employee/getEmployee";
import {EmployeeRegistRequest} from "../../../../api/basic/employee/employeeRegistration";
import {IOSSwitch} from "../../../../components/switch/MaterialSwitch";
import ModifyButton from "../../../../components/button/ModifyButton";
import SaveButton from "../../../../components/button/SaveButton";
import {NonRequiredRulesAndValidationTelNumber} from "../../../../components/form/BaseFormType";
import {ErrorInput} from "../../../../components/style/input/ErrorInput";
import {FormGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface EmployeeModalScreenComponentProps {
    data?: EmployeeResponse
    form?: EmployeeRegistrationFormResponse
    update: (data: EmployeeRegistRequest) => void
    save: (data: EmployeeRegistRequest) => void
    closeModal: () => void
}

function EmployeeModalScreen(props: EmployeeModalScreenComponentProps) {
    const methods = useForm<EmployeeRegistRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    function closeModal() {
        props.closeModal()
        setType(1)
    }

    const onSubmit = (data: EmployeeRegistRequest) => {

        if (props.data) {
            props.update(data)
        } else {
            console.log("data not found")
            props.save(data)
        }
        closeModal()
        window.location.reload()
    }

    const [type, setType] = useState(1)

    const [active, setActive] = useState(true)

    useEffect(() => {
        if (props.data) {
            methods.setValue("code", props.data.code)
            methods.setValue("name", props.data.name)
            setType(props.data.employeeType)
            methods.setValue("employeeType", props.data.employeeType)
            methods.setValue("groupAreaIdList", props.data.groupAreaIdList)
            methods.setValue("mobile", props.data.mobile)
            methods.setValue("employeeId", props.data.employeeId)
            methods.setValue("password", props.data.password)
            setActive(props.data.active)
            methods.setValue("active", props.data.active)
        }
    }, [props.data])

    return (
        <FormProvider {...methods} >
            <form>
                <ModalDiv>
                    <ModalHeader title={"직원 등록"} onCancel={closeModal}/>
                    <ContentDiv>
                        <HeaderDiv>
                            <HeaderText>
                                직원 등록
                            </HeaderText>
                        </HeaderDiv>

                        <ItemDiv>
                            <ItemBoxDiv>
                                코드
                            </ItemBoxDiv>
                            <ItemInput
                                name={"code"}
                                reference={methods.register({
                                    required: true,
                                    pattern: /^([0-9+]){4}$/,
                                })}
                                error={methods.errors.code?.type === "pattern"}
                                errorMessage={"코드를 확인해주세요.(숫자4자리)"}
                                placeholder={"코드(4자리)"}
                            />
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                이름
                            </ItemBoxDiv>
                            <ItemInput
                                name={"name"}
                                reference={methods.register({
                                    required: true,
                                    pattern: /^([ㄱ-ㅎ|ㅏ-ㅣ|가-힣])*$/,
                                })}
                                error={methods.errors.name?.type === "pattern"}
                                errorMessage={"이름을 확인해주세요.(20자이하)"}
                                placeholder={"이름을 입력해주세요"}
                            />
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                구분
                            </ItemBoxDiv>
                            {props.form?.employeeType.map((item) =>
                                <InputDiv>
                                    <RadioButton name={"employeeType"}
                                                 type={"radio"}
                                                 value={type}
                                                 checked={item.id == type}
                                                 onClick={() => setType(item.id)}
                                                 ref={methods.register({
                                                     required: true,
                                                 })}
                                    />
                                    <InputText>{item.title}</InputText>
                                </InputDiv>
                            )}
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                휴대폰번호
                            </ItemBoxDiv>
                            <ItemInput
                                name="mobile"
                                reference={methods.register(NonRequiredRulesAndValidationTelNumber)}
                                error={methods.errors.mobile?.types?.minLength === true || methods.errors.mobile?.types?.maxLength === true || methods.errors.mobile?.type === "pattern"}
                                errorMessage={"숫자만 입력해주세요(- 제외)"}
                                placeholder={"전화번호를 입력해주세요"}
                            />
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                담당거래처
                            </ItemBoxDiv>
                            TBD
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                아이디
                            </ItemBoxDiv>
                            <ItemInput
                                name={"employeeId"}
                                reference={methods.register({
                                    required: true,
                                    maxLength: 14,
                                    pattern: /^([A-Za-z0-9+])*$/
                                })}
                                error={methods.errors.employeeId?.type === "pattern"}
                                errorMessage={"아이디를 확인해주세요."}
                                placeholder={"아이디(숫자,영어 4~16자리)"}
                            />
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                비밀번호
                            </ItemBoxDiv>
                            <ItemInput
                                name="password"
                                reference={methods.register({
                                    required: true,
                                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{4,16}$/
                                })}
                                error={methods.errors.password?.type === "pattern"}
                                errorMessage={"비밀번호를 확인해주세요."}
                                placeholder={"비밀번호(4~16자리)"}
                            />
                        </ItemDiv>
                        <ItemDiv>
                            <ItemBoxDiv>
                                활성화여부
                            </ItemBoxDiv>
                            <FormGroup>
                                <Row>
                                    <FormControlLabel
                                        control={<IOSSwitch checked={active} onChange={() => setActive(!active)}
                                                            name={"active"} inputRef={methods.register}/>}
                                        label={null}
                                    />
                                    {active == false ?
                                        (<TrueDiv>
                                            <StateText>OFF</StateText>
                                        </TrueDiv>) :
                                        (<FalseDiv>
                                            <StateText>ON</StateText>
                                        </FalseDiv>)
                                    }
                                </Row>
                            </FormGroup>
                        </ItemDiv>

                        <WarningTextDiv>
                            <WarningText>
                                비활성화시 , 해당 아이디 앱 로그인이 불가능합니다.
                            </WarningText>
                        </WarningTextDiv>

                        <FooterDiv>
                            <OtherButton onClick={props.closeModal}>
                                <OtherText fontWeight={500}>취소</OtherText>
                            </OtherButton>

                            {props.data ?
                                <ModifyButton
                                    height={"48px"}
                                    width={"120px"}
                                    onConfirm={methods.handleSubmit(onSubmit)}
                                    description={"직원 수정"}
                                />
                                :
                                <SaveButton
                                    height={"48px"}
                                    width={"120px"}
                                    onConfirm={methods.handleSubmit(onSubmit)}
                                    description={"직원 등록"}
                                />
                            }
                        </FooterDiv>
                    </ContentDiv>
                </ModalDiv>
            </form>
        </FormProvider>
    );
}

const ModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 720px;
    height: 788px;
    padding: 0 0 32px;
    border-radius: 4px;
    background-color: var(--white);
`

const ContentDiv = styled.div`
  width: 656px;
  height: 728px;
  padding : 22px 32px 0px 32px;
`

const HeaderDiv = styled.div`
  height: 60px;
`
const HeaderText = styled(FixedWeightText)`
  font-size : 24px;
  font-weight: 500;
`

const ConfirmButton = styled(Button)`
  width: 120px;
  height: 48px;
  margin-left: 8px;
  background-color: var(--blue);
`

const OtherButton = styled(ConfirmButton)`
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`

const ConfirmText = styled(FixedWeightText)`
  width: 46px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  color: var(--white);
`

const OtherText = styled(ConfirmText)`
  color : var(--red);
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(ErrorInput)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const RadioText = styled(InputText)`
  font-size: 14px;
  font-weight: 500;
  width: 60px;
`

const WarningTextDiv = styled.div`
  margin-top: 16px;
`

const WarningText = styled(FixedWeightText)`
  font-size: 14px;
  text-align: left;
  color: var(--red);
`

const Row = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`

const TrueDiv = styled.div`
margin-left: -65px;
z-index: 2;
pointer-events:none
`

const FalseDiv = styled.div`
margin-left : -90px;
z-index : 2;
pointer-events:none
`

const StateText = styled(FixedWeightText)`
width: fit-content;
height: fit-content;
font-size: 14px;
font-weight: 500;
color: var(--white);
`

export default EmployeeModalScreen