import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getCertificateDetailForm() {
    const response = await axios.get<BaseResponse<CertificateDetailForm>>("/tank/certificate/detail/form")
    return response.data
}

export type CertificateDetailForm = {
    manufactureType: Array<TankDetailFormType>,
    ownerType: Array<TankDetailFormType>,
    certType: Array<TankDetailFormType>,
    volumeType : Array<TankDetailFormType>,
    examHistoryType : Array<TankDetailFormType>
}

export type TankDetailFormType ={
    id : number
    title : string
}
