import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import HorizontalSpace from "../../../components/HorizontalSpace";
import SubTitle from "../components/SubTitle";
import {ItemBoxDiv} from "../../tank/component/ItemBox";
import Input from "../../../components/style/input/Input";
import ProductListTable from "../components/ProductListTable";
import {useFormContext} from "react-hook-form";
import Select from "../../../components/style/select/Select";
import {SearchButton} from "./PurchaseRegistHeaderScreen";
import Button from "../../../components/style/button/Button";
import {useHistory} from "react-router-dom";
import RegistDetailListTable from "../components/RegistDetailListTable";
import {GasProductList} from "../../../api/basic/gasproduct/getGasProductList";
import {PurchaseDetail, PurchaseDetailList} from "../../../api/purchase/getPurchaseDetailList";
import {PurchaseRequest} from "../../../api/purchase/postPurchase";
import {useDispatch} from "react-redux";
import {add} from "../../../redux/purchase/PurchaseRegistModule";
import SumListTable from "../components/SumListTable";
import {PurchaseAccountList} from "../../../api/purchase/account/getPurchaseAccountList";
import {PriceType} from "./PurchaseRegistContainer";

interface PurchaseRegistDetailProps {
    data: Array<PurchaseDetail> | undefined
    resultData? : PurchaseDetailList
    gasProductList?: Array<GasProductList>
    postPurchase: (...payload1: any[]) => Promise<boolean | undefined>
    reset: () => void
    accountUid?: string
    postStatus: 'idle' | 'loading' | 'success' | 'error'
    update: () => void
    isUpdate: boolean
    price? : PriceType
}

function PurchaseRegistDetailScreen(props: PurchaseRegistDetailProps) {
    const history = useHistory()
    const {register, watch, setValue} = useFormContext()
    const dispatch = useDispatch()


    const [sum, setSum] = useState(0)

    function handleCancelButton() {
        history.go(-1)
    }

    function handleConfirmButton() {
        if (props.accountUid && props.data) {
            const paramData: PurchaseRequest = {
                accountId: props.accountUid,
                date: watch("date"),
                butaneRemainAmount: watch("butaneRemainAmount"),
                propaneRemainAmount: watch("propaneRemainAmount"),
                memo: watch("memo"),
                detailList: props.data
            }
            props.postPurchase(paramData)
        }
    }

    function handleUpdateButton() {
        console.log(props.data)
        // props.update()
    }

    function handleContinuesConfirmButton() {
        handleConfirmButton()
        props.reset()
    }

    function onProductAdd(data: PurchaseDetail) {
        dispatch(add(data))
    }

    function onChangeSum(){
        setSum(sum + Date.now())
    }

    useEffect(()=>{
        console.log(sum)

        if(sum == 0){
            setValue("propaneRemainAmount", props.resultData?.propaneRemainAmount)
            setValue("butaneRemainAmount", props.resultData?.butaneRemainAmount)
            setValue("memo", props.resultData?.memo)
        }
    },[props.resultData, props.data, sum])

    return (
        <div>
            <HorizontalSpace height={"4px"}/>
            <SubTitle text={"상세"} width={"1080px"}/>
            <RowDiv>
                <div>
                    <RegistDetailListTable
                        data={props.data}
                        isEditable={true}
                        calSum={onChangeSum}
                    />

                    <RemainBoard>
                        <RemainSection>
                            <ItemDiv>
                                <ItemBoxDiv>
                                    잔량(프로판)
                                </ItemBoxDiv>
                                <ItemInput name={"propaneRemainAmount"} ref={register}/>
                            </ItemDiv>
                            <ItemDiv>
                                <ItemBoxDiv>
                                    잔량(부탄)
                                </ItemBoxDiv>
                                <ItemInput name={"butaneRemainAmount"} ref={register}/>
                            </ItemDiv>
                        </RemainSection>
                        <RemainSection>
                            <ItemDiv>
                                <RemainBox>
                                    비고
                                </RemainBox>
                                <Memo name={"memo"} ref={register}/>
                            </ItemDiv>
                        </RemainSection>
                    </RemainBoard>

                    <SumListTable
                        data={props.data}
                        info={props.resultData}
                        sum={sum}
                        price={props.price}
                    />

                </div>
                <div>
                    <ProductListTable onProductListClick={onProductAdd} data={props.gasProductList}/>

                    <SearchProductDiv>
                        <SelectBox menuItems={[{id: 1, title: "품목명"}, {id: 2, title: "품목코드"}]} name={"select"}/>
                        <ProductSearchBar>
                            <ProductInput name={"searchQuery"}/>
                            <SearchButton>검색</SearchButton>
                        </ProductSearchBar>
                    </SearchProductDiv>
                </div>
            </RowDiv>
            <ButtonDiv>
                <CancelButton onClick={handleCancelButton}>취소</CancelButton>
                {props.isUpdate == true ? (<SaveButton onClick={handleUpdateButton}>수정</SaveButton>) :
                    (<>
                        <SaveButton onClick={handleConfirmButton}>등록</SaveButton>
                        <ContinuousButton onClick={handleContinuesConfirmButton}>연속등록</ContinuousButton>
                    </>)
                }

            </ButtonDiv>
        </div>
    );
}

const RowDiv = styled.div`
  display: flex;
  flex : 1;
  justify-content: space-between;
`

const CancelButton = styled(Button)`
  width: 120px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  color : var(--red);
`

const SaveButton = styled(Button)`
  width: 120px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--blue);
  color : var(--white);
  margin-left: 8px;
`

const ContinuousButton = styled(SaveButton)`

`
const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 52px;
    justify-content: flex-end;
`
const ProductSearchBar = styled(RowDiv)`
  margin-top: 16px;
`

const SelectBox = styled(Select).attrs({
    width: "352px",
    height: "48px",
})``

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const ProductInput = styled(ItemInput)`
 margin-left: 0px;
`
const RemainBoard = styled.div`
  display: flex;
  flex-direction: row;
  flex : 1;
  width: 712px;
  margin-top : 16px;
  margin-bottom: 16px;
`

const RemainSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const RemainBox = styled(ItemBoxDiv)`
  height: 128px;
  margin-left: 8px;
  padding-bottom: 66px;
`

export const Memo = styled.textarea`
  box-sizing: border-box;
  width: 220px;
  height: 112px;
  margin: 8px 0 8px 8px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  resize : none;
  padding : 10px;
  caret-color: var(--blue);
  
  &:focus{
    outline: none !important;
    border-width: 1px;
    border-color: var(--blue);
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: var(--gray-4);
  }
  :-ms-input-placeholder {
    color: var(--gray-4);
  }
  
`
const SearchProductDiv = styled.div`
  flex-direction: column;
  margin-top : 16px;
`

export default PurchaseRegistDetailScreen