import React, {Component, ReactNode} from 'react';
import styled from "styled-components";

interface DefaultOuterDivProps {
    children: ReactNode
}

function DefaultOuterDiv(props: DefaultOuterDivProps) {
    return (
        <OuterDiv>
            {props.children}
        </OuterDiv>
    );
}

export default DefaultOuterDiv

const OuterDiv = styled.div`
  display: flex;
  flex : 10;
  justify-content: center;
  align-items: center;
`
