import React from 'react';
import styled from 'styled-components';
import BasicNavButton from "./BasicNavButton";
import {Link} from 'react-router-dom';
import {
    LINK_BASIC_EMPLOYEE,
    LINK_BASIC_ETC_PRODUCT,
    LINK_BASIC_GAS_PRODUCT, LINK_BASIC_GROUP,
    LINK_BASIC_VOLUME_PRICE
} from "../../../resources/LinkConstants";

export enum BasicType {
    GAS_PRODUCT,
    VOLUME_PRICE,
    ETC_PRODUCT,
    EMPLOYEE,
    GROUP,
}

interface BasicNavComponentComponentProps {
    type: BasicType
}

function BasicNavComponent(props: BasicNavComponentComponentProps) {

    return (
        <Contents>
            <ButtonsDiv>
                <Link to={LINK_BASIC_GAS_PRODUCT} style={{textDecoration: 'none'}}>
                    <BasicNavButton isSelected={props.type == BasicType.GAS_PRODUCT}>
                        가스품목
                    </BasicNavButton>
                </Link>

                <Link to={LINK_BASIC_VOLUME_PRICE} style={{textDecoration: 'none'}}>
                    <BasicNavButton isSelected={props.type == BasicType.VOLUME_PRICE}>
                        체적
                    </BasicNavButton>
                </Link>

                <Link to={LINK_BASIC_ETC_PRODUCT} style={{textDecoration: 'none'}}>
                    <BasicNavButton isSelected={props.type == BasicType.ETC_PRODUCT}>
                        기타품목
                    </BasicNavButton>
                </Link>

                <Link to={LINK_BASIC_EMPLOYEE} style={{textDecoration: 'none'}}>
                    <BasicNavButton isSelected={props.type == BasicType.EMPLOYEE}>
                        직원
                    </BasicNavButton>
                </Link>

                <Link to={LINK_BASIC_GROUP} style={{textDecoration: 'none'}}>
                    <BasicNavButton isSelected={props.type == BasicType.GROUP}>
                        거래처 그룹
                    </BasicNavButton>
                </Link>
            </ButtonsDiv>
        </Contents>
    );
}

const Contents = styled.div`
  margin-bottom: 24px;
`
const ButtonsDiv = styled.div`
  display: flex;
`

export default BasicNavComponent