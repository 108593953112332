import React, {useState} from 'react';
import ErpTableComponent from "../../../../components/table/ErpTable";
import {useHistory} from "react-router-dom";
import {GridApi} from "ag-grid-community";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";

interface TableExampleGridComponentProps {
    data? : Array<any>
    onClick : (uid : string)=> void
}


function TankRetestDetailListTable(props: TableExampleGridComponentProps) {

    function onCellClicked(uid: string) {
        props.onClick(uid)
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                onCellClicked={onCellClicked}
                rowData={props.data}
                columns={[
                    {
                        id: "index",
                        title: "순번",
                        accessor: "",
                        sortable: true,
                        width: 56,
                        autoIncrement: true
                    },{
                        id: "applicationDate",
                        title: "검사예정년",
                        accessor: "applicationDate",
                        sortable: true,
                        clickable : true,
                        width: 116,

                    }, {
                        id: "completionRequestDate",
                        title: "제조번호",
                        accessor: "completionRequestDate",
                        width: 160,
                    }, {
                        id: "CompletionDate",
                        title: "제조사",
                        accessor: "CompletionDate",
                        width: 114,
                    }, {
                        id: "Quantity",
                        title: "용량",
                        accessor: "Quantity",
                        width: 98,
                    }, {
                        id: "Progress",
                        title: "자가여부",
                        accessor: "Progress",
                        width: 98,
                    }, {
                        id: "Progress",
                        title: "판매처명",
                        accessor: "Progress",
                        width: 116,
                    }, {
                        id: "Progress",
                        title: "주소",
                        accessor: "Progress",
                        width: 256,
                    },{
                        id: "selected",
                        title: "",
                        accessor: "selected",
                        width: 66,
                        checked: true,
                    }
                ]}/>
        </>

    )
}

export default TankRetestDetailListTable
