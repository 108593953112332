import React, {useState} from 'react';
import styled from 'styled-components';
import Button from "../style/button/Button";
import BaseModal from "../modal/BaseModal";
import Common from "./component/Common";

interface ModifyButtonComponentProps {
    height: string
    width: string
    description?: string
    onConfirm?: () => void
}

function ModifyButton(props: ModifyButtonComponentProps) {

    const [open, setOpen] = useState(false)

    function onUpdate() {
        if(props.onConfirm){
            props.onConfirm()
        }

        setOpen(false)
    }

    return (
        <div>
            <StyledModifyButton
                width={props.width}
                height={props.height}
                onClick={() => setOpen(true)}
            >
                수정
            </StyledModifyButton>

            <BaseModal showModal={open} onCancel={() => setOpen(false)}>
                <Common
                    description={props.description}
                    subtitle={"해당 품목을 수정하시겠습니까"}
                    setOpen={() => setOpen(false)}
                    onClick={onUpdate}
                />
            </BaseModal>
        </div>
    );
}

const StyledModifyButton = styled(Button)`
  width: ${(props: ModifyButtonComponentProps) => props.width};
  height: ${(props: ModifyButtonComponentProps) => props.height};
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
`

export default ModifyButton