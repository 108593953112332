import React, {useEffect} from 'react';
import BasicNavComponent, {BasicType} from "../BasicNavComponent";
import EmployeeTable from "./EmployeeTable";
import styled from "styled-components";
import BasicTopTitle from "../BasicTopTitle";
import Button from "../../../../components/style/button/Button";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../../../redux/CompassModule";
import {MethodState, ProductMethodType} from "../GasProduct/GasProductContainer";
import TopButton from "../../../../components/style/button/TopButton";
import {Div} from "../GasProduct/GasProductScreen";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";

interface GasProductScreenComponentProps {
    openModal: (type: ProductMethodType) => void
}

function EmployeeScreen(props: GasProductScreenComponentProps) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeTitle({
            title: {
                first_depth: "기초데이터 홈"
            }
        }))
    })

    return (
        <>
            <BasicTopTitle openModal={props.openModal}/>
            <BasicNavComponent type={BasicType.EMPLOYEE}/>
            <SubtitleDiv>
                <SubText>
                    직원
                </SubText>
                <AccountIdText>
                    회원번호 : TBD
                </AccountIdText>
            </SubtitleDiv>
            <EmployeeTable onCellClicked={(uid) => props.openModal({
                type: MethodState.UPDATE,
                payload: uid
            })}
            />

            <Div>
                <TopButton/>
            </Div>
        </>
    );
}

export const SubtitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top : 24px;
  margin-bottom : 12px;
`

export const SubText = styled(FixedWeightText)`
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
`

const AccountIdText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: normal;
`

export default EmployeeScreen