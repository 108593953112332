import React, {useEffect} from 'react';
import PurchaseFooterScreen from "./PurchaseFooterScreen";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../redux/CompassModule";
import PurchaseHeaderScreen from "./PurchaseHeaderScreen";
import PurchaseListScreen from "./PurchaseListScreen";
import {Div} from "../basic/component/GasProduct/GasProductScreen";
import TopButton from "../../components/style/button/TopButton";
import {useAsync} from "../../hooks/useAsync";
import {getPurchaseList, PurchaseList} from "../../api/purchase/getPurchaseList";
import {getPurchaseDetailList} from "../../api/purchase/getPurchaseDetailList";
import {deletePurchase} from "../../api/purchase/deletePurchase";
import {useLocation} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";

interface PurchaseContainerProps {

}

type PurchaseSearchRequest = {
    startDate: string,
    endDate: string,
    searchType: number,
    searchQuery: string,
}

function PurchaseContainer(props: PurchaseContainerProps) {

    const dispatch = useDispatch()

    dispatch(changeTitle({
        title: {
            first_depth: "입고입력 홈",
        }
    }))

    const methods = useForm<PurchaseSearchRequest>({
        mode: "onBlur",
        criteriaMode: 'all'
    })

    const {execute: getList, data: listData} = useAsync(getPurchaseList, true, undefined, (data : PurchaseList) => {
        if(data.purchaseList.length > 0) {
            getDetailList(data.purchaseList[0].uid)
        }
    })
    const {execute: getDetailList, data: detailListData} = useAsync(getPurchaseDetailList)
    const {execute: removePurchase, status} = useAsync(deletePurchase)

    const location = useLocation()
    const params = new URLSearchParams(location.search)


    useEffect(() => {
        if(location.search !== "") {
            getList(params.get("startDate"), params.get("endDate"), params.get("searchType"), params.get("searchQuery"))
        }
    },[location.search])

    return (
        <FormProvider {...methods}>
            <form>
            <PurchaseHeaderScreen
                onClickSearchButton={getList}
            />
            <PurchaseListScreen
                listData={listData}
                detailListData={detailListData}
                getDetail={getDetailList}
                deletePurchase={removePurchase}
            />
            <PurchaseFooterScreen
                data={listData?.purchaseSumList}
            />
            <Div>
                <TopButton/>
            </Div>
            </form>
        </FormProvider>
    );
}

export default PurchaseContainer