import React from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import {PurchaseDetail} from "../../../api/purchase/getPurchaseDetailList";

interface DetailListTableComponentProps {
    isEditable?: boolean,
    data?: Array<PurchaseDetail>
}

function DetailListTable(props: DetailListTableComponentProps) {

    return (
        <ErpTableComponent
            tableWidth={712}
            tableHeight={216}
            rowHeight={36}
            rowData={props.data}
            stayScrollEnd={true}
            columns={[
               {
                    id: "code",
                    title: "품목\n코드",
                    accessor: "code",
                    sortable: true,
                    width: 59.2,
                }, {
                    id: "title",
                    title: "품목명",
                    accessor: "title",
                    sortable: true,
                    width: 120,
                }, {
                    id: "type",
                    title: "구분",
                    accessor: "type",
                    sortable: true,
                    width: 59.2,
                }, {
                    id: "standard",
                    title: "규격",
                    accessor: "standard",
                    sortable: true,
                    width: 59.2,
                }, {
                    id: "purchaseCount",
                    title: "입고",
                    accessor: "purchaseCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }, {
                    id: "sellCount",
                    title: "출고",
                    accessor: "sellCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }, {
                    id: "containerPurchaseCount",
                    title: "입고\n(미검)",
                    accessor: "containerPurchaseCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }, {
                    id: "containerSellCount",
                    title: "출고\n(미검)",
                    accessor: "containerSellCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }, {
                    id: "stationPurchaseCount",
                    title: "보관\n(충전소)",
                    accessor: "stationPurchaseCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }, {
                    id: "stationSellCount",
                    title: "회수\n(충전소)",
                    accessor: "stationSellCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }, {
                    id: "disposalCount",
                    title: "폐기",
                    accessor: "disposalCount",
                    sortable: true,
                    width: 59.2,
                    editable: props.isEditable
                }]
            }
            onCellClicked={() => {
            }}
        />
    );
}

export default DetailListTable