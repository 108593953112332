import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function gasProductRegistrationForm() {
    const response = await axios.get<BaseResponse<GasProductRegistrationFormResponse>>("/basic/gas_product/form")

    return response.data
}

export type GasProductRegistrationFormResponse = {
    gasProductType: Array<GasProductType>,
}

export type GasProductType = {
    id: number,
    title: string
}