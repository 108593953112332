import React from 'react'
import styled from "styled-components"
import DefaultWrapperDiv from "../../components/style/div/DefaultWrapperDiv"
import DefaultOuterDiv from "../../components/style/div/DefaultOuterDiv"
import {useForm} from "react-hook-form"
import {SignInRequest} from "../../api/signIn"
import FixedWeightText from "../../components/style/text/FixedWeightText"
import ProgressButton from "../../components/style/button/ProgressButton";
import Input from "../../components/style/input/Input";

interface LoginComponentProps {
    onConfirmClick: (request: SignInRequest) => (void)
    loading: boolean
}

function LoginScreen(props: LoginComponentProps) {
    const {register, handleSubmit, formState} = useForm<SignInRequest>(
        {
            mode: "onChange"
        }
    )

    const onSubmit = (data: SignInRequest) => {
        props.onConfirmClick(data)
    }

    const {isValid} = formState

    return (
        <DefaultOuterDiv>
            <DefaultWrapperDiv justifyContent={"center"} alignItems={"center"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <HeaderText>
                        가스경영프로그램
                    </HeaderText>

                    <ContentBorder>
                        <Title>로그인</Title>

                        <SubTitle>아이디</SubTitle>

                        <EditText
                            name="userId"
                            ref={register({required: true, minLength: 1})}
                            placeholder={"아이디"}/>

                        <SubTitle fontSize={"14px"}>비밀번호</SubTitle>

                        <EditText
                            name="password"
                            ref={register({required: true, minLength: 1})}
                            placeholder={"비밀번호(4~16자)"}
                            type={"password"}/>

                        <NextButton
                            disabled={!isValid}
                            type={"submit"}
                            isLoading={props.loading}>
                            <NextButtonText>다음</NextButtonText>
                        </NextButton>
                    </ContentBorder>
                </form>

                <BottomLink>
                    <BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>아이디 찾기</BottomItemText>
                    <BottomBar/>
                    <BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>비밀번호 찾기</BottomItemText>
                    <BottomBar/>
                    <BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>회원가입</BottomItemText>
                </BottomLink>
            </DefaultWrapperDiv>
        </DefaultOuterDiv>
    );
}

const ContentBorder = styled.div`
      display: flex;
      flex-direction: column;
      width: (484-64)px;
      height: (388-64)px;
      padding : 32px;
      border-radius: 4px;
      border: solid 1px var(--gray-4);
      background-color: white;
      margin-top: 52px;
`

const EditText = styled(Input)`
    width: 420px;
    height: 48px;
    font-size: 14px;
    padding: 14px 16px 14px 16px;
    align-self: center;
`
const NextButtonText = styled(FixedWeightText).attrs({
    fontWeight: 600
})`
  margin-right: 50px;
  color: var(--white);
`
const NextButton = styled(ProgressButton)`
  margin-top: 24px;
  width: 100%;
  height: 64px;
`

const HeaderText = styled(FixedWeightText)`
  display: flex;
  flex : 1;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  font-size: 36px;
  font-weight: 500;
`

const Title = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  font-size: 36px;
  color: var(--black);
`

const SubTitle = styled(FixedWeightText)`
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 8px;
`

const BottomLink = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;
  flex-direction: row;
`

const BottomItemText = styled(FixedWeightText)`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-3);
`
const BottomBar = styled.div`
  width: 2px;
  height: 16px;
  margin: 2px 16px;
  background-color: var(--gray-3);
`
export default LoginScreen