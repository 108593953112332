import React, {useState} from 'react';
import VolumeScreen from "./VolumeScreen";
import VolumeModalContainer from "./VolumeModalContainer";
import {useAsync} from "../../../../hooks/useAsync";
import {getVolumeList} from "../../../../api/basic/volume/getVolumeList";

interface VolumeContainerComponentProps {
}

function VolumeContainer(props: VolumeContainerComponentProps) {
    const {data: volumeList} = useAsync(getVolumeList, true)
    const [showModal, setShowModal] = useState(false)

    function onOpenModal() {
        setShowModal(true)
    }

    function onCloseModal() {
        setShowModal(false)
    }

    return (
        <div>
            <VolumeScreen openModal={onOpenModal} data={volumeList}/>
            <VolumeModalContainer showModal={showModal} onCloseModal={onCloseModal} data={volumeList}/>
        </div>
    );
}

export default VolumeContainer