import React from 'react';

import {useAsync} from "../../hooks/useAsync";
import {useParams} from "react-router";
import TankScreen from "./TankScreen";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../redux/CompassModule";

interface Props {
}

interface MatchType {

}

function TankContainer(props: Props) {

    const dispatch = useDispatch()

    dispatch(changeTitle({
        title: {
            first_depth: "탱크관리홈",
        }
    }))


    // const {data, error: formError, isLoading: formLoading} = useAsync(getPurchaseAccountList,true)

    return (
        <TankScreen />
    );
}

export default TankContainer