import React, {useEffect} from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import styled from "styled-components";
import {GasProductList} from "../../../api/basic/gasproduct/getGasProductList";
import {PurchaseDetail} from "../../../api/purchase/getPurchaseDetailList";

interface ProductListTableComponentProps {
    onProductListClick : (data : PurchaseDetail) => void
    data? : Array<GasProductList>
}


function ProductListTable(props: ProductListTableComponentProps) {

    function onClick(data : GasProductList){
        if(data){
            const newData : PurchaseDetail = {
                productId : data.uid,
                code : data.code,
                title : data.title,
                type : data.gasProductType,
                weight : data.weight,
                standard : data.standard,
                purchaseCount: 0,
                sellCount: 0,
                containerPurchaseCount: 0,
                containerSellCount: 0,
                stationPurchaseCount: 0,
                stationSellCount: 0,
                disposalCount: 0,
                currentTimestamp : Date.now()
            }

            props.onProductListClick(newData)
        }
    }

    function activeCellRenderer(data : any) {
        return <SelectButton onClick={()=>onClick(data.data)}>적용</SelectButton>
    }

    return (
        <ErpTableComponent
            tableWidth={352}
            tableHeight={656}
            onCellClicked={() => {}}
            rowData={props.data}
            columns={[
                {
                    id: "index",
                    title: "순번",
                    accessor: "",
                    sortable: true,
                    width: 68,
                    autoIncrement: true
                }, {
                    id: "code",
                    title: "품목코드",
                    accessor: "code",
                    sortable: true,
                    width: 108,
                }, {
                    id: "title",
                    title: "품목명",
                    accessor: "title",
                    sortable: true,
                    width: 108,
                }, {
                    id: "uid",
                    title: "적용",
                    accessor: "uid",
                    sortable: true,
                    width: 68,
                    cellRendererFramework : activeCellRenderer
                },]
            }
        />
    );
}


const SelectButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
`

export default ProductListTable