import React, {useEffect} from 'react';

interface MeterCheckScreenComponentProps {

}


function MeterCheckScreen(props: MeterCheckScreenComponentProps) {

    return (
        <div>
            검침 화면
        </div>
    );
}

export default MeterCheckScreen