import React from 'react';
import styled from "styled-components";
import FixedWeightText from "../text/FixedWeightText";
import {BaseFormType} from "../../form/BaseFormType";
import Input from "../input/Input";
import {PlaceholderInput} from "../input/PlaceholderInput";
import BoxText from "../text/BoxText";

type ItemBoxComponentProps = BaseFormType & {
    width?: string
    height?: string
    title: string
    title2?: string
    subTitle?: string
    placeholder?: string
    readOnly?: boolean
    value?: string | number
    reference? : any
    error? : any
}

function ItemBox (props : ItemBoxComponentProps){
    return (
        <IBDiv>
            <Box>
                <BoxText {...props}/>
                {props.subTitle && <SubText>{props.subTitle}</SubText>}
            </Box>
            <StyledInput {...props} ref={props.reference}/>
        </IBDiv>
    );
}

const IBDiv = styled.div`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  width : 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
`

const Text = styled(FixedWeightText)`
  font-size : 14px;
  font-weight: 400;
`

const SubText = styled(Text)`
  font-size : 2px;
  margin-top: 3px;
`

const StyledInput = styled(Input)`
  display: flex;
  flex : 1;
  width : 100%;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const StyledPlaceholderInput = styled(PlaceholderInput)`
  display: flex;
  flex : 1;
  width : 100%;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`
export const Box = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 120px;
  height: 64px;
  padding-left: 16px;
  padding-right: 20px;
  background-color: var(--pale-grey);
  align-items: center;
`

export default ItemBox;