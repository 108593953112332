import React, {useEffect, useState} from 'react';
import TankCertificateRegistrationScreen from "./TankCertificateRegistrationScreen";
import {postCertificate, TankCertificate} from "../../../../api/tank/certification/postCertificate";
import {getCertificateDetailForm} from "../../../../api/tank/certification/getCertificateDetailForm";
import {getCertificate} from "../../../../api/tank/certification/getCertificate";
import {useParams} from "react-router-dom";
import {MatchType} from "../../../../api/MatchType";
import {putCertificate} from "../../../../api/tank/certification/putCertificate";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../../components/style/button/CircularIndeterminate";
import {postOCR} from "../../../../api/tank/certification/postOCR";
import {deleteCertificate} from "../../../../api/tank/certification/deleteCertificate";
import {useAsync} from "../../../../hooks/useAsync";

interface TankCertificationContainerComponentProps {
}


function TankCertificateRegistrationContainer(props: TankCertificationContainerComponentProps) {

    const {uid} = useParams<MatchType>()

    const {execute: post, status: postStatus} = useAsync(postCertificate)

    const {data: form} = useAsync(getCertificateDetailForm, true)
    const {data, execute: ocr, status: ocrStatus} = useAsync(postOCR)
    const {execute: update, status: updateStatus} = useAsync(putCertificate)
    const {execute: deleteTank, status: deleteStatus} = useAsync(deleteCertificate)
    const {data: getCertificateData} = useAsync(getCertificate, uid != undefined, [uid])

    const [ocrData, setOcrData] = useState<Array<TankCertificate>>()

    useEffect(() => {
        setOcrData(data)
    }, [data])


    return (
        <>
            {(ocrStatus == 'loading' || postStatus == 'loading' || updateStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <TankCertificateRegistrationScreen
                post={post}
                put={update}
                delete={deleteTank}
                form={form}
                postOcr={ocr}
                tankCertificateData={ocrData}
                updateData={getCertificateData}
                loading={postStatus == 'success' || updateStatus == 'success' || deleteStatus == 'success'}
            />
        </>
    );
}

export default TankCertificateRegistrationContainer