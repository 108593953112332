import JSZip from "jszip";
import FileSaver from "file-saver";

export type TankInfo = {
    data: string | Blob,
    name: string
}

export async function SingleFileDownload(tankInfo: TankInfo) {
    try {
        const blob: Blob = typeof tankInfo.data == "string" ? await fetch(tankInfo.data).then(res => res.blob()) : tankInfo.data
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style.display = "none";
        a.download = tankInfo.name + ".pdf"
        document.body.append(a);
        a.click();
    } catch {
        console.log("download fail")
    }
}

export function fileDownload(tankInfo: Array<TankInfo>) {

    const zip = new JSZip();
    let count = 0;
    const zipFilename = "저장탱크 합격증.zip";
    tankInfo.forEach(async function (item) {
        try {
            const blob: Blob = typeof item.data == "string" ? await fetch(item.data).then(res => res.blob()) : item.data
            const fileName = item.name.substring(item.name.length - 3, item.name.length) == "pdf" ? item.name : item.name + ".pdf"
            count++;
            zip.file(fileName, blob, {binary: true});

            if (count === tankInfo.length) {
                zip.generateAsync({type: 'blob'}).then(function (content) {
                    FileSaver.saveAs(content, zipFilename);
                });
            }
        } catch (err) {
            console.log(err);
        }
    })
}