import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalHeader from "../../../../components/modal/Header";
import {ItemBoxDiv} from "../../../tank/component/ItemBox";
import {InputDiv, InputText, RadioButton} from "../../../salesaccount/component/RadioButton";
import {Controller, useForm} from "react-hook-form";
import BasicCheckBox from "../../../../components/style/button/BasicCheckBox";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Button from "../../../../components/style/button/Button";
import Input from "../../../../components/style/input/Input";
import {GasProductRegistrationFormResponse} from "../../../../api/basic/gasproduct/gasProductRegistrationForm";
import {GasProductResponse} from "../../../../api/basic/gasproduct/getGasProduct";
import {GasProductRegistRequest} from "../../../../api/basic/gasproduct/gasProductRegistration";
import SaveButton from "../../../../components/button/SaveButton";
import DeleteButton from "../../../../components/button/DeleteButton";
import ModifyButton from "../../../../components/button/ModifyButton";

interface GasProductModalScreenComponentProps {
    data?: GasProductResponse
    form?: GasProductRegistrationFormResponse
    update: (data: GasProductRegistRequest) => void
    save: (data: GasProductRegistRequest) => void
    delete: () => void
    closeModal: () => void
}

function GasProductModalScreen(props: GasProductModalScreenComponentProps) {
    const methods = useForm<GasProductRegistRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    function closeModal() {
        props.closeModal()
        setType(1)
        setSellPriceVAT(false)
        setKgRatioPriceVAT(false)
    }


    const onSubmit = (data: GasProductRegistRequest) => {
        if (props.data) {
            props.update(data)
        } else {
            props.save(data)
        }

        //민주
        // window.location.reload()
        // closeModal()
    }

    function onDelete() {
        props.delete()
        window.location.reload()
        closeModal()
    }

    const [type, setType] = useState(1)
    const [sellPriceVAT, setSellPriceVAT] = useState(false)
    const [kgRatioPriceVAT, setKgRatioPriceVAT] = useState(false)
    const [confirm, setConfirm] = useState(false)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSellPriceVAT(event.target.checked)
        methods.setValue("isPriceTaxInclude", event.target.checked)
    }

    function handleChangeKgRatio(event: React.ChangeEvent<HTMLInputElement>) {
        setKgRatioPriceVAT(event.target.checked)
        methods.setValue("isPricePerKgTaxInclude", event.target.checked)
    }

    useEffect(() => {
        if (props.data) {
            methods.setValue("code", props.data.code)
            methods.setValue("title", props.data.title)
            setType(props.data.gasProductType)
            methods.setValue("standard", props.data.standard)
            methods.setValue("weight", props.data.weight)
            methods.setValue("price", props.data.price)
            setSellPriceVAT(props.data.isPriceTaxInclude)
            methods.setValue("isPriceTaxInclude", props.data.isPriceTaxInclude)
            methods.setValue("pricePerKg", props.data.pricePerKg)
            setKgRatioPriceVAT(props.data.isPricePerKgTaxInclude)
            methods.setValue("isPricePerKgTaxInclude", props.data.isPricePerKgTaxInclude)
            methods.setValue("purchasePrice", props.data.purchasePrice)
        }
    }, [props.data])

    function onConfirm() {
        methods.handleSubmit(onSubmit)

    }

    return (
        <form>
            <ModalDiv>
                <ModalHeader title={"가스 품목 단가 등록"} onCancel={closeModal}/>
                <ContentDiv>
                    <HeaderDiv>
                        <HeaderText>
                            가스 품목 등록
                        </HeaderText>
                    </HeaderDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            코드
                        </ItemBoxDiv>
                        <ItemInput
                            name={"code"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            품목명
                        </ItemBoxDiv>
                        <ItemInput
                            name={"title"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            구분
                        </ItemBoxDiv>
                        {props.form?.gasProductType.map((item) =>
                            <InputDiv>
                                <RadioButton name={"gasProductType"}
                                             type={"radio"}
                                             value={type}
                                             checked={item.id == type}
                                             onClick={() => setType(item.id)}
                                             ref={methods.register({
                                                 required: true,
                                             })}
                                />
                                <InputText>{item.title}</InputText>
                            </InputDiv>
                        )}
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            규격
                        </ItemBoxDiv>
                        <ItemInput name={"standard"} ref={methods.register}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            KG
                        </ItemBoxDiv>
                        <ItemInput name={"weight"} ref={methods.register}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            판매가
                        </ItemBoxDiv>
                        <ItemInput name={"price"} ref={methods.register}/>

                        <Controller
                            as={
                                <BasicCheckBox
                                    handleChange={handleChange}
                                    checked={sellPriceVAT}
                                />
                            }
                            control={methods.control}
                            defaultValue={sellPriceVAT}
                            name={"isPriceTaxInclude"}
                        />

                        <CheckBoxText
                            color={sellPriceVAT ? "var(--black)" : "var(--gray-4)"}
                        >
                            VAT 포함
                        </CheckBoxText>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            Kg당 구매가
                        </ItemBoxDiv>
                        <ItemInput name={"pricePerKg"} ref={methods.register}/>

                        <Controller
                            as={
                                <BasicCheckBox
                                    handleChange={handleChangeKgRatio}
                                    checked={kgRatioPriceVAT}
                                />
                            }
                            control={methods.control}
                            defaultValue={kgRatioPriceVAT}
                            name={"isPricePerKgTaxInclude"}
                        />
                        <CheckBoxText
                            color={kgRatioPriceVAT ? "var(--black)" : "var(--gray-4)"}
                        >
                            VAT 포함
                        </CheckBoxText>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            구매가
                        </ItemBoxDiv>
                        <ItemInput name={"purchasePrice"} ref={methods.register}/>
                    </ItemDiv>

                    <FooterDiv>
                        <OtherButton onClick={closeModal}>
                            <OtherText fontWeight={500}>취소</OtherText>
                        </OtherButton>

                        {props.data && (
                            <DeleteButton
                                height={"48px"}
                                width={"120px"}
                                onConfirm={onDelete}
                                description={"가스 품목 삭제"}
                            />
                        )}

                        {props.data ?
                            <ModifyButton
                                height={"48px"}
                                width={"120px"}
                                onConfirm={methods.handleSubmit(onSubmit)}
                                description={"가스 품목 등록"}
                            />
                            :
                            <SaveButton
                                height={"48px"}
                                width={"120px"}
                                onConfirm={methods.handleSubmit(onSubmit)}
                                description={"가스 품목 등록"}
                            />
                        }

                    </FooterDiv>
                </ContentDiv>
            </ModalDiv>
        </form>
    );
}

const ModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 720px;
    height: 772px;
    border-radius: 4px;
    background-color: var(--white);
`

const ContentDiv = styled.div`
  box-sizing: border-box;
  width: 720px;
  height: 724px;
  padding : 22px 32px 32px 32px;
`

const HeaderDiv = styled.div`
  height: 60px;
`
const HeaderText = styled(FixedWeightText)`
  font-size : 24px;
  font-weight: 500;
`

const GasConfirmButton = styled(Button)`
  width: 120px;
  height: 48px;
  margin-left: 8px;
  background-color: var(--blue);
`

const OtherButton = styled(GasConfirmButton)`
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`

const ConfirmText = styled(FixedWeightText)`
  width: 46px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  color: var(--white);
`

const OtherText = styled(ConfirmText)`
  color : var(--red);
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const CheckBoxText = styled(FixedWeightText)`
  width: 54px;
  height: 20px;
  font-size : 14px;
  font-weight : 400;
  line-height: 1.43;
`

export default GasProductModalScreen