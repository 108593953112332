import React from 'react';
import Compass from "../../components/style/div/Compass";
import Divider from "../../components/style/div/Divider";

interface SalesScreenComponentProps {

}

function SalesScreen(props: SalesScreenComponentProps) {
    return (
        <>
            <Compass first_depth={"판매입력 관리 홈"} first_depth_width={198}/>

            <Divider/>
            판매입력
        </>
    );
}

export default SalesScreen