import React, {useEffect, useState} from 'react';
import {HeaderDiv} from "../../purchaseaccount/PurchaseAccountScreen";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {ItemBoxDiv} from "../../tank/component/ItemBox";
import Moment from "moment";
import styled from "styled-components";
import Input from "../../../components/style/input/Input";
import Button from "../../../components/style/button/Button";
import Datepicker from "../../../components/style/datepicker/Datepicker";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import AccountSearchModal from "../../../components/modal/AccountSearchModal";
import {PurchaseAccountList} from "../../../api/purchase/account/getPurchaseAccountList";

interface PurchaseRegistHeaderProps {
    accountList?: Array<PurchaseAccountList>
    setAccountUid : (uid : string) => void
    date? : string
    accountUid? : string,
}


function PurchaseRegistHeaderScreen(props: PurchaseRegistHeaderProps) {

    const [showModal, setShowModal] = useState(false)

    const [account, setAccount] = useState<PurchaseAccountList>()

    const {register, setValue} = useFormContext()

    useEffect(()=>{
        console.log(props.accountUid)

        if(account && props.setAccountUid){
            setValue("accountName", account.accountName)
            props.setAccountUid(account.uid)
        }else{
            props.accountList?.map((item) => {
                if(item.uid == props.accountUid){
                    setValue("accountName", item.accountName)
                }
            })
        }

        if(props.date){
            setValue("date", props.date)
        }

    }, [props.accountUid, props.date, account])

    return (
        <>
            <HeaderScreen>
                <HeaderDiv>
                    <FixedWeightText fontSize={"24px"} fontWeight={500}>
                        매입등록
                    </FixedWeightText>
                </HeaderDiv>

                <Row>
                    <ItemDiv>
                        <ItemBoxDiv>
                            일자
                        </ItemBoxDiv>
                        <HalfDatePicker defaultValue={Moment().format('YYYY-MM-DD')} name={"date"} reference={register}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            매입거래처명
                        </ItemBoxDiv>
                        <ItemInput onClick={() => setShowModal(true)}
                                   readOnly={true}
                                   ref={register}
                                   name={"accountName"}/>
                        <SearchButton onClick={() => setShowModal(true)}>검색</SearchButton>
                    </ItemDiv>

                </Row>
            </HeaderScreen>
            <AccountSearchModal
                showModal={showModal}
                onCancel={() => setShowModal(false)}
                setAccount={setAccount}
                data={props.accountList}
            />
        </>
    );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const HeaderScreen = styled.div`
  margin-bottom: 32px;
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

export const SearchButton = styled(Button)`
  width: 80px;
  height: 48px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
`

const HalfDatePicker = styled(Datepicker)`
  width: 404px;
  height: 48px;
`

export default PurchaseRegistHeaderScreen