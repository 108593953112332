import axios from 'axios';
import {BaseResponse} from "./BaseResponse";
import {getLocalStorageJsonItemOrNull} from "../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_TOKEN} from "../resources/LocalStorageConstants";

export async function refreshToken() {
    const erpToken = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_TOKEN)
    const response = await axios.get<BaseResponse<ErpToken>>(`/token/refresh`, {
        headers: {
            'Authorization': `Bearer ${erpToken.refreshToken}`
        }
    })
    return response.data
}

export interface ErpToken {
    accessToken: string,
    refreshToken: string
}