import React from 'react';
import ErpTableComponent from "../../../../components/table/ErpTable";
import {VolumePriceValue} from "../../../../api/basic/volume/getVolumeList";

interface TableExampleGridComponentProps {
    data? : Array<VolumePriceValue>
}

function VolumeTable(props: TableExampleGridComponentProps) {


    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={380}
                rowData={props.data}
                onCellClicked={()=> {}}
                columns={[
                    {
                        id: "title",
                        title: "조정기 압력",
                        accessor: "title",
                        sortable: true,
                        width: 200,
                    }, {
                        id: "value",
                        title: "mmH2O당 단가",
                        accessor: "value",
                        sortable: true,
                        width: 200,
                    }, {
                        id: "factor",
                        title: "압력 보정계수",
                        accessor: "factor",
                        width : 200,
                        sortable: true,
                    },
                    {
                        id: "space",
                        title: " ",
                        accessor: "space",
                        width : 700,
                    }
                ]}/>
        </>

    )
}

export default VolumeTable