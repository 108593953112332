import axios from 'axios';
import {BaseResponse} from "./BaseResponse";

export async function signIn(signInRequest : SignInRequest) {
    const response = await axios.post<BaseResponse<ErpUser>>(`/signIn`, signInRequest)
    return response.data
}

interface ErpToken {
    accessToken : string,
    refreshToken : string
}

export interface SignInRequest {
    userId: string,
    password: string
}

export interface ErpUser {
    companyName: string
    token: ErpToken
}