import React from 'react';
import styled from 'styled-components';

interface DividerComponentProps {

}

function Divider (props : DividerComponentProps){
    return (
        <DividerDiv/>
    );
}

export const DividerDiv = styled.div`
  display: flex;
  width: 100%;
  height: 4px;
  background-color: var(--pale-grey);
`

export default Divider