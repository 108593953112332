import React from 'react';
import ErpTableComponent from "../../components/table/ErpTable";
import styled from "styled-components";
import {PurchaseSum} from "../../api/purchase/getPurchaseList";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";

interface PurchaseScreenComponentProps {
    data? : Array<PurchaseSum>
}

function PurchaseFooterScreen(props: PurchaseScreenComponentProps) {

    return (
        <>
            <MatchHorizontalSpace height={"1px"}/>
            <SubTitle>
                기간합계 2021.03.01 ~ 2021.03.30
            </SubTitle>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={180}
                rowData={props.data}
                columns={[
                    {
                        id: "type",
                        title: "구분",
                        accessor: "type",
                        sortable: true,
                        width: 216,
                    },
                    {
                        id: "totalPurchaseAmount",
                        title: "총입고량",
                        accessor: "totalPurchaseAmount",
                        sortable: true,
                        width: 216,
                    },
                    {
                        id: "totalRemainAmount",
                        title: "잔량",
                        accessor: "totalRemainAmount",
                        sortable: true,
                        width: 216,
                    },
                    {
                        id: "totalChargeAmount",
                        title: "충전량",
                        accessor: "totalChargeAmount",
                        sortable: true,
                        width: 216,
                    },
                    {
                        id: "totalPrice",
                        title: "합계금액(VAT포함)",
                        accessor: "totalPrice",
                        sortable: true,
                        width: 216,
                    },]
                } onCellClicked={() => {
            }}/>
        </>
    );
}

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 1080px;
  height: 80px;
`

export default PurchaseFooterScreen