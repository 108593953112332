import React, {useState} from 'react';
import styled from 'styled-components';
import Button from "../style/button/Button";
import BaseModal from "../modal/BaseModal";
import Common from "./component/Common";

interface SaveButtonComponentProps {
    height: string
    width: string
    description?: string
    onConfirm?: () => void
}

function SaveButton(props: SaveButtonComponentProps) {

    const [open, setOpen] = useState(false)

    function onSave() {
        props.onConfirm?.()
        setOpen(false)
    }

    return (
        <div>
            <StyledSaveButton
                width={props.width}
                height={props.height}
                onClick={() => setOpen(true)}
            >
                등록
            </StyledSaveButton>

            <BaseModal showModal={open} onCancel={() => setOpen(false)}>
                <Common
                    description={props.description}
                    subtitle={"해당 품목을 등록하시겠습니까"}
                    setOpen={() => setOpen(false)}
                    onClick={onSave}
                />
            </BaseModal>
        </div>
    );
}

const StyledSaveButton = styled(Button)`
  width: ${(props: SaveButtonComponentProps) => props.width};
  height: ${(props: SaveButtonComponentProps) => props.height};
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
`

export default SaveButton