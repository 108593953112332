import React, {useEffect, useState} from 'react';
import BaseModal from "../../../../components/modal/BaseModal";
import {useAsync} from "../../../../hooks/useAsync";
import EmployeeModalScreen from "./EmployeeModalScreen";
import {employeeRegistration, EmployeeRegistRequest} from "../../../../api/basic/employee/employeeRegistration";
import {employeeRegistrationForm} from "../../../../api/basic/employee/employeeRegistrationForm";
import {putEmployeeRegistration} from "../../../../api/basic/employee/putEmployeeRegistration";
import {EmployeeResponse, getEmployee} from "../../../../api/basic/employee/getEmployee";
import {deleteEmployee} from "../../../../api/basic/employee/deleteEmployee";
import {MethodState, ProductMethodType} from "../GasProduct/GasProductContainer";
import ConfirmModal from "../../../../components/modal/ConfirmModal";

interface BasicModalProps {
    type?: ProductMethodType
    onCloseModal: () => void
    onOpen?: () => void
}

function EmployeeModalContainer(props: BasicModalProps) {
    const {data: saveResponse, error: saveError, isLoading: saveLoading, execute: save} = useAsync(employeeRegistration)
    const {data: formResponse, error: formError, isLoading: formLoading} = useAsync(employeeRegistrationForm, true)
    const {data: updateResponse, error: updateError, isLoading: updateLoading, execute: update} = useAsync(putEmployeeRegistration)
    const {data: deleteResponse, isLoading: deleteLoading, execute: deleteEmployeeData} = useAsync(deleteEmployee)
    const {data: getResponse, isLoading: getLoading, execute: getEmployeeData, reset : resetEmployeeData} = useAsync(getEmployee)

    const [showModal, setShowModal] = useState(false)

    function closeModal() {
        setShowModal(false)
        resetEmployeeData()
        props.onCloseModal()
    }

    function saveEmployee(request: EmployeeRegistRequest) {
        save(request)
    }

    function updateEmployee(request: EmployeeRegistRequest) {
        if (props.type?.payload) {
            update(props.type?.payload, request)
        }
    }

    useEffect(() => {
        switch (props.type?.type) {
            case MethodState.UPDATE:
                getEmployeeData(props.type?.payload)
            case MethodState.SAVE:
                setShowModal(true)
                break;
        }
    }, [props.type])

    return (
        <ConfirmModal showModal={showModal} onCancel={closeModal}>
            <EmployeeModalScreen
                data={getResponse}
                form={formResponse}
                save={saveEmployee}
                update={updateEmployee}
                closeModal={closeModal}/>
        </ConfirmModal>

    );
}


export default EmployeeModalContainer