import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {signUpAsync} from "../../redux/account/signUpModule";
import {SignUpRequest} from "../../api/signup/signUp";
import SignUpScreen from "./SignUpScreen";
import {useHistory} from "react-router-dom";
import {LINK_TANK} from "../../resources/LinkConstants";
import {useAsync} from "../../hooks/useAsync";
import {getSignUpForm} from "../../api/signup/getSignUpForm";


interface SignUpContainerProps {
}

function SignUpContainer(props: SignUpContainerProps) {

    const {data, loading, error} = useSelector((state: RootState) => state.accountReducers.signUpReducer.signUpResponse)
    const {data : form} = useAsync(getSignUpForm ,true)

    const dispatch = useDispatch()
    const history = useHistory();
    function onConfirm(request: SignUpRequest) {
        dispatch(signUpAsync.request(request))
    }

    useEffect(() => {
        if (data != null) {
            alert("관리자의 승인을 대기해주세요.")
            history.replace(LINK_TANK)
        }
    })

    return (
        <SignUpScreen
            onConfirm={onConfirm}
            loading={loading}
            form={form}
        />
    );
}

export default SignUpContainer