import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import Moment from 'moment';

export async function getPurchaseList(startDate: string = Moment().format("YYYY-MM-01"), endDate: string = Moment().format("YYYY-MM-DD"), searchType: number = 1, searchQuery: string = "") {

    const response = await axios.get<BaseResponse<PurchaseList>>(`/purchase`, {
        params: {
            startDate: startDate,
            endDate: endDate,
            searchType: searchType,
            searchQuery: searchQuery
        }
    })

    return response.data
}

export type PurchaseList ={
    purchaseList : Array<Purchase>
    purchaseSumList : Array<PurchaseSum>
}

export type PurchaseSum = {
    type : string,
    totalPurchaseAmount : number,
    totalRemainAmount : number,
    totalChargeAmount : number,
    totalPrice : number
}

export type Purchase= {
    uid: string,
    date: string,
    butaneRemainAmount: number,
    propaneRemainAmount: number,
    memo: string,
    accountName: string,
    chargePerson: string,
}
