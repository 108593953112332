import React from 'react';
import {
    LINK_BASIC_EMPLOYEE,
    LINK_BASIC_ETC_PRODUCT,
    LINK_BASIC_GAS_PRODUCT,
    LINK_BASIC_VOLUME_PRICE
} from "../../resources/LinkConstants";
import {Route} from "react-router-dom";
import GasProductContainer from "./component/GasProduct/GasProductContainer";
import EtcProductContainer from "./component/EtcProduct/EtcProductContainer";
import EmployeeContainer from "./component/Employee/EmployeeContainer";
import VolumeContainer from "./component/Volume/VolumeContainer";

interface PurchaseAccountRegistContainerProps {
}

interface MatchType {
    uid: string
}

function BasicDataContainer(props: PurchaseAccountRegistContainerProps) {

    return (
        <>
            <Route path={LINK_BASIC_GAS_PRODUCT} component={GasProductContainer}/>
            <Route path={LINK_BASIC_VOLUME_PRICE} component={VolumeContainer}/>
            <Route path={LINK_BASIC_ETC_PRODUCT} component={EtcProductContainer}/>
            <Route path={LINK_BASIC_EMPLOYEE} component={EmployeeContainer}/>
        </>
    );
}

export default BasicDataContainer