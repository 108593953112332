import React from 'react';
import styled from 'styled-components';
import Home from "../../../assets/images/purchase/POI_24_home_solid@3x.png";
import RightArrow from "../../../assets/images/purchase/ic_36_depth@3x.png";
import DefaultImage from "../image/DefaultImage";
import FixedWeightText from "../text/FixedWeightText";
import DefaultWrapperDiv from "./DefaultWrapperDiv";

interface ComponentProps {
    first_depth: string
    first_depth_width: number
    second_depth?: string
    second_depth_width?: number
    visibility?: boolean
}

const defaultProps: ComponentProps = {
    first_depth: "",
    first_depth_width: 0,
    second_depth: undefined,
    second_depth_width: undefined,
    visibility: true
}

function Compass(props: ComponentProps) {
    return (
        <DivWithStyle>
            <OnePixel/>
            {props.visibility &&
            <DefaultWrapperDiv justifyContent={"flex-start"}>
                <CompassDiv>
                    <DefaultImage src={Home} width={"20px"} height={"20px"}/>
                    <CompassTitle>
                        {props.first_depth}
                    </CompassTitle>

                    {props.second_depth &&
                    <DefaultImage src={RightArrow} width={"20px"} height={"20px"} marginLeft={"12px"}/>}

                    <CompassTitle>
                        {props.second_depth}
                    </CompassTitle>
                </CompassDiv>
            </DefaultWrapperDiv>
            }
        </DivWithStyle>
    )
};

Compass.defaultProps = defaultProps;

const DivWithStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height : 53px;
  background-color: var(--gray-6);
`

const OnePixel = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0,0,0,0.08);
`

const CompassDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height : 52px;
`

const CompassTitle = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  font-size: 14px;
  margin-left: 8px;
`


export default Compass;