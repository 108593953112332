import React from 'react';
import TankCertificateLoadModalScreen from "./TankCertificateLoadModalScreen";
import {useAsync} from "../../../../hooks/useAsync";
import {postUploadTankCertificate} from "../../../../api/tank/certification/postUploadTankCertificate";
import {getSharedTankCertificate} from "../../../../api/tank/certification/getSharedTankCertificate";
import {getShareTankCertificateForm} from "../../../../api/tank/certification/getShareTankCertificateForm";

interface TankCertificateLoadModalContainerComponentProps {
    showModal: boolean
    onCloseModal: () => void
    onConfirm: () => void
}

export type TankSearchType = {
    passYear: string
    passMonth: string
    manufacturerNumber: number
    manufacturerType: number
    volumeType : number
}

function TankCertificateLoadModalContainer(props: TankCertificateLoadModalContainerComponentProps) {
    const {execute: post} = useAsync(postUploadTankCertificate)
    const {data: tankData, execute: get, reset} = useAsync(getSharedTankCertificate)
    const {data : form} = useAsync(getShareTankCertificateForm, true)

    function postData() {
        if (tankData === undefined) {
            alert("탱크 정보가 없습니다")
            return
        }

        if (tankData && tankData.uid) {
            post(tankData.uid)
                .then(props.onCloseModal)
            reset()
        }

    }

    function onSubmit(data: TankSearchType) {
        console.log(data)
        const passDate = data.passYear + "-" + data.passMonth + "-" + "01"
        get(passDate, data.manufacturerNumber, data.manufacturerType, data.volumeType).then(result => console.log(result))
    }

    return (
        <div>
            <TankCertificateLoadModalScreen
                data={tankData?.fileUri}
                form={form}
                post={postData}
                onSubmit={onSubmit}
                showModal={props.showModal}
                onCloseModal={props.onCloseModal}
                onConfirm={props.onConfirm}/>
        </div>
    );
}

export default TankCertificateLoadModalContainer