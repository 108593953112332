import React from 'react';
import styled from 'styled-components';
import UpArrow from "../../../assets/images/purchase/common-36-upward@3x.png";
import Button from "./Button";

const TopButton = styled(Button).attrs((props) => ({
    children:
        <div>
            <div>
                <img src={UpArrow} width={"36px"}/>
            </div>
            <div style={{marginTop: "-10px"}}>
                <text style={{color: "var(--white)"}}>위로</text>
            </div>
        </div>,
    onClick: () => window.scrollTo(0, 0)
}))`
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border-width: 0;
  margin-top: 80px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: var(--black);
  justify-self: flex-end;
  align-self: flex-end;
`

export default TopButton