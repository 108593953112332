import {ActionType, createAction, createReducer} from "typesafe-actions";

const CHANGE_TITLE = 'headerTitle/CHANGE_TITLE'

export const changeHeaderTitle = createAction(CHANGE_TITLE)<{
    userName?: string | null
}>()
type ChangeTitleAction = ActionType<typeof changeHeaderTitle>
type ChangeTitleState = {
    userName?: string | null
};

const initialState: ChangeTitleState = {
    userName: undefined
};
export const headerTitleReducer = createReducer<ChangeTitleState, ChangeTitleAction>(initialState)
    .handleAction(changeHeaderTitle, (state, {payload: {userName: value}}) => ({
        ...state,
        userName: value
    }))