import React, {useEffect, useState} from 'react'
import Compass from "../../../components/style/div/Compass"
import Divider from "../../../components/style/div/Divider"
import CancelButton from "../../../components/style/button/CancelButton"
import ConfirmButton from "../../../components/style/button/ConfirmButton"
import TopButton from "../../../components/style/button/TopButton";
import styled from "styled-components";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {LINK_SALES_ACCOUNT} from "../../../resources/LinkConstants";
import {ItemBoxDiv} from "../../tank/component/ItemBox";
import Input from "../../../components/style/input/Input";
import Button from "../../../components/style/button/Button";
import Select, {MenuItem} from "../../../components/style/select/Select";
import {InputDiv, InputText, RadioButton} from "../component/RadioButton";
import {FormProvider, useForm} from "react-hook-form";
import SectionTitle from "../../../components/style/div/SectionTitle";
import PostCodeButton from "../../../components/postcode/PostCodeButton";
import Datepicker from "../../../components/style/datepicker/Datepicker";
import {PlaceholderInput} from "../../../components/style/input/PlaceholderInput";
import {
    SalesAccountRegistrationFormResponse
} from "../../../api/sales/account/salesAccountRegistrationForm";
import {SalesAccountRegistRequest} from "../../../api/sales/account/salesAccountRegistration";
import {selectItem} from "../../../components/form/YesOrNoSelectItem";


interface SalesRegistComponentProps {
    onRegist : (data : SalesAccountRegistRequest) => void
    loading : boolean
    formResponse?: SalesAccountRegistrationFormResponse
}

function SalesAccountRegist(props: SalesRegistComponentProps) {

    const methods = useForm<SalesAccountRegistRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const onSubmit = (data: SalesAccountRegistRequest) => {
        props.onRegist(data)
    }

    const [type, setType] = useState(1)

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Compass first_depth={"판매거래처 관리 홈"} first_depth_width={198} second_depth={"판매거래처 등록"}/>
            <Divider/>
            <HeaderDiv>
                <FixedWeightText fontSize={"24px"} fontWeight={500}>
                    판매거래처 등록
                </FixedWeightText>

                <ButtonsDiv>
                    <CancelButton link={LINK_SALES_ACCOUNT}/>
                    <ConfirmButton onSubmit={onSubmit}/>
                </ButtonsDiv>
            </HeaderDiv>

            <SectionTitle>기본정보</SectionTitle>

            <ContentsDiv>
                <LeftContents>
                    <ItemDiv>
                        <ItemBoxDiv>코드</ItemBoxDiv>
                        <ItemInput
                            name={"code"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>판매거래처명</ItemBoxDiv>
                        <ItemInput name={"salesAccount"} ref={methods.register}/>
                        <StyledButton>
                            중복확인
                        </StyledButton>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>전화번호</ItemBoxDiv>
                        <ItemInput name={"tel"} ref={methods.register}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>핸드폰번호</ItemBoxDiv>
                        <ItemInput name={"phone"} ref={methods.register}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>주소</ItemBoxDiv>
                        <ItemInput name={"address"} ref={methods.register} readOnly={true}/>
                        <StyledButton>
                                검색
                        </StyledButton>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>상세주소</ItemBoxDiv>
                        <ItemInput name={"addressDetail"} ref={methods.register}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>담당사원</ItemBoxDiv>
                        <ItemInput name={"employeeId"} ref={methods.register}/>
                    </ItemDiv>
                </LeftContents>

                <RightContents>
                    <ItemDiv>
                        <ItemBoxDiv>구분</ItemBoxDiv>
                        <FilterSelect
                            menuItems={props.formResponse?.saleType}
                            initialMenuKey={1}
                            reference={methods.register}
                            name={"sellTypeId"}
                        />
                        <FilterSelect
                            menuItems={props.formResponse?.sellType}
                            initialMenuKey={1}
                            reference={methods.register}
                            name={"salesAccountTypeId"}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>수금방법</ItemBoxDiv>
                        <SingleSelect
                            menuItems={props.formResponse?.collectMoneyType}
                            initialMenuKey={1}
                            reference={methods.register}
                            name={"collectMoneyTypeId"}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>거래처 분류</ItemBoxDiv>
                        <FilterSelect
                            menuItems={props.formResponse?.accountType}
                            initialMenuKey={1}
                            reference={methods.register}
                            name={"salesTypeId"}
                        />
                        <FilterSelect
                            menuItems={selectItem}
                            reference={methods.register}
                            name={"groupId"}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>단가구분(체적)</ItemBoxDiv>
                        <FilterSelect
                            menuItems={props.formResponse?.volumePriceType}
                            initialMenuKey={1}
                            reference={methods.register}
                            name={"volumePriceTypeId"}
                        />
                        <ItemInput
                            ref={methods.register}
                            name={"adjustmentValue"}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>단가</ItemBoxDiv>
                        <ItemInput
                            ref={methods.register}
                            name={"unitPrice"}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>미터기 초기값</ItemBoxDiv>
                        <ItemInput
                            ref={methods.register}
                            name={"gaugeInitValue"}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>탱크 초기값</ItemBoxDiv>
                        <HalfDatePicker
                            name={"tankInitDate"}
                            reference={methods.register}
                        />
                        <ItemInput
                            name={"tankInitValue"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>탱크 용량</ItemBoxDiv>
                        <ItemInput
                            ref={methods.register}
                            name={"tankWeight"}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>계산서 발행</ItemBoxDiv>
                        <InputDiv>
                            <RadioButton
                                type={"radio"}
                                value={1}
                                checked={type == 1}
                                onClick={() => setType(1)}
                                name={"isBillNeeded"}
                                ref={methods.register}
                            />
                            <InputText>예</InputText>
                        </InputDiv>
                        <InputDiv>
                            <RadioButton
                                type={"radio"}
                                value={2}
                                checked={type == 2}
                                onClick={() => setType(2)}
                                name={"isBillNeeded"}
                                ref={methods.register}/>
                            <InputText>아니오</InputText>
                        </InputDiv>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            <Column>
                                <FixedWeightText fontSize={"14px"} fontWeight={500}>통소리 연동</FixedWeightText>
                                <FixedWeightText fontSize={"14px"} fontWeight={500}>계량기 명</FixedWeightText>
                            </Column>
                        </ItemBoxDiv>
                        <ItemInput
                            name={"tongsoriSyncId"}
                            ref={methods.register}
                        />
                        <StyledButton>
                            연동
                        </StyledButton>
                    </ItemDiv>
                </RightContents>
            </ContentsDiv>

            <SectionTitle>공급계약정보</SectionTitle>

            <ContentsDiv>
                <LeftContents>
                    <ItemDiv>
                        <ItemBoxDiv>계약번호</ItemBoxDiv>
                        <ItemInput
                            name={"contractNo"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>공급계약일</ItemBoxDiv>
                        <FullDatePicker name={"contractStartDate"}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>계약종료일</ItemBoxDiv>
                        <FullDatePicker name={"contractEndDate"}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>최종 점검일</ItemBoxDiv>
                        <FullDatePicker name={"finalCheckDate"}/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>성명</ItemBoxDiv>
                        <ItemInput
                            name={"contractorName"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>주민등록번호</ItemBoxDiv>
                        <ItemInput
                            maxLength={6}
                            name={"contractorSocialNo1"}
                            ref={methods.register}
                        />
                        <Dash>-</Dash>
                        <ItemInput
                            maxLength={7}
                            name={"contractorSocialNo2"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            <Column>
                                <FixedWeightText
                                    fontSize={"14px"}
                                    fontWeight={500}
                                >
                                    용기(탱크)
                                </FixedWeightText>

                                <FixedWeightText
                                    fontSize={"14px"}
                                    fontWeight={500}
                                >
                                    소유자
                                </FixedWeightText>
                            </Column>
                        </ItemBoxDiv>
                        <SingleSelect
                            menuItems={props.formResponse?.tankOwnerType}
                            name={"tankOwnerTypeId"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>시설 소유자</ItemBoxDiv>
                        <SingleSelect
                            menuItems={props.formResponse?.facilityOwnerType}
                            name={"facilityOwnerTypeUid"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>절체기</ItemBoxDiv>
                        <FilterSelect
                            menuItems={selectItem}
                            name={"regulatorTypeId"}
                            reference={methods.register}
                        />
                        <InputPlaceholder
                            placeholder={"kg/hr"}
                            name={"regulatorValue"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                </LeftContents>

                <RightContents>
                    <ItemDiv>
                        <ItemBoxDiv>기화기</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"kg"}
                            name={"vaporizeValue"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>계량기</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"m3"}
                            name={"meterValue"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>퓨즈콕</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"개"}
                            name={"fuseCockCount"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>밸브</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"A"}
                            name={"valveValue"}
                            reference={methods.register}
                        />
                        <InputPlaceholder
                            placeholder={"개"}
                            name={"valveCount"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>공급관1</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"A"}
                            name={"supplyPipe1Value"}
                            reference={methods.register}
                        />
                        <InputPlaceholder
                            placeholder={"개"}
                            name={"supplyPipe1Count"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>공급관2</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"A"}
                            name={"supplyPipe2Value"}
                            reference={methods.register}
                        />
                        <InputPlaceholder
                            placeholder={"개"}
                            name={"supplyPipe2Count"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>시설적합여부</ItemBoxDiv>
                        <SingleSelect
                            menuItems={selectItem}
                            name={"isFacilityGood"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>배관설치유무</ItemBoxDiv>
                        <SingleSelect
                            menuItems={selectItem}
                            name={"isPipeInstalled"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                </RightContents>
            </ContentsDiv>

            <SectionTitle>전자계산서</SectionTitle>

            <ContentsDiv>
                <LeftContents>
                    <ItemDiv>
                        <ItemBoxDiv>계산서발행</ItemBoxDiv>
                        <FilterSelect
                            menuItems={selectItem}
                            name={""}
                        />
                        <ItemInput
                            name={"electronicBillTypeId"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>상호</ItemBoxDiv>
                        <ItemInput
                            name={"companyName"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>대표자명</ItemBoxDiv>
                        <ItemInput
                            name={"ownerName"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>주소</ItemBoxDiv>
                        <ItemInput
                            name={"address"}
                            ref={methods.register}
                        />
                        <StyledButton>
                            주소검색
                        </StyledButton>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>상세주소</ItemBoxDiv>
                        <ItemInput
                            name={"addressDetail"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>담당자명</ItemBoxDiv>
                        <ItemInput
                            name={"chargePersonName"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>부서명</ItemBoxDiv>
                        <ItemInput
                            name={"departmentName"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>
                            e-mail
                        </ItemBoxDiv>
                        <ItemInput
                            name={"email"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>SMS 문자발송</ItemBoxDiv>
                        <ItemInput
                            name={'isSmsSend'}
                            ref={methods.register}
                        />
                    </ItemDiv>
                </LeftContents>

                <RightContents>
                    <ItemDiv>
                        <ItemBoxDiv>업태</ItemBoxDiv>
                        <ItemInput
                            name={"businessType"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>종목</ItemBoxDiv>
                        <ItemInput
                            name={"type"}
                            ref={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>과세구분</ItemBoxDiv>
                        <SingleSelect
                            menuItems={selectItem}
                            name={"taxTypeId"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>공급사업자</ItemBoxDiv>
                        <SingleSelect
                            menuItems={selectItem}
                            name={"supplyAccountId"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                </RightContents>
            </ContentsDiv>

            <SectionTitle>체적시설정보</SectionTitle>

            <ContentsDiv>
               <LeftContents>
                    <ItemDiv>
                        <ItemBoxDiv>연체율</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"%"}
                            name={"supply_pipe2_value"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>할인율</ItemBoxDiv>
                        <InputPlaceholder
                            placeholder={"%"}
                            name={"supply_pipe2_value"}
                            reference={methods.register}
                        />
                    </ItemDiv>
                </LeftContents>

                <RightContents/>
            </ContentsDiv>

            <SectionTitle>자동이체 기본정보</SectionTitle>

            <ContentsDiv>
                <LeftContents>
                    <ItemDiv>
                        <ItemBoxDiv>결제은행</ItemBoxDiv>
                        <SingleSelect
                            menuItems={selectItem}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>계좌번호</ItemBoxDiv>
                        <ItemInput/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>예금주</ItemBoxDiv>
                        <ItemInput/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>이체 약정일</ItemBoxDiv>
                        <ItemInput/>
                    </ItemDiv>
                </LeftContents>

                <RightContents>
                    <ItemDiv>
                        <ItemBoxDiv>가상계좌</ItemBoxDiv>
                        <ItemInput/>
                    </ItemDiv>
                    <ItemDiv>
                        <ItemBoxDiv>SMS 청구번호</ItemBoxDiv>
                        <SingleSelect
                            menuItems={selectItem}
                        />
                    </ItemDiv>
                </RightContents>
            </ContentsDiv>
            </form>
            <TopButton/>
        </FormProvider>
    );
}

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom : 22px;
`

const ButtonsDiv = styled.div`
  display: flex;
`


const ContentsDiv = styled.div`
  display: flex;
  flex : 1;
`

const LeftContents = styled.div`
  width: 624px;
  margin-right: 16px;
`

const RightContents = styled(LeftContents)`
  margin-right: 0px;
`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center; 
`

const ItemInput = styled(Input)`
  display: flex;
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const StyledButton = styled(Button)`
  width: 80px;
  height: 48px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color : var(--white);
  background-color: var(--blue);
`

const AddressSearchButton = styled(PostCodeButton)`
  color : var(--white);
  background-color: var(--blue);
  width: 80px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const FilterSelect = styled(Select).attrs({
    width: "198px",
    height: "48px",
    marginLeft: "8px"
})``

const SingleSelect = styled(Select).attrs({
    width: "404px",
    height: "48px",
    marginLeft: "8px"
})``

const HalfDatePicker = styled(Datepicker)`
  width: 198px;
  height: 48px;
`

const FullDatePicker = styled(Datepicker)`
  width: 404px;
  height: 48px;
`

const Dash = styled.div`
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`

const InputPlaceholder = styled(PlaceholderInput)`
  flex : 1;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

export default SalesAccountRegist