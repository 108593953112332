import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getEmployeeList(page: number, pagingSize : number, sortCallName : string, sort : string){
    const response = await axios.get<BaseResponse<EmployeeListResponse>>(
        `/basic/employee`, {
            params: {
                page : page,
                size : pagingSize,
                sortCallName : sortCallName,
                sort : sort
            }
        })
    return response.data
}

export type EmployeeListResponse = {
    totalCount : number,
    itemList : Array<EmployeeList>,
    currentPage : number,
    totalPage : number,
}

export type EmployeeList = {
    uid: string,
    code: string,
    name: string,
    employeeType: string,
    mobile: string,
    groupAreaList : Array<string>,
    employeeId : string,
    password : string,
    active: string,
}

