import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../../style/text/FixedWeightText";
import Button from "../../style/button/Button";

interface CommonComponentProps {
    description?: string
    subtitle?: string
    setOpen: () => void
    onClick: () => void
}

function Common(props: CommonComponentProps) {
    return (
        <Contents>
            <Title>
                {props.description}
            </Title>

            <Subtitle>
                {props.subtitle}
            </Subtitle>

            <ButtonArea>
                <CancelButton onClick={props.setOpen}>
                    취소
                </CancelButton>
                <ConfirmButton onClick={props.onClick}>
                    확인
                </ConfirmButton>
            </ButtonArea>
        </Contents>
    );
}

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
  height: 200px;
  box-sizing: border-box;
  padding: 24px 36px 32px;
  border-radius: 4px;
  background-color: var(--white);
`

const Title = styled(FixedWeightText)`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: var(--black);
`

const Subtitle = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top : 16px;
  color: var(--black);
`

const ButtonArea = styled.div`
  display: flex;
  width: 248px;
  margin-top: 32px;
  flex-direction: row;
  justify-content: space-between;
`
const CancelButton = styled(Button)`
  width: 121px;
  height: 48px;
  padding: 14px 47px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  color : var(--red);
`

const ConfirmButton = styled(Button)`
  width: 121px;
  height: 48px;
  padding: 14px 47px;
  border-radius: 4px;
  background-color: var(--blue);
  color : var(--white);
`

export default Common