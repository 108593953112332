import {ActionType, createAction, createReducer} from "typesafe-actions";
import {createActions} from "redux-actions";
import {PurchaseDetail} from "../../api/purchase/getPurchaseDetailList";

export const ADD = 'purchase_regist/ADD'
export const REMOVE = 'purchase_regist/REMOVE'
export const RESET = 'purchase_regist/RESET'

export const add = createAction(ADD)<PurchaseDetail>()
export const remove = createAction(REMOVE)<PurchaseDetail>()
export const reset = createAction(RESET)()

type PurchaseRegistAction = ActionType<typeof add | typeof remove | typeof reset>

type PurchaseRegistState = {
    type: "add" | "remove" | "none"
    item?: PurchaseDetail
};

const initialState: PurchaseRegistState = {
    type: "none"
};

export const purchaseRegistReducer = createReducer<PurchaseRegistState, PurchaseRegistAction>(initialState)
    .handleAction(add, (state, action) => ({type: "add", item: action.payload}))
    .handleAction(remove, (state, action) => ({type: "remove", item: action.payload}))
    .handleAction(reset, (state, action) => ({type: "none", item: undefined}))
