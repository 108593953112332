import React, {useEffect} from 'react';
import BasicNavComponent, {BasicType} from "../BasicNavComponent";
import GasProductTable from "./GasProductTable";
import styled from "styled-components";
import BasicTopTitle from "../BasicTopTitle";
import {useDispatch} from "react-redux";
import {changeTitle} from "../../../../redux/CompassModule";
import {MethodState, ProductMethodType} from "./GasProductContainer";
import TopButton from "../../../../components/style/button/TopButton";

interface GasProductScreenComponentProps {
    openModal: (type: ProductMethodType) => void
}

function GasProductScreen(props: GasProductScreenComponentProps) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeTitle({
            title: {
                first_depth: "기초데이터 홈"
            }
        }))
    })


    return (
        <>
            <BasicTopTitle openModal={props.openModal}/>
            <BasicNavComponent type={BasicType.GAS_PRODUCT}/>
            <GasProductTable
                onCellClicked={(uid) => props.openModal({
                    type: MethodState.UPDATE,
                    payload: uid
                })}
            />
            <Div>
                <TopButton/>
            </Div>
        </>
    );
}

export const Div = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export default GasProductScreen