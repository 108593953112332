import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../../../components/style/text/FixedWeightText";

interface SubTitleComponentProps {
    width?: string,
    height?: string,
    text?: string
}

function SubTitle(props: SubTitleComponentProps) {
    return (
        <SubTitleDiv width={props.width} height={props.height}>
            <SubtitleText>{props.text}</SubtitleText>
        </SubTitleDiv>
    );
}

const SubTitleDiv = styled.div<SubTitleComponentProps>`
  display: flex;
  align-items: center;
  width: ${(props: SubTitleComponentProps) => props.width};
  height: 80px;
`


const SubtitleText = styled(FixedWeightText)`
  font-size: 18px;
  font-weight: 400;
  margin-right: 24px;
`


export default SubTitle