import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getPurchaseAccountList() {
    const response = await axios.get<BaseResponse<Array<PurchaseAccountList>>>(`/purchase/account`)
    return response.data
}

export type PurchaseAccountList = {
    uid: string,
    code: string,
    accountName: string,
    shopTypeId: number,
    tel: string,
    fax: string,
    chargePerson: string,
    chargePersonTel: string,
    email: string,
    businessNumber: string,
    ownerName: string,
    address: string,
    addressDetail: string,
    latitude: number,
    longitude: number,
    businessType: string,
    type: string,
    memo1: string,
    memo2: string,
    bankId: number,
    bankAccountNumber: string,
    bankAccountOwnerName: string,
    propanePrice: number,
    butanePrice: number
}