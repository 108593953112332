import axios from 'axios';
import {BaseResponse} from "../../BaseResponse";


export async function employeeRegistration(request: EmployeeRegistRequest) {
    const response = await axios.post<BaseResponse<boolean>>("/basic/employee", request)
    return response.data
}

export type EmployeeRegistRequest = {
    code: string,
    name: string,
    type: number,
    groupAreaList: Array<string>,
    mobile: string,
    employeeId : string,
    password : string,
    deleted: boolean,
}