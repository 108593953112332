import React, {useEffect} from 'react';
import TankRetestRegistScreen from "./TankReExaminationRegistrationScreen";
import {useLocation, useParams} from "react-router-dom";

interface TankRetestRegistContainerComponentProps {

}

type RetestId= {
    retestId : string
}

type TankRetestDetail = {

}

function TankReExaminationRegistContainer(props: TankRetestRegistContainerComponentProps) {

    const param = useParams<RetestId>()

    useEffect(()=>{
        console.log(param)
    })

    const tankRests : Array<TankRetestDetail> = [{
        uid : "0001",
        applicationDate : "2021-03-04",
        completionRequestDate : "1234",
        CompletionDate : "0001",
        Quantity : 1,
        Progress : "접수중(1/1)",
    }]

    return (
        <div>
            <TankRetestRegistScreen isUpdate={param?.retestId?.length > 0}/>
        </div>
    );
}

export default TankReExaminationRegistContainer