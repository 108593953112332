import styled from "styled-components";
import FixedWeightText from "../../../components/style/text/FixedWeightText";

export const InputDiv = styled.div`
 display : flex;
 align-items : center;
`

export const InputText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  margin-left: 8px;
`

export const RadioButton = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 8px;
  border: solid 1px #bec0c2;
  background-color: white;
  align-self: center;
  justify-self: center;
`
