import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {TankDetailFormType} from "./getCertificateDetailForm";

export async function getShareTankCertificateForm() {
    const response = await axios.get<BaseResponse<CertificateSharedForm>>(`/tank/certificate/share/form`)
    return response.data
}

export type CertificateSharedForm = {
    manufacturerType: Array<TankDetailFormType>,
    volumeType : Array<TankDetailFormType>,
    tankUploadCount : number
}

