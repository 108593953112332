import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import FixedWeightText from "../text/FixedWeightText";
import Button from "./Button";

interface CancelButtonComponentProps {
    marginRight?: number
    link?: string
}

function CancelButton(props: CancelButtonComponentProps) {

    return (
        <Link to={props.link} style={{textDecoration: 'none'}}>
            <StyledButton marginRight={props.marginRight}>
                <ButtonText>취소</ButtonText>
            </StyledButton>
        </Link>
    );
}

const StyledButton = styled(Button)`
  width: 43px;
  height: 32px;
  background-color: var(--white);
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin-right: ${(props: CancelButtonComponentProps) => props.marginRight || 8}px;
`
const ButtonText = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  width: 26px;
  height: 20px;
  font-size: 13px;
  color: var(--red);
`

export default CancelButton