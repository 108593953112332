import React from 'react';
import BasicCheckBox from "./BasicCheckBox";
import FixedWeightText from "../text/FixedWeightText";
import styled from "styled-components";

interface TextWithCheckBoxComponentProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    trueColor: string
    trueCheckColor: string
    falseColor: string
    falseCheckColor: string
    checked: boolean
    text: string
}

function TextWithCheckBox(props: TextWithCheckBoxComponentProps) {
    return (
        <TextWithCheckboxDiv>
            <BasicCheckBox
                handleChange={props.handleChange} checked={props.checked}/>
            <CheckBoxText
                fontSize={"14px"}
                fontWeight={500}
                color={props.checked ? props.trueColor : props.falseColor}
            >
                {props.text}
            </CheckBoxText>
        </TextWithCheckboxDiv>
    );
}

const TextWithCheckboxDiv = styled.div`
  display: flex;
  align-items : center; 
  justify-content : space-between;
`

const CheckBoxText = styled(FixedWeightText)`
  width: fit-content;
  margin-left : 12px;
`
export default TextWithCheckBox